import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Tabs,
    Tab,
    Button,
    Divider, IconButton, TextField,
} from "@mui/material";
import {styled} from "@mui/system";
import {ApiDetails, KEY} from "../../../../dummyData";
import FormComponent from "../Form/FormComponent";
import MessagesComponent from "../Message/MessagesComponent";
import TenantFiles from "../Download Files/TenantFiles";
import TenantReport from "../Report/TenantReport";
import CryptoJS from "crypto-js";
import Account from "../Account/Account";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@mui/material/DialogContent";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import DialogActions from "@mui/material/DialogActions";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {useLocation} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import Textarea from "@mui/joy/Textarea";

const themeColor = "#2C5283";

const StyledCard = styled(Card)(({theme}) => ({
    marginBottom: theme.spacing(2),
    border: "1px solid #DDE6ED",
    borderRadius: theme.spacing(1),
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const RightSide = ({tenantID, tenantData}) => {

    const [accountNumber, setAccountNumber] = useState(tenantData.account_number);
    const [tenant, setTenant] = useState(tenantID);

    useEffect(() => {
        setAccountNumber(tenantData.account_number);
    }, [tenantData]);

    let myMapDetailsDecrypted = "";

    try {
        myMapDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.getItem("myMap"), KEY);

    } catch (e) {
        console.log(e);
    }

    const originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // const [tenantData, setTenantData] = useState({
    //     first_name: "n/a",
    //     last_name: "n/a",
    //     phone_number: "",
    //     secondary_phone_number: "",
    //     email_address: "",
    //     account_number: "",
    //     house_number: "",
    //     Wallet: 0,
    //     status: "",
    //     state: ""
    // });

    const [files, setFiles] = useState({
        defaults: {
            "tenant_photo": "",
            "photo_of_national_id": "",
            "copy_of_contract": "",
            "inventory_checklist": ""
        }
    });

    // useEffect(() => {
    //     // Fetch the tenant data
    //     fetch(ApiDetails + "pegasus/visionary/tenant/get/specific/tenant", {
    //         method: "POST",
    //         body: JSON.stringify({tenant_id: props.tenantID}),
    //         headers: {"Content-Type": "application/json"},
    //     })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setTenantData(data);
    //         })
    //         .catch((err) => console.error(err));
    //
    // }, []);

    useEffect(() => {
        // Fetch the image from the server
        fetch(
            ApiDetails +
            "pegasus/visionary/tenant/fetch/tenant/files",
            {
                method: "POST",
                body: JSON.stringify({
                    tenant_id: tenantID,
                }),
            }
        )
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setFiles(data)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    const sendCredentials = () => {
        // setMessageLoading(true);
        let values = {
            tenant_id: tenantData.tenant_id,
            property_id: tenantData.property_id,
            phone_number: tenantData.phone_number,
            secondary_phone_number: tenantData.secondary_phone_number,
            first_name: tenantData.first_name,
            account_number: tenantData.account_number,
            landlord_id: JSON.parse(originalText)[0][1],
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/credentials", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    showSnackbar({
                        title: "Success!",
                        message: "Credentials sent successfully.",
                        type: "success", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });

                } else if (response.status === 500) {
                    showSnackbar({
                        title: "Error!",
                        message: "Could not send credentials.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
                // setMessageLoading(false);
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later.",
                    type: "success", // success | error | warning | info
                    position: {vertical: "bottom", horizontal: "right"}, // Custom position
                });
                // setMessageLoading(false);
            });
    };


    const addArrears = (values) => {
        setOpen(false);

        let body = {
            tenant_id: tenantID,
            description: formData.description,
            amount: parseInt(formData.amount),
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/add/arrears", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    // getUserDetails({
                    //   landlord_id: JSON.parse(originalText)[0][1],
                    // });
                    // getArrears();
                    // handleClose();
                    showSnackbar({
                        title: "Success!",
                        message: "Arrear added successfully.",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });

                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Kindly try again later.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
            });
    };

    const [addingTransaction, setAddingTransaction] = useState(false)

    const addTransaction = (values) => {
        if (formDataTransaction.amount === null || formDataTransaction.amount === undefined || formDataTransaction.amount === "") {
            showSnackbar({
                title: "error!",
                message: "Transaction amount can not be empty.",
                type: "error", // success | error | warning | info
                position: {vertical: "bottom", horizontal: "right"}, // Custom position
            });

            return;
        }

        if (formDataTransaction.date === null || formDataTransaction.date === undefined || formDataTransaction.date === "") {
            showSnackbar({
                title: "error!",
                message: "Transaction date can not be empty.",
                type: "error", // success | error | warning | info
                position: {vertical: "bottom", horizontal: "right"}, // Custom position
            });

            return;
        }


        setAddingTransaction(true)

        let body = {
            TransactionType: "Pay Bill",
            TransID: "**********",
            // TransTime: "20220910102100",
            TransTime: formDataTransaction.date,
            TransAmount: parseFloat(formDataTransaction.amount),
            BusinessShortCode: "",
            BillRefNumber: accountNumber,
            InvoiceNumber: "",
            OrgAccountBalance: "00.00",
            ThirdPartyTransID: "",
            MSISDN: "",
            FirstName: "",
            tenant_id: tenantID,
            landlord_id: JSON.parse(originalText)[0][1],
        };

        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/payments/", {
            method: "POST",
            body: JSON.stringify(body),
        })
            .then(async (response) => {

                if (response.status === 200) {
                    showSnackbar({
                        title: "Success!",
                        message: "Transaction added successfully.",
                        type: "success", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });

                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Transaction not added.",
                        type: "error", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });
                }

                setAddingTransaction(false)
                window.location.reload();

            })
            .catch((err) => {
                setAddingTransaction(false)
                window.location.reload();
                showSnackbar({
                    title: "Error!",
                    message: "Failed updating tenant payment.",
                    type: "error", // success | error | warning | info
                    position: {vertical: "bottom", horizontal: "right"}, // Custom position
                });
            });


    };

    const [transactionModal, setTransactionModal] = useState(false);
    const [formDataTransaction, setFormDataTransaction] = useState({
        amount: null,
        date: new Date(), // Use `null` instead of an empty string for compatibility with DatePicker
    });


    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        amount: "",
        description: "",
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    // For text and number fields
    const handleChangeTransaction = (e) => {
        const {name, value} = e.target;
        setFormDataTransaction((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // For the date picker field
    const handleDateChangeTransaction = (newDate) => {
        setFormDataTransaction((prev) => ({
            ...prev,
            date: newDate,
        }));
    };

    const [messageLoading, setMessageLoading] = React.useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [broadcastMessage, setBroadcastMessage] = useState("");


    const sendMessage = () => {
        setMessageLoading(true);
        if (broadcastMessage === "") {
            showSnackbar({
                title: "Error!",
                message: "Can Not Send An Empty Message",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            setMessageLoading(false);
            return;
        }
        let values = {
            message: broadcastMessage,
            tenant_id: tenantData.tenant_id,
            first_name: tenantData.first_name,
            phone_number: tenantData.phone_number,
            property_id: tenantData.property_id,
            secondary_phone_number: tenantData.secondary_phone_number,
            landlord_id: JSON.parse(originalText)[0][1],
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/message", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    showSnackbar({
                        title: "Success!",
                        message: "Message Sent successfully.",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                } else if (response.status === 500) {
                    showSnackbar({
                        title: "Error!",
                        message: "Something Went Wrong. Call Customer Care For Assistance.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
                setMessageLoading(false);
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
                setMessageLoading(false);
            });
    };


    const handleClose = () => {
        setMessageModal(false);
        setTransactionModal(false);
    };


    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={() => setOpen(false)}
                PaperProps={{
                    style: {
                        // backgroundColor: "#FBF4F0",
                        borderRadius: "5px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                        padding: "20px",
                        minWidth: "400px",
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "16px",
                        borderBottom: "2px solid #E3DED8",
                        // backgroundColor: "#FBF4F0",

                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: "#2C5283",
                            fontWeight: "bold",
                            fontSize: "1.25rem",
                            marginBottom: "8px",
                        }}
                    >
                        Add Arrears
                    </Typography>
                    <IconButton
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "#2C5283",
                            "&:hover": {backgroundColor: "#F1EAE5"},
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>

                <DialogContent
                    sx={{padding: 0}}
                >
                    <Box
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                        }}
                    >
                        {/* Amount Field */}
                        <TextField
                            label="Amount"
                            name="amount"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={formData.amount}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: {color: "#5A5A5A"},
                            }}
                            InputProps={{
                                style: {
                                    backgroundColor: "#FFF",
                                    borderRadius: "5px",
                                    boxShadow: "inset 0px 1px 4px rgba(0, 0, 0, 0.1)",
                                },
                            }}
                        />

                        {/* Description Field */}
                        <TextField
                            label="Description"
                            name="description"
                            type="text"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            value={formData.description}
                            onChange={handleChange}
                            InputLabelProps={{
                                style: {color: "#5A5A5A"},
                            }}
                            InputProps={{
                                style: {
                                    backgroundColor: "#FFF",
                                    borderRadius: "5px",
                                    boxShadow: "inset 0px 1px 4px rgba(0, 0, 0, 0.1)",
                                },
                            }}
                        />
                    </Box>
                </DialogContent>

                <DialogActions
                    sx={{
                        padding: "16px",
                        marginTop: "20px",
                        borderTop: "2px solid #E3DED8",
                        justifyContent: "space-between",
                    }}
                >
                    {/* Cancel Button */}
                    <Button
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "#2C5283",
                            backgroundColor: "#FFF",
                            textTransform: "none",
                            borderRadius: "12px",
                            padding: "10px 20px",
                            fontWeight: "bold",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                            "&:hover": {backgroundColor: "#F1EAE5"},
                        }}
                    >
                        Cancel
                    </Button>

                    {/* Submit Button */}
                    <Button
                        onClick={addArrears}
                        sx={{
                            color: "#FFF",
                            background: "linear-gradient(90deg, #2C5283, #25446E)",
                            textTransform: "none",
                            borderRadius: "12px",
                            padding: "10px 20px",
                            fontWeight: "bold",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
                            "&:hover": {background: "#1E3A66"},
                        }}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={messageModal}
                TransitionComponent={Transition}
                onClose={() => setMessageModal(false)}
                PaperProps={{
                    style: {
                        // backgroundColor: "#FBF4F0",
                        borderRadius: "5px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                        padding: "20px",
                        minWidth: "400px",
                    },
                }}
            >
                <DialogTitle>{tenantData.first_name} message</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            width: "calc(100% - 20px)",
                            padding: "10px",
                        }}
                    ></div>
                    <Textarea
                        minRows={5}
                        placeholder="Type message .."
                        onChange={(event) => {
                            setBroadcastMessage(event.target.value);
                        }}
                        style={{width: "100%"}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={sendMessage}
                        loading={messageLoading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Send
                    </LoadingButton>
                </DialogActions>
            </Dialog>


            <Dialog
                open={transactionModal}
                TransitionComponent={Transition}
                onClose={() => setTransactionModal(false)}
                PaperProps={{
                    style: {
                        // backgroundColor: "#FBF4F0",
                        borderRadius: "5px",
                        boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
                        padding: "20px",
                        minWidth: "400px",
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "12px",
                        borderBottom: "1px solid #E3DED8",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            color: "#2C5283",
                            fontWeight: 600,
                            fontSize: "1.5rem",
                        }}
                    >
                        Add Transaction
                    </Typography>
                    <IconButton
                        onClick={() => setTransactionModal(false)}
                        sx={{
                            color: "#2C5283",
                            "&:hover": {
                                backgroundColor: "#E3E7EB",
                            },
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>

                <DialogContent
                    sx={{
                        padding: "20px 0",
                    }}
                >
                    <Box
                        component="form"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                        }}
                    >
                        {/* Amount Field */}
                        <TextField
                            label="Amount"
                            name="amount"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={formDataTransaction.amount}
                            onChange={handleChangeTransaction}
                            InputLabelProps={{
                                sx: {color: "#5A5A5A", fontSize: "0.9rem"},
                            }}
                            InputProps={{
                                style: {
                                    backgroundColor: "#FFF",
                                    borderRadius: "8px",
                                    boxShadow: "inset 0px 1px 6px rgba(0, 0, 0, 0.1)",
                                },
                            }}
                        />

                        {/* Date Picker Field */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Select Date"
                                value={formDataTransaction.date}
                                onChange={handleDateChangeTransaction}
                                maxDate={new Date()} // Disable future dates
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        InputLabelProps={{
                                            sx: {color: "#5A5A5A", fontSize: "0.9rem"},
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: {
                                                backgroundColor: "#FFF",
                                                borderRadius: "8px",
                                                boxShadow: "inset 0px 1px 6px rgba(0, 0, 0, 0.1)",
                                            },
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>

                    </Box>
                </DialogContent>

                <DialogActions
                    sx={{
                        paddingTop: "16px",
                        borderTop: "1px solid #E3DED8",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        onClick={() => setTransactionModal(false)}
                        sx={{
                            color: "#2C5283",
                            backgroundColor: "#FFF",
                            textTransform: "none",
                            fontSize: "0.95rem",
                            fontWeight: 500,
                            borderRadius: "10px",
                            padding: "10px 20px",
                            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
                            "&:hover": {backgroundColor: "#F1EAE5"},
                        }}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        size="small"
                        onClick={addTransaction}
                        sx={{
                            color: "#FFF",
                            background: "linear-gradient(90deg, #2C5283, #25446E)",
                            textTransform: "none",
                            fontSize: "0.95rem",
                            fontWeight: 500,
                            borderRadius: "10px",
                            padding: "10px 20px",
                            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.2)",
                            "&:hover": {background: "#1E3A66"},
                        }}
                        disabled={addingTransaction}
                        loading={addingTransaction}
                        startIcon={<SendIcon/>}
                        loadingPosition="start"
                    >
                        Submit
                    </LoadingButton>


                </DialogActions>
            </Dialog>

            <Grid item xs={15} md={8}>
                {/* Tabs Section */}
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{marginBottom: 2}}
                >
                    <Tab label="Account"/>
                    <Tab label="Edit Details"/>
                    <Tab label="Messages"/>
                    <Tab label="Files"/>
                    <Tab label="Report"/>
                </Tabs>

                {/* Tab Content */}
                {activeTab === 0 && (
                    <StyledCard>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >

                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    color={themeColor}
                                    gutterBottom
                                >
                                    Account
                                </Typography>

                                <Box sx={{display: "flex", gap: 1}}>
                                    <Button variant="outlined"
                                            sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                            onClick={() => {
                                                setTransactionModal(true)
                                            }}>
                                        Make Payment
                                    </Button>
                                    <Button variant="outlined"
                                            sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                            onClick={() => {
                                                setOpen(true)
                                            }}>
                                        Add Arrear
                                    </Button>
                                </Box>
                            </Box>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Account tenantID={tenantID}/>

                        </CardContent>
                    </StyledCard>
                )}

                {activeTab === 1 && (
                    <StyledCard>
                        <CardContent>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color={themeColor}
                                gutterBottom
                            >
                                Update Tenant Information
                            </Typography>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Typography variant="body2" color="textSecondary">
                                <FormComponent tenatDetails={tenantData} originalDetails={originalText}/>
                            </Typography>
                        </CardContent>
                    </StyledCard>
                )}

                {activeTab === 2 && (
                    <StyledCard>
                        <CardContent>

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >

                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    color={themeColor}
                                    gutterBottom
                                >
                                    Tenant Messages
                                </Typography>

                                <Button variant="outlined"
                                        sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                        onClick={()=> setMessageModal(true)}>

                                    Send SMS
                                </Button>

                                <Button variant="outlined"
                                        sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                        onClick={sendCredentials}>
                                    Resend Credentials
                                </Button>
                            </Box>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Typography variant="body2" color="textSecondary">
                                <MessagesComponent tenatDetails={tenantData}
                                                   landlordID={JSON.parse(originalText)[0][1]}/>

                            </Typography>
                        </CardContent>
                    </StyledCard>
                )}

                {activeTab === 3 && (
                    <StyledCard>
                        <CardContent>


                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color={themeColor}
                                gutterBottom
                            >
                                Download Files
                            </Typography>


                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Typography variant="body2" color="textSecondary">
                                <TenantFiles files={files} tenantDetails={tenantData}
                                             originalText={originalText}
                                             propertyID={tenantData.property_id}
                                             propertyName={tenantData.property_name}/>
                            </Typography>
                        </CardContent>
                    </StyledCard>
                )}


                {activeTab === 4 && (
                    <StyledCard>
                        <CardContent>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color={themeColor}
                                gutterBottom
                            >
                                Tenant Report Generator
                            </Typography>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Typography variant="body2" color="textSecondary">
                                <TenantReport tenantDetails={tenantData}/>
                            </Typography>
                        </CardContent>
                    </StyledCard>
                )}
            </Grid>

        </>


    );
};

export default RightSide;
