import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Tabs,
    Tab,
    Button,
    Divider, IconButton, TextField,
} from "@mui/material";
import {styled} from "@mui/system";
import {KEY} from "../../../../dummyData";
import FormComponent from "../Form/Details Form/FormComponent";
import CryptoJS from "crypto-js";
import PropertyTenants from "../Property Tenants/PropertyTenants";
import UpdateUnitsDetails from "./UpdateUnitsDetails";

const themeColor = "#2C5283";

const StyledCard = styled(Card)(({theme}) => ({
    marginBottom: theme.spacing(2),
    border: "1px solid #DDE6ED",
    borderRadius: theme.spacing(1),
}));

const UpdatePropertyDetails = (props) => {


    const {propertyDetails, tenants, setBillTenantsModal,billTenantsModal} = props;


    let myMapDetailsDecrypted = "";

    try {
        myMapDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.getItem("myMap"), KEY);

    } catch (e) {
        console.log(e);
    }

    const originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <>
            <Grid xs={12} md={12}>
                {/* Tabs Section */}
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{marginBottom: 2}}
                >
                    <Tab label="Property Details"/>
                    <Tab label="Unit Details"/>
                </Tabs>

                {/* Tab Content */}

                {activeTab === 0 && (
                    <StyledCard>
                        <CardContent>

                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                color={themeColor}
                                gutterBottom
                            >
                                Edit Property Details
                            </Typography>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <Typography variant="body2" color="textSecondary">
                                <FormComponent propertyDetails={propertyDetails} originalDetails={originalText}/>
                            </Typography>
                        </CardContent>
                    </StyledCard>
                )}

                {activeTab === 1 && (
                    <StyledCard>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >

                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    color={themeColor}
                                    gutterBottom
                                >
                                    Edit Property Unit Details
                                </Typography>

                            </Box>

                            <Divider sx={{marginY: "10px", backgroundColor: "#2C5283"}}/>

                            <UpdateUnitsDetails tenants={tenants} originalText={originalText} propertyDetails={propertyDetails}
                            />

                        </CardContent>
                    </StyledCard>
                )}
            </Grid>
        </>
    );
};

export default UpdatePropertyDetails;
