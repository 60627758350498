import React, {useEffect, useRef, useState} from "react";
import * as yup from "yup";
import "yup-phone";
import "../../Tenant.css";
import "../../../../../node_modules/draft-js/dist/Draft.css";
import {DeleteOutline,} from "@mui/icons-material";
import {TextField} from "@material-ui/core";
import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@material-ui/core/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";
import {
    useMediaQuery,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {ApiDetails, KEY} from "../../../../dummyData";
import NonEditableRichTextEditor from "../../../../components/RichTextEditorNotEditable/NonEditableRichTextEditor";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import RichTextEditor from "../../../../components/RichTextEditorEditable/RichTextEditor";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import editorState from "draft-js/lib/EditorState";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";

const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Notices(props) {
    const matches = useMediaQuery("(max-width:700px)");
    const [dataSet, setDataSet] = useState(false);

    const [tenantLoading, setTenantLoading] = React.useState(false);

    const [editorState, setEditorState] = React.useState(() =>
        EditorState.createEmpty()
    );

    const [tenant, setTenant] = useState({first_name: "", last_name: "", state: "", notice: ""});

    const initialValues3 = {
        first_name: tenant.first_name,
        last_name: tenant.last_name,
        phone_number: tenant.phone_number,
        national_id: tenant.national_id,
        unit: tenant.house_number,
        property_name: tenant.property_name,
        landlord_id: tenant.landlord_id,
        tenant_id: tenant.tenant_id,
    };


    const [notices, setNotices] = useState({});

    const getNotices = () => {
        fetch(
            ApiDetails +
            "pegasus/visionary/authorization/admin/get/specific/tenant/notices",
            {
                method: "POST",
                body: JSON.stringify({
                    tenant_id: props.tenantID,
                }),
            }
        )
            .then(async (response) => {
                let data = await response.json();
                console.log("NOTICES");
                console.log(data);
                if (response.status === 200) {
                    //Fetch tenant notices
                    setNotices(data);

                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Kindly try again later.",
                        type: "error", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });
                }
            })
            .catch((error) => console.error("Error fetching image:", error));
    };

    useEffect(() => {
        getNotices();
        setDataSet(true);

    }, [])

    useEffect(() => {
        // Fetch the tenant data
        fetch(ApiDetails + "pegasus/visionary/tenant/get/specific/tenant", {
            method: "POST",
            body: JSON.stringify({tenant_id: props.tenantID}),
            headers: {"Content-Type": "application/json"},
        })
            .then((response) => response.json())
            .then((data) => {
                setTenant(data);
            })
            .catch((err) => console.error(err));

    }, []);

    const deleteNotice = (id) => {
        fetch(
            ApiDetails +
            "pegasus/visionary/authorization/admin/delete/specific/tenant/notices",
            {
                method: "POST",
                body: JSON.stringify({
                    id: id,
                    tenant_id: props.tenantID
                }),
            }
        )
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    // console.log(data)
                    window.location.reload();
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Kindly try again later.",
                        type: "error", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });
                }
            })
            .catch((error) => console.error("Error fetching image:", error));
    };


    const [dateToday] = useState(new Date());
    const [dateToVacate, setDateToVacate] = useState(new Date());

    function toMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber);

        return date.toLocaleString("en-US", {
            month: "long",
        });
    }

    const handleClose = () => {
        setVacatingModal(false);
        setForceVacatingModal(false);
    };


    const sendVacatingNotice = (values) => {
        setTenantLoading(true);
        const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        // const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        // const data = htmlToDraft;

        values = {

            first_name: tenant.first_name,
            last_name: tenant.last_name,
            phone_number: tenant.phone_number,
            national_id: tenant.national_id,
            unit: tenant.house_number,
            property_name: tenant.property_name,
            landlord_id: tenant.landlord_id,
            tenant_id: tenant.tenant_id,

            // ...values,
            // date_to_vacate_house: new Date(dateToVacate.toJSON()),
            date_to_vacate_house:
                toMonthName(new Date(dateToVacate.toJSON()).getMonth()) +
                " " +
                new Date(dateToVacate.toJSON()).getDate() +
                ", " +
                new Date(dateToVacate.toJSON()).getFullYear(),
            date_notice_given: new Date(),
            reasons_for_vacating: JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
            ),
            landlord_notice: true,
        };

        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/app/AddNotice", {
            method: "POST",
            body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    await getNotices();
                    showSnackbar({
                        title: "Success!",
                        message: "Notice uploaded successfully.",
                        type: "success", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });
                    setTenantLoading(false);
                    handleClose();
                } else {
                    setTenantLoading(false);
                    handleClose();
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Kindly try again later.",
                        type: "error", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });
                }
            })
            .catch((err) => {
                setTenantLoading(false);
                handleClose();

                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Kindly try again later.",
                    type: "error", // success | error | warning | info
                    position: {vertical: "bottom", horizontal: "right"}, // Custom position
                });
            });


    };


    let dateSchema = yup.object().shape({
        phone_number: yup.string().phone("KE", true).required("Required"),
    });

    const classes = useStyles();

    const [vacatingModal, setVacatingModal] = useState(false);
    const [forceVacatingModal, setForceVacatingModal] = useState(false);


    const noticeColumns = [
        {
            field: "date_to_vacate_house",
            headerName: "Date to vacate",
            headerAlign: "center",
            // width: 150,
            minWidth: 150,
            flex: 1,
        },
        {
            field: "date_created",
            align: "center",
            headerAlign: "center",
            headerName: "Date notice given",
            // width: 200,
            minWidth: 200,
            flex: 1,
        },
        {
            field: "",
            headerName: "Action",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                        className="userListAction"
                    >
                        <Button
                            onClick={(event) => {
                                setVacatingModal(true);
                            }}
                            variant="contained"
                        >
                            View notice details
                        </Button>

                        <Tooltip title="Delete" placement="bottom-start">
                            <DeleteOutline
                                className="userListDelete"
                                style={{
                                    paddingLeft: "10px",
                                    color: "red",
                                }}
                                onClick={async () => {
                                    //open confirm delete modal
                                    //Delete arrear
                                    await deleteNotice(params.row.id);
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    return (
        <div className="user">
            <Dialog
                open={vacatingModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Vacating Form
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <small
                        style={{
                            color: "red",
                        }}
                    >
                        Kindly refresh if details are not clear
                    </small>
                    <br/>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        value={new Map(Object.entries(tenant)).get("first_name")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        value={new Map(Object.entries(tenant)).get("last_name")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <TextField
                        label="Date To Vacate"
                        variant="outlined"
                        value={new Map(
                            Object.entries(new Map(Object.entries(tenant)).get("notice"))
                        ).get("date_to_vacate_house")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <TextField
                        label="Date Notice Given"
                        variant="outlined"
                        value={new Map(
                            Object.entries(new Map(Object.entries(tenant)).get("notice"))
                        ).get("date_created")}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: "10px", width: "100%"}}
                    />
                    <br/>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px",
                        }}
                    >
                        {dataSet &&
                        new Map(Object.entries(tenant)).get("state") === "NOTICE" ? (
                            <NonEditableRichTextEditor
                                richTextValue={EditorState.createWithContent(
                                    convertFromRaw(
                                        JSON.parse(
                                            new Map(
                                                Object.entries(
                                                    new Map(Object.entries(tenant)).get("notice")
                                                )
                                            ).get("reasons_for_vacating")
                                        )
                                    )
                                )}
                            />
                        ) : (
                            <></>
                        )}
                        {/*<Editor editorState={editorState} onChange={setEditorState}/>*/}
                    </div>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "20px",
                        }}
                    >
                        <Button onClick={() => setVacatingModal(false)}>
                            <span style={{color: "green"}}>Close</span>
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                maxWidth="md"
                open={forceVacatingModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        borderRadius: "12px",
                        padding: "20px",
                        backgroundColor: "#f9f9f9",
                    },
                }}
            >
                <DialogTitle>
                    <div
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                            color: "#398DD2",
                            letterSpacing: "0.5px",
                            marginBottom: "10px",
                        }}
                    >
                        Vacating Notice
                    </div>
                </DialogTitle>
                <DialogContent style={{padding: "20px"}}>
                    <Formik initialValues={initialValues3} onSubmit={sendVacatingNotice}>
                        {(props) => (
                            <Form noValidate>
                                {/* Date Fields */}
                                <div style={{display: "flex", flexDirection: "column", gap: "10px", marginTop: "10px"}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date Today"
                                            disabled
                                            value={dateToday}
                                            inputFormat="MM/DD/YYYY"
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    sx={{backgroundColor: "#fff", borderRadius: "8px"}}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date To Vacate"
                                            inputFormat="MM/DD/YYYY"
                                            value={dateToVacate}
                                            onChange={(newValue) => setDateToVacate(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    sx={{backgroundColor: "#fff", borderRadius: "8px"}}
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>

                                {/* Rich Text Editor */}
                                <div style={{
                                    marginTop: "20px",
                                    backgroundColor: "#fff",
                                    padding: "10px",
                                    borderRadius: "8px"
                                }}>
                                    <RichTextEditor
                                        richTextValue={editorState}
                                        change={(value) => {
                                            setEditorState(value);
                                        }}
                                    />
                                </div>

                                {/* Action Buttons */}
                                <div style={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                                    <Button
                                        onClick={() => setForceVacatingModal(false)}
                                        sx={{
                                            color: "#fff",
                                            backgroundColor: "#d32f2f",
                                            "&:hover": {backgroundColor: "#b71c1c"},
                                            borderRadius: "8px",
                                            padding: "8px 20px",
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        size="medium"
                                        loading={tenantLoading}
                                        startIcon={<SendIcon/>}
                                        sx={{
                                            color: "#fff",
                                            backgroundColor: "#388E3C",
                                            "&:hover": {backgroundColor: "#2E7D32"},
                                            borderRadius: "8px",
                                            padding: "8px 20px",
                                        }}
                                    >
                                        Submit
                                    </LoadingButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            {/* Tenant arrears and transactions */}
            <div
                style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                    justifyContent: "space-between",
                    gap: "20px", // Adds spacing between elements
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "40vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px", // Adds padding for better spacing
                        backgroundColor: "#FAFAFA", // Light background for contrast
                        borderRadius: "10px", // Soft rounded corners
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                    }}
                >
                    {notices.length === 0 ? (
                        <div style={{textAlign: "center", padding: "20px"}}>
                            <p style={{marginBottom: "15px", fontSize: "16px", fontWeight: "500", color: "#333"}}>
                                No vacating notices found.
                            </p>
                            <Button
                                variant="outlined"
                                sx={{
                                    textTransform: "none",
                                    backgroundColor: "#FBF4F0",
                                    padding: "8px 20px",
                                    borderRadius: "8px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // Slight shadow for better visibility
                                    "&:hover": {
                                        backgroundColor: "#F2E6E0",
                                    },
                                }}
                                onClick={() => setForceVacatingModal(true)}
                            >
                                Add New Notice
                            </Button>
                        </div>
                    ) : (
                        <DataGrid
                            className={classes.root}
                            rows={notices}
                            disableSelectionOnClick
                            rowHeight={45}
                            columns={noticeColumns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            sx={{
                                width: "100%",
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", // Adds depth
                                padding: "10px",
                            }}
                        />
                    )}
                </div>
            </div>

        </div>
    );
}
