import React, {useState} from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Button} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {ApiDetails, KEY} from "../../../dummyData";
import DialogActions from "@mui/material/DialogActions";
import {DeleteOutline} from "@mui/icons-material";
import CryptoJS from "crypto-js";
import LoadingButton from "@mui/lab/LoadingButton";

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function ContentThree(props) {
    let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
        localStorage.getItem("myMap"),
        KEY
    );
    let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    const [propertyDetails, setPropertyDetails] = React.useState("");
    const [filename, setFilename] = React.useState("");

    const navigate = useNavigate();

    const previous = () => {
        props.previous();
    };

    const getFileName = (e) => {
        let startIndex =
            e.target.value.indexOf("\\") >= 0
                ? e.target.value.lastIndexOf("\\")
                : e.target.value.lastIndexOf("/");
        let filename = e.target.value.substring(startIndex);
        if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
            filename = filename.substring(1);
        }
        return filename;
    };

    function handleFile(e) {
        let filename = getFileName(e);
        if (checkTemplateFile(filename)) {
            setFilename(filename);
            setPropertyDetails(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload An Excel File",
                type: "warning",
            });
        }
    }

    function getExtension(filename) {
        let parts = filename.split(".");
        return parts[parts.length - 1];
    }

    function checkTemplateFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "xlsx":
                //etc
                return true;
        }
        return false;
    }

    const getUserDetails = () => {
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/dashboard/get/details", {
            method: "POST",
            body: JSON.stringify({
                landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    const map1 = new Map(Object.entries(data));

                    let portfolioDetails = CryptoJS.AES.encrypt(
                        JSON.stringify(Array.from(map1.entries())),
                        KEY
                    ).toString();

                    localStorage.portfolioDetails = portfolioDetails;

                    //new Map(JSON.parse(localStorage.portfolioDetails));
                    props.snackBar({
                        text: "File Uploaded Successfully",
                        type: "success",
                    });
                    props.complete();
                    setLoading(false)


                    //navigate("/home");
                    window.location.href = "/home";
                } else {
                    props.snackBar({text: "Failed Fetching Details", type: "error"});
                    setLoading(false)

                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);

            });
    };
    const [loading, setLoading] = useState(false);

    const uploadFile = () => {
        let formData = new FormData();
        formData.append(
            "details",
            JSON.stringify({
                landlord_id: props.details.get("landlord_id"),
                email_address: props.details.get("email_address"),
            })
        );
        formData.append("updated_document", propertyDetails);

        if (propertyDetails === "") {
            props.snackBar({
                text: "Please Select File To Upload",
                type: "error",
            });
            return;
        }
        setLoading(true)
        fetch(ApiDetails + "pegasus/visionary/files/finishSetup", {
            method: "POST",
            body: formData,
        })
            .then(async (response) => {
                if (response.status === 200) {
                    getUserDetails();
                } else {
                    props.snackBar({
                        text: "Make Sure The File Is The Correct One And Is Properly Edited",
                        type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)

                props.snackBar({
                    text: "Something went wrong. Please try again later",
                    type: "error",
                });
            });
    };

    return (
        <div>
            <BootstrapDialogTitle id="customized-dialog-title">
                Finally
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Typography>
                    Make sure the changes made to the document downloaded are as per the
                    provided guidelines. This document is used by the system to help the
                    system know the current status of all your properties.
                </Typography>
                <br/>
                <Typography></Typography>
                <Typography>Select the excel file you downloaded and edited</Typography>
                <br/>
                <div>
                    {propertyDetails === "" ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "500px",
                            }}
                        >
                            {/*<span>TENANT CONTRACT TEMPLATE</span>*/}
                            <Button variant="outlined" component="label">
                                Upload File
                                <input type={"file"} onChange={(e) => handleFile(e)} hidden/>
                            </Button>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "50px",
                                borderRadius: "5px",
                                textAlign: "center",
                                width: "500px",
                                backgroundColor: "rgb(180,250,180)",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "0px 10px",
                                }}
                            >
                                <span>{filename}</span>
                                <DeleteOutline
                                    className="userListDelete"
                                    onClick={() => {
                                        setPropertyDetails("");
                                        setFilename(null);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {propertyDetails === "" ? (
                    <>
                        <Button
                            style={{marginTop: "20px", marginBottom: "10px"}}
                            variant="contained"
                            color="primary"
                            disabled={true}
                        >
                            Upload
                        </Button>
                    </>
                ) : (
                    <>
                        <LoadingButton
                            style={{marginTop: "20px", marginBottom: "10px"}}
                            variant="contained"
                            color="primary"
                            onClick={uploadFile}
                            loading={loading}
                        >
                            Upload
                        </LoadingButton>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={previous}>
                    <span style={{color: "green"}}>Previous</span>
                </Button>
            </DialogActions>
        </div>
    );
}
