import React, {useEffect, useState, useMemo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import CryptoJS from "crypto-js";
import * as yup from "yup";
import {ApiDetails, KEY, loginState} from "../../dummyData";

// Material-UI Components
import {Snackbar, Stack, Alert, Backdrop, CircularProgress, useMediaQuery} from "@mui/material";

// Custom Components
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import WidgetLg from "../../components/widgetLG/WidgetLg";

// CSS
import "./home.css";
import ProgressBars from "../../components/ProgressBars";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {LogoutOutlined} from "@mui/icons-material";
import DialogContentText from "@mui/material/DialogContentText";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {TextField} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import {showSnackbar} from "../../components/SnackBar/SnackBar";
import OnboardingStepOne from "../../components/customDialogs/onboardingDialog";

const Transition = React.forwardRef(function Transition(props: TransitionProps & {
    children: React.ReactElement<any, any>,
}, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Helper function for safe decryption
const safeDecrypt = (key) => {
    try {
        return CryptoJS.AES.decrypt(localStorage.getItem(key), KEY).toString(CryptoJS.enc.Utf8) || "";
    } catch {
        return "";
    }
};

const apiFetch = async (url, options) => {
    try {
        const response = await fetch(url, options);
        if (!response.ok) throw new Error("API request failed");
        return await response.json();
    } catch (error) {
        console.error(error);
        return null;
    }
};

const HomeV2 = ({refreshSideBar, topBar}) => {
    const matches = useMediaQuery("(max-width:700px)");

    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:768px)");

    const [modals, setModals] = useState({
        subscriptionModal: false,
        paymentModal: false,
        snackbarOpen: false,
    });

    const [subscription, setSubscription] = useState(safeDecrypt("subscriptionDetails"));
    const [originalText, setOriginalText] = useState(safeDecrypt("myMap"));
    const [originalText2, setOriginalText2] = useState(safeDecrypt("portfolioDetails"));

    let [set, updateSet] = React.useState(false);

    const [message, setMessage] = React.useState("");

    const [severity, setSeverity] = React.useState();

    const totalUnits = useMemo(() => {
        try {
            return new Map(JSON.parse(originalText2)).get("number_of_units") || 0;
        } catch {
            return 0;
        }
    }, [originalText2]);

    const handleSnackbarClose = () => {
        setModals((prev) => ({...prev, snackbarOpen: false}));
    };

    const specificOptions = new Set(['option1', 'option2', 'option3']); // Replace with actual option keys

    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);


    const [dateSubscriptionExpired, setDateSubscriptionExpired] = useState(null);
    const [, setDateSubscribed] = useState(null);
    let [, setEmailAddress] = useState(null);

    const [amount, setAmount] = useState({amount: 0});

    const [subscriptionAmount, setSubscriptionAmount] = useState([]);
    const [subscribeButtonDisabled, setSubscribeButtonDisabled] = useState(true);

    let [status, setStatus] = React.useState(localStorage.getItem("isNew"));

    useEffect(() => {

        const userDetails = new Map(JSON.parse(originalText).map((object) => {
            return [object[0], object[1]];
            // console.log(object);
        }));

        let dateCreated;

        let date_subscribed;
        userDetails.forEach((value, key) => {
            if (key === "date_subscription_renewed") {
                date_subscribed = new Date(value);
            }
            if (key === "date_created") {
                dateCreated = new Date(value);
            }
        });

        let email_address;
        userDetails.forEach((value, key) => {
            if (key === "email_address") {
                email_address = value;
            }
        });

        setEmailAddress(email_address);
        setDateSubscribed(date_subscribed);

        if (status !== "yes") {

            try {
                // let subscriptionExpired = JSON.parse(originalText)[24][1];
                let subscriptionExpired = JSON.parse(subscription)[0][1];

                if (!(date_subscribed === undefined || date_subscribed === null)) {
                    // subscriptionExpired = date_subscribed.getFullYear() <= date_today.getFullYear() && date_subscribed.getMonth() < date_today.getMonth() && date_subscribed.getDate() < date_today.getDate();

                    date_subscribed.setMonth(date_subscribed.getMonth() + 1);

                    setDateSubscriptionExpired(date_subscribed.getDate() + " " + date_subscribed.toLocaleString("en-US", {
                        month: "long",
                    }) + ", " + date_subscribed.getFullYear());
                } else {
                    // subscriptionExpired = true;
                    setDateSubscriptionExpired("First subscription");
                }

                if (subscriptionExpired && !(status === "yes")) {

                    // console.log(JSON.parse(subscription))

                    setSubscriptionAmount(
                        Math.ceil(JSON.parse(subscription)[1][1])
                    )
                    setSubscribeButtonDisabled(false)
                    // setSubscriptionModal(subscriptionExpired && !(status === "yes"));
                    setSubscriptionModal(true);

                }
            } catch (e) {
            }
        }

        // setSubscriptionAmount(subscriptionAmount * 0.1)

        // console.log(subscriptionAmount)
        updateSet(true)
    }, []);

    let initialPaymentValues = {
        phone_number: JSON.parse(originalText)[3][1],
    };
    let basicSchema = yup.object().shape({
        phone_number: yup.string().phone("KE", true).required("Required"),
        amount: yup.number().min(1, "Amount must be more than 1 KSH"),
    });

    const [open, setOpen] = React.useState(loginState);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        setPaymentModal(false);
    };

    const [tenantLoading, setTenantLoading] = React.useState(false);


    const processPayment = async (values) => {

        if (values.phone_number.toString().startsWith("0")) {
            values.phone_number = "254" + values.phone_number.toString().substring(1);
        } else if (values.phone_number.toString().startsWith("1") || values.phone_number.toString().startsWith("7")) {
            values.phone_number = "254" + values.phone_number.toString();
        }

        let body = {
            BusinessShortCode: "6122464",
            Password: "",
            Timestamp: "",
            TransactionType: "CustomerBuyGoodsOnline",
            Amount: amount,
            PartyA: values.phone_number, // PartyB: "6122464",
            PartyB: "5726811",
            PhoneNumber: values.phone_number,
            CallBackURL: "https://api.pegasus-pms.co.ke/api/" + "pegasus/visionary/payments/subscription",
            AccountReference: "ITORAMA SOLUTIONS",
            TransactionDesc: "Subscription payment",
            landlord_id: JSON.parse(originalText)[0][1],
        };

        // console.log(body);
        fetch(ApiDetails + "pegasus/visionary/mpesa/landlord/StkPush", {
            method: "POST", body: JSON.stringify(body),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setTenantLoading(false);
                    showSnackbar({
                        title: "Success!",
                        message: "Kindly wait for M-Pesa 💵 prompt on you phone",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                } else if (response.status === 403) {
                    // console.log(data);
                    setTenantLoading(false);

                    showSnackbar({
                        title: "Error!",
                        message: new Map(Object.entries(data)).get("Message"),
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });

                }
                handleClose();
            })
            .catch((err) => {
                console.log(err);
                handleClose();
                setTenantLoading(false);
                showSnackbar({
                    title: "Error!",
                    message: "Payment not failed",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });

            });

        // fetchTenantDetails();
    };

    const logout = (event) => {
        // 👇️ toggle class on click
        window.location.href = "/";
        localStorage.clear();
    };

    let map;
    try {
        map = new Map(JSON.parse(originalText));
    } catch (e) {
    }

    const handleClick = (message) => {
        setMessage(message.text);
        setSeverity(message.type);
        setOpen(true);
    };

    const viewTopBarClick = () => {
        topBar();
    };

    return (
        <>

            <Dialog
                open={subscriptionModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{color: "#398DD2", fontWeight: "bold", textAlign: "center"}}>
                    Renew Subscription
                </DialogTitle>
                <DialogContent sx={{px: 3, py: 2, textAlign: "center"}}>
                    <Box sx={{width: "100%", pb: 2}}>
                        <Box
                            sx={{
                                p: 2,
                                color: "#959EAD",
                                borderRadius: 2,
                                border: "2px solid #398DD2",
                                backgroundColor: "#F8FAFD",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <strong>Price:</strong> {subscriptionAmount.toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                            <Button
                                sx={{color: "green", borderColor: "green"}}
                                disabled={subscribeButtonDisabled}
                                onClick={() => {
                                    setAmount(parseInt(subscriptionAmount));
                                    setPaymentModal(true);
                                }}
                                variant="outlined"
                            >
                                Subscribe Now
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{color: "red", fontWeight: "bold", pt: 2}}>Important:</Box>
                    <Box sx={{color: "#000", fontSize: "1rem", mt: 1}}>
                        Your subscription expired on: <strong>{dateSubscriptionExpired}</strong>
                    </Box>
                    <Box sx={{color: "#555", fontSize: "0.95rem", mt: 1}}>
                        Renew your subscription to avoid interruptions.
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: matches ? "column" : "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            pt: 3,
                        }}
                    >
                        <Box sx={{color: "#6C757D", fontSize: "0.85rem", textAlign: "left"}}>
                            After subscribing, you'll receive a confirmation message from IT-orama. Kindly log in again
                            to
                            update your payment details.
                        </Box>
                        <Button
                            disabled={subscribeButtonDisabled}
                            onClick={logout}
                            variant="outlined"
                            sx={{
                                textTransform: "uppercase",
                                // padding: 1.5,
                                // margin: 1.5,
                                borderRadius: 2,
                                display: "flex",
                                alignItems: "center",
                                borderColor: "#d32f2f",
                                color: "#d32f2f",
                                '&:hover': {borderColor: "#b71c1c", backgroundColor: "#ffebee"},
                            }}
                        >
                            <LogoutOutlined sx={{pr: 0.5}}/> Logout
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>


            <Dialog
                open={paymentModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "#398DD2"}}>Make Payment</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Formik
                            initialValues={initialPaymentValues}
                            validationSchema={basicSchema}
                            onSubmit={processPayment}
                        >
                            {(props) => (
                                <Form noValidate>
                                    <p>Enter your phone number to receive a payment prompt.</p>
                                    <Field
                                        as={TextField}
                                        name="phone_number"
                                        label="Phone Number"
                                        placeholder="e.g., 07XXXXXXXX"
                                        error={props.errors.phone_number && props.touched.phone_number}
                                        helperText={<ErrorMessage className="error" name="phone_number"/>}
                                        required
                                        variant="outlined"
                                        style={{marginTop: "10px", width: "100%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="amount"
                                        label="Amount"
                                        type="number"
                                        required
                                        variant="outlined"
                                        value={amount}
                                        InputLabelProps={{shrink: true}}
                                        style={{marginTop: "10px", width: "100%"}}
                                    />

                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: "20px",
                                        }}
                                    >
                                        <Button onClick={handleClose} style={{color: "red"}}>
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            type="submit"
                                            size="small"
                                            loading={tenantLoading}
                                            startIcon={<SendIcon/>}
                                            style={{color: "green"}}
                                            loadingPosition="start"
                                        >
                                            Submit
                                        </LoadingButton>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {set && (
                status === "yes" ? (
                    <div className="home">
                        <Stack spacing={2} sx={{width: "100%"}}>
                            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity={severity} sx={{width: "100%"}}>
                                    {message}
                                </Alert>
                            </Snackbar>
                        </Stack>
                        <OnboardingStepOne details={map} snackBar={handleClick} topBar={viewTopBarClick}/>
                    </div>) : (

                    <div className={subscriptionModal ? "home blur" : "home"}>

                        <FeaturedInfo/>

                        <ProgressBars/>

                        <div className="homeWidgets">
                            {/*<WidgetSm />*/}
                            <WidgetLg/>
                        </div>
                    </div>
                )
            )}

        </>
    );
};

export default HomeV2;