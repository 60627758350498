import React, {useEffect, useState} from "react";
import "./testSidebar.css";
import {Menu, MenuItem, Sidebar, SubMenu, useProSidebar} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import {
    AutoGraphOutlined,
    BackupOutlined,
    BusinessOutlined,
    ChatBubbleOutlineOutlined,
    DashboardOutlined,
    ElderlyOutlined,
    ErrorOutlineOutlined,
    GroupsOutlined,
    LogoutOutlined,
    MonetizationOnOutlined,
    MoneyOffCsredOutlined,
    PaymentsOutlined,
    PeopleOutlined,
    SettingsOutlined,
} from "@mui/icons-material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {useMediaQuery} from "@mui/material";
import CryptoJS from "crypto-js";
import logo from "../../assets/react-logo-no-background.svg";
import {KEY} from "../../dummyData";

export default function TestSidebar() {
    const isMobile = useMediaQuery("(max-width:800px)");
    const {collapseSidebar} = useProSidebar();

    const decryptLocalStorage = () => {
        try {
            const encryptedData = localStorage.getItem("myMap");
            if (!encryptedData) return "";
            const decryptedData = CryptoJS.AES.decrypt(encryptedData, KEY).toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedData);
        } catch {
            return null;
        }
    };

    const [originalText, setOriginalText] = useState(decryptLocalStorage());
    const [logoVisible, setLogoVisible] = useState(true);

    useEffect(() => {
        if (isMobile) {
            collapseSidebar();
            setLogoVisible(false);
        }
    }, [isMobile, collapseSidebar]);

    const logout = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    const menuItemStyles = {
        button: ({level, active}) => ({
            color: active ? "#e2684f" : "#2c3e50", // Active items white, inactive deep slate gray
            backgroundColor: active
                ? level === 0
                    ? "#d4f4ff" // Primary blue for level 0
                    : "#F3F7FA" // Primary green for level 1
                : undefined,
            "&:hover": {
                backgroundColor: "#f4f6f8", // Light gray for hover
            },
        }),
    };

    const renderLogo = () => (
        <div className="topLeft" style={{textAlign: "center", marginBottom: "20px"}}>
            <img
                src={logo}
                alt="React Logo"
                style={{
                    width: "150px",
                    height: "auto",
                    display: logoVisible ? "" : "none",
                }}
            />
        </div>
    );

    const renderMenuItems = () => (
        <>
            <MenuItem
                active={window.location.pathname === "/home"}
                component={<Link to="/home"/>}
                icon={<DashboardOutlined style={{color: "#2E588A"}}/>}
            >
                Dashboard
            </MenuItem>
            <MenuItem
                active={window.location.pathname === "/analytics"}
                component={<Link to="/analytics"/>}
                icon={<AutoGraphOutlined style={{color: "#2E588A"}}/>}
            >
                Analytics
            </MenuItem>
            <SubMenu
                icon={<GroupsOutlined style={{color: "#2E588A"}}/>}
                label="Tenants"
                active={window.location.pathname.toLowerCase().includes("tenant")}
            >
                <MenuItem
                    active={window.location.pathname === "/tenantList"}
                    component={<Link to="/tenantList"/>}
                    icon={<PeopleOutlined style={{color: "#2E588A"}}/>}
                >
                    All Tenants
                </MenuItem>
                <MenuItem
                    active={window.location.pathname === "/vacatingTenantList"}
                    component={<Link to="/vacatingTenantList"/>}
                    icon={<PeopleOutlined style={{color: "#2E588A"}}/>}
                >
                    Vacating Tenants
                </MenuItem>
                <MenuItem
                    active={window.location.pathname === "/oldTenantList"}
                    component={<Link to="/oldTenantList"/>}
                    icon={<ElderlyOutlined style={{color: "#2E588A"}}/>}
                >
                    Old Tenants
                </MenuItem>
               {/* <MenuItem
                    active={window.location.pathname === "/tenantIssues"}
                    component={<Link to="/tenantIssues"/>}
                    icon={<ErrorOutlineOutlined style={{color: "#2E588A"}}/>}
                >
                    Tenant Issues
                </MenuItem>*/}
            </SubMenu>
            <MenuItem
                active={window.location.pathname === "/propertyList"}
                component={<Link to="/propertyList"/>}
                icon={<BusinessOutlined style={{color: "#2E588A"}}/>}
            >
                Properties
            </MenuItem>
            <SubMenu
                icon={<MonetizationOnOutlined style={{color: "#2E588A"}}/>}
                label="Money"
                active={["transactions", "expenses"].some((path) => window.location.pathname.toLowerCase().includes(path))}
            >
                <MenuItem
                    active={window.location.pathname === "/transactions"}
                    component={<Link to="/transactions"/>}
                    icon={<PaymentsOutlined style={{color: "#2E588A"}}/>}
                >
                    Transactions
                </MenuItem>
                <MenuItem
                    active={window.location.pathname === "/expenses"}
                    component={<Link to="/expenses"/>}
                    icon={<MoneyOffCsredOutlined style={{color: "#2E588A"}}/>}
                >
                    Expenses
                </MenuItem>
            </SubMenu>
            <MenuItem
                active={window.location.pathname === "/messages"}
                component={<Link to="/messages"/>}
                icon={<ChatBubbleOutlineOutlined style={{color: "#2E588A"}}/>}
            >
                Messages
            </MenuItem>
            <MenuItem
                active={window.location.pathname === "/backup"}
                component={<Link to="/backup"/>}
                icon={<BackupOutlined style={{color: "#2E588A"}}/>}
            >
                Backup
            </MenuItem>
            <MenuItem
                active={window.location.pathname === "/settings"}
                component={<Link to="/settings"/>}
                icon={<SettingsOutlined style={{color: "#2E588A"}}/>}
            >
                Settings
            </MenuItem>
            <MenuItem onClick={logout} icon={<LogoutOutlined style={{color: "#e74c3c"}}/>}>
                Logout
            </MenuItem>
        </>
    );

    return (
        <div
            style={{
                zIndex: 1000,
                display: ["/", "/signup"].includes(window.location.pathname) ? "none" : "",
                // backgroundColor: "#FFE6D9"
            }}
        >
            <Sidebar style={{ height: "100vh", position: "sticky", top: 0 }}>
            {/*<Sidebar style={{height: "100vh", position: "sticky", top: 0}}>*/}

                <Menu menuItemStyles={menuItemStyles}>
                    <MenuItem
                        icon={<MenuOutlinedIcon/>}
                        onClick={() => {
                            if (!isMobile) setLogoVisible(!logoVisible);
                            collapseSidebar();
                        }}
                        style={{textAlign: "center"}}
                    />
                    {renderLogo()}
                    {renderMenuItems()}
                </Menu>
            </Sidebar>
        </div>
    );
}
