import React, {useEffect, useRef, useState} from "react";
import "../../property.css";

import Button from "@mui/material/Button";
import {InputLabel, TextField} from "@material-ui/core";
import {
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {ApiDetails, KEY} from "../../../../dummyData";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import CryptoJS from "crypto-js";
import DeleteIcon from "@mui/icons-material/Delete";
import {useConfirm} from "material-ui-confirm";
import {showSnackbar} from "../../../../components/SnackBar/SnackBar";
import {styled} from "@mui/system";
import Slide from "@mui/material/Slide";

const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    "& .MuiDataGrid-root": {
        height: "400px", // Prevents it from exceeding 500px
        minHeight: "200px", // Ensures it remains visible even with few rows
        overflowY: "auto",  // Enables scrolling when needed
    },
    "& .MuiDataGrid-columnHeaders": {
        fontSize: "1rem",
        fontWeight: 700,
        backgroundColor: "#E3F2FD", // Light blue background
        color: "#0D47A1", // Dark blue text
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "#f5faf5", // Light green hover color
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell": {
        fontSize: "0.9rem",
    },
}));


// Slide transition for smoother opening
const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
));

// Styled components
const styles = {
    dialogTitle: {
        textAlign: "center",
        fontWeight: "bold",
        color: "#2C5283",
        padding: "16px",
        borderBottom: "2px solid #2C5283",
    },
    dialogContent: {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    container: {
        height: "500px",
        width: "100%",
        maxWidth: "90vw",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
        // padding: "15px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    },
    billingHeader: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "20px",
    },
    label: {
        fontSize: "1rem",
        fontWeight: 600,
        color: "#444",
    },
    cancelButton: {
        color: "#d32f2f",
        borderColor: "#d32f2f",
        "&:hover": {
            backgroundColor: "rgba(211, 47, 47, 0.1)",
        },
    },
    billButton: {
        backgroundColor: "#2C5283",
        color: "white",
        "&:hover": {
            backgroundColor: "#1E3A63",
        },
    },
};

export default function UpdateUnitsDetails(props) {

    const {propertyDetails, tenants, setBillTenantsModal, billTenantsModal} = props;

    const [propertyId, setPropertyId] = React.useState(propertyDetails.property_id);

    const confirm = useConfirm();

    let [meterReadings, setMeterReadings] = useState([]);
    let [electricityMeterReadings, setElectricityMeterReadings] = useState([]);
    const [warning, setWarning] = useState("");


    let myMapDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.getItem("myMap"), KEY);
    let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.portfolioDetails, KEY);
    let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    const [property, setProperty] = useState({
        expected_income: 0, agency_id: 0,
    });

    //Trying To Use UseEffect To Set Component Data
    useEffect(() => {
        getUnits()
    }, []);


    //Add unit parameters
    const [unitName, setUnitName] = useState("");
    const [unitMeterReading, setUnitMeterReading] = useState("");
    const [electricityUnitMeterReading, setElectricityUnitMeterReading] = useState("");

    //Units State
    // const [allUnits, setAllUnits] = useState([]);
    const [units, setUnits] = React.useState([]);
    const [selectedUnit, setSelectedUnit] = React.useState(null);


    const getUnits = () => {
        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/property/getUnits", {
            method: "POST", body: JSON.stringify({property_id: propertyId}),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setUnits(data);
                    console.log(data)
                    showSnackbar({
                        title: "Success!",
                        message: "Units retrieved successfully.",
                        type: "success",
                        position: {vertical: "bottom", horizontal: "right"},
                    });
                } else {

                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Please try again later.",
                        type: "error",
                        position: {vertical: "bottom", horizontal: "right"},
                    });

                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Please try again later.",
                    type: "error",
                    position: {vertical: "bottom", horizontal: "right"},
                });

            });
    };

    const addUnit = () => {
        if (unitName.trim() === "" || unitMeterReading.trim() === "" || electricityUnitMeterReading.trim() === "") {

            showSnackbar({
                title: "Error!",
                message: "Kindly fill in all the details",
                type: "error",
                position: {vertical: "bottom", horizontal: "right"},
            });

            return;
        }

        fetch(ApiDetails + "pegasus/visionary/property/add/unit", {
            method: "POST", body: JSON.stringify({
                property_id: propertyId,
                unit: unitName,
                state: "VACANT",
                meter_reading: unitMeterReading,
                electricity_meter_reading: electricityUnitMeterReading,
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setUnits(data);
                    getUnits();
                    showSnackbar({
                        title: "Success!",
                        message: "Unit Added Successfully",
                        type: "success",
                        position: {vertical: "bottom", horizontal: "right"},
                    });
                } else if (response.status === 500) {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Please try again later.",
                        type: "error",
                        position: {vertical: "bottom", horizontal: "right"},
                    });

                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Please try again later.",
                    type: "error",
                    position: {vertical: "bottom", horizontal: "right"},
                });

            });
    };

    const DeleteUnit = (unitId) => {
        fetch(ApiDetails + "pegasus/visionary/property/delete/unit", {
            method: "POST", body: JSON.stringify({
                property_id: propertyId,
                id: unitId,
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setUnits(data);
                    getUnits();
                    showSnackbar({
                        title: "Success!",
                        message: "Units Deleted successfully.",
                        type: "success",
                        position: {vertical: "bottom", horizontal: "right"},
                    });
                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Please try again later.",
                        type: "error",
                        position: {vertical: "bottom", horizontal: "right"},
                    });

                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Please try again later.",
                    type: "error",
                    position: {vertical: "bottom", horizontal: "right"},
                });
            });
    };


    const updateUnits = () => {
        let finalChanges = [];
        // console.log(meterReadings);
        // console.log(electricityMeterReadings);

        if (meterReadings > electricityMeterReadings) {
            meterReadings.forEach((reading) => {
                electricityMeterReadings.forEach((readings2) => {
                    if (reading.unit === readings2.unit) {
                        finalChanges = [...finalChanges, {
                            unit: reading.unit,
                            meter_reading: reading.meter_reading,
                            electricity_meter_reading: readings2.electricity_meter_reading,
                        },];
                        meterReadings = meterReadings.filter((reading) => reading.unit !== readings2.unit);
                        electricityMeterReadings = electricityMeterReadings.filter((reading) => reading.unit !== readings2.unit);
                    }
                });
            });
        } else {
            electricityMeterReadings.forEach((reading) => {
                meterReadings.forEach((readings2) => {
                    if (reading.unit === readings2.unit) {
                        finalChanges = [...finalChanges, {
                            unit: reading.unit,
                            meter_reading: readings2.meter_reading,
                            electricity_meter_reading: reading.electricity_meter_reading,
                        },];
                        meterReadings = meterReadings.filter((reading) => reading.unit !== readings2.unit);
                        electricityMeterReadings = electricityMeterReadings.filter((reading) => reading.unit !== readings2.unit);
                    }
                });
            });
        }

        let finalMeterReadings = [];
        meterReadings.forEach((reading) => {
            reading = {...reading, electricity_meter_reading: 0};
            finalMeterReadings = [...finalMeterReadings, reading];
        });

        let finalElectricityMeterReadings = [];
        electricityMeterReadings.forEach((reading) => {
            reading = {...reading, meter_reading: 0};
            finalElectricityMeterReadings = [...finalElectricityMeterReadings, reading,];
        });

        finalChanges = [...finalChanges, ...finalMeterReadings, ...finalElectricityMeterReadings,];
        // console.log(finalChanges);
        // return;
        // Make API call
        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/property/update/Units", {
            method: "POST", body: JSON.stringify({
                property_id: propertyId, units: finalChanges,
            }),
        })
            .then(async (response) => {
                // let data = await response.json();
                if (response.status === 200) {
                    // console.log(data);
                    // setAllUnits(data);
                    getUnits();
                    showSnackbar({
                        title: "Success!",
                        message: "Units Updated successfully.",
                        type: "success",
                        position: {vertical: "bottom", horizontal: "right"},
                    });

                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Please try again later.",
                        type: "error",
                        position: {vertical: "bottom", horizontal: "right"},
                    });

                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Please try again later.",
                    type: "error",
                    position: {vertical: "bottom", horizontal: "right"},
                });

            });
    };

    const downloadUnitReport = () => {
        setBillLoading(true)
        let fileName;

        fetch(ApiDetails + "pegasus/visionary/property/unit/report", {
            method: "POST", body: JSON.stringify({
                property_id: propertyId, id: selectedUnit,
            }),
        })
            .then(async (response) => {
                // let data = await response.json();
                if (response.status === 200) {
                    // console.log(data);
                    // setAllUnits(data);
                    setBillLoading(false)

                    // getPropertyDetails();
                    showSnackbar({
                        title: "Success!",
                        message: "Download Will Start Shortly",
                        type: "success",
                        position: {vertical: "bottom", horizontal: "right"},
                    });


                    for (let pair of response.headers.entries()) {
                        // accessing the entries
                        if (pair[0] === "content-disposition") {
                            // key I'm looking for in this instance
                            fileName = pair[1]; // saving that value where I can use it
                        }
                    }

                    let blob = await response.blob();

                    let href = window.URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);


                } else {
                    setBillLoading(false)
                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Please try again later.",
                        type: "error",
                        position: {vertical: "bottom", horizontal: "right"},
                    });
                }
            })
            .catch((err) => {
                setBillLoading(false)
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Please try again later.",
                    type: "error",
                    position: {vertical: "bottom", horizontal: "right"},
                });
            });

    };


    //Loading button states
    const [billLoading, setBillLoading] = React.useState(false);

    const unitsColumns = [
        /*{
            field: "unit", headerName: "Unit",
            flex: 1,
            minWidth: 0, sortOrder: "asc",
        },*/
        {field: "unit", headerName: "Unit", width: 200},
        {
            field: "state", headerName: "State",
            flex: 1,
            minWidth: 100
        }, {
            field: "meter_reading", headerName: "Water", flex: 1,
            minWidth: 100,
        }, {
            field: "electricity_meter_reading", headerName: "Electricity ", flex: 1,
            minWidth: 100,
        }, {
            field: "water", headerName: "Update Water", flex: 1,
            minWidth: 150, renderCell: (params) => {
                return (<div className="userListAction">
                    <TextField
                        type={"number"}
                        placeholder="Enter Reading ..."
                        onChange={(e) => {
                            let filteredTenantArray = meterReadings.filter((tenant) => {
                                return tenant.unit !== params.row.unit;
                            });

                            // console.log(filteredTenantArray);

                            // let newReading = e.target.value;
                            let newReading = parseFloat(e.target.value);

                            setMeterReadings([...filteredTenantArray, {
                                unit: params.row.unit, meter_reading: newReading,
                            },]);
                        }}
                    />
                </div>);
            },
        }, {
            field: "electricity", headerName: "Update Electricity ", flex: 1,
            minWidth: 150, renderCell: (params) => {
                return (<div className="userListAction">
                    <TextField
                        type={"number"}
                        placeholder="Enter Reading ..."
                        onChange={(e) => {
                            let filteredTenantArray = electricityMeterReadings.filter((tenant) => {
                                return tenant.unit !== params.row.unit;
                            });

                            // console.log(filteredTenantArray);

                            // let newReading = e.target.value;
                            let newReading = parseFloat(e.target.value);

                            setElectricityMeterReadings([...filteredTenantArray, {
                                unit: params.row.unit, electricity_meter_reading: newReading,
                            },]);
                        }}
                    />
                </div>);
            },
        },
        {
            field: "", headerName: "", flex: 1,
            minWidth: 150, renderCell: (params) => {
                return (<div className="userListAction">
                    <Button variant="outlined"
                            onClick={async () => {

                                //Seek confirmation

                                let deleteUnit = false;
                                console.log(params)

                                await confirm({
                                    description: `You are about to delete unit ${params.row.unit}`
                                })
                                    .then(() => {
                                        deleteUnit = true
                                    })
                                    .catch(() => deleteUnit = false);


                                if (deleteUnit) {
                                    DeleteUnit(params.row.id)
                                }
                            }}
                            startIcon={<DeleteIcon/>}>
                        Delete
                    </Button>
                </div>);
            },
        }
    ];

    if (propertyDetails.property_id === "") return <div>Loading...</div>;

    return (<div className="container mx-auto p-6">
        <h2 className="text-2xl font-bold text-center mb-6">Generate Unit Report</h2>
        <div className="flex justify-center gap-6">
            <FormControl className="w-1/3" disabled={!units.length}>
                <InputLabel>Select House No</InputLabel>
                <Select
                    value={selectedUnit}
                    onChange={(e) => setSelectedUnit(e.target.value)}
                >
                    {units.length ? (
                        units.map((unit, index) => (
                            <MenuItem key={index} value={unit.id}>
                                {unit.unit}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                    )}
                </Select>
                <FormHelperText>Required</FormHelperText>
            </FormControl>
        </div>

        <div className="flex justify-center mt-6">
            <LoadingButton
                onClick={downloadUnitReport}
                loading={billLoading}
                startIcon={<SendIcon/>}
                disabled={!!warning}
                variant="contained"
                color="primary"
            >
                Generate Report
            </LoadingButton>
        </div>

        <h2 className="text-2xl font-bold text-center mt-10 mb-6">Edit Property Units</h2>
        <div className="border p-6 rounded-lg shadow-lg bg-white">
            <div className="flex justify-between gap-6">

                <TextField
                    label="Unit"
                    variant="outlined"
                    className="w-1/3 light-blue"
                    onChange={(e) => setUnitName(e.target.value)}
                />
                <TextField
                    label="Meter Reading"
                    variant="outlined"
                    className="w-1/3"
                    onChange={(e) => setUnitMeterReading(e.target.value)}
                />
                <TextField
                    label="Electricity Reading"
                    variant="outlined"
                    className="w-1/3"
                    onChange={(e) => setElectricityUnitMeterReading(e.target.value)}
                />
            </div>
            <div className="flex justify-end mt-4">
                <Button onClick={addUnit} variant="contained" color="success">
                    Add Unit
                </Button>
            </div>
        </div>

        <div className="mt-6 bg-white p-4 shadow-lg rounded-lg">
            {/* <DataGrid
                autoHeight
                initialState={{sorting: {sortModel: [{field: "unit", sort: "asc"}]}}}
                className="bg-white"
                rows={units}
                columns={[{field: "unit", headerName: "Unit", width: 200}]}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />*/}
            <StyledDataGrid
                autoHeight
                // initialState={{sorting: {sortModel: [{field: "unit", sort: "asc"}]}}}
                rows={units}
                columns={unitsColumns}
                disableSelectionOnClick
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
        </div>

        <div className="flex justify-end mt-4">
            <Button onClick={updateUnits} variant="contained" color="primary">
                Update Units
            </Button>
        </div>
    </div>);
}