import React, {useEffect, useRef, useState} from "react";
import "./property.css";
import {
    AddLink,
    DeleteOutline,
    PaymentsOutlined,
    PercentOutlined,
    RequestQuoteOutlined,
    WarningAmberOutlined,
} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {InputLabel, TextField, Typography} from "@material-ui/core";
import Textarea from "@mui/joy/Textarea";
import {
    Autocomplete,
    FormControl,
    FormHelperText,
    Menu,
    MenuItem,
    Select,
    TextareaAutosize,
    useMediaQuery,
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@material-ui/core/styles";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {
    AccountCircleOutlined,
    AddCircleOutlined,
    ApartmentOutlined,
    CheckCircleOutlineOutlined,
    Close,
    DoneOutlined,
    EditOutlined,
    HomeOutlined,
    LocationOnOutlined,
    Message,
    MoreVert,
    SecurityOutlined,
} from "@material-ui/icons";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import {ApiDetails, KEY} from "../../dummyData";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import PropertiesMap from "../../components/Map/PropertiesMap";
import {updatePropertyPaymentDetailsSchema, updatePropertySchema} from "../../components/schemas/Schemas";
import Switch from "@mui/material/Switch";
import CryptoJS from "crypto-js";
import DeleteIcon from '@mui/icons-material/Delete';
import {useConfirm} from "material-ui-confirm";
import Chip from "@mui/material/Chip";
import {ErrorIcon} from "react-hot-toast";


const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        }, "& .MuiDataGrid-columnHeaders": {
            fontSize: 16, color: "darkblue", backgroundColor: "rgb(245,245,245)",
        },
    },
});
const Transition = React.forwardRef(function Transition(props: TransitionProps & {
    children: React.ReactElement<any, any>,
}, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Property(props) {
    const matches = useMediaQuery("(max-width:700px)");

    const confirm = useConfirm();

    const textRef = useRef();

    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber);

        return date.toLocaleString("en-US", {month: "long"});
    }

    const [currentMonth] = useState(getMonthName(new Date().getMonth()));

    const [month, setMonth] = useState(new Date(2020, 11, 17, 0, 0, 0, 0));
    const [selectedMonth, setSelectedMonth] = useState(null);

    const {state} = useLocation();
    // let { property, tenants } = state;
    let {property_id} = state;

    let myMapDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.getItem("myMap"), KEY);
    let portfolioDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.portfolioDetails, KEY);
    let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    portfolioDetailsDecrypted.toString(CryptoJS.enc.Utf8);
    const ref = useRef();
    const handleClick = (e) => {
        ref.current.click();
    };

    const label = {inputProps: {"aria-label": "Switch demo"}};

    const [checked, setChecked] = React.useState(true);
    const [penalties, setPenalties] = useState("");

    const [agency_id, setAgency_id] = useState("");

    //Broadcast message
    const [broadcastMessage, setBroadcastMessage] = useState("");

    //Property Image
    const [propertyImage, setPropertyImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(false);

    const [updateState, setUpdateState] = useState(false);
    const [finished, setFinished] = useState(false);
    const [property, setProperty] = useState({
        expected_income: 0, agency_id: 0,
    });
    const [expectedIncome, setExpectedIncome] = useState(0);
    const [incomeReceived, setIncomeReceived] = useState(0);
    let [tenants, setTenants] = useState({});
    let [tenantsLength, setTenantsLength] = useState(0);

    //Loading Screen
    const [backDrop, setBackDrop] = useState(<Backdrop
        sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={true}
        onClick={null}
    >
        <CircularProgress color="inherit"/>
    </Backdrop>);

    //Trying To Use UseEffect To Set Component Data
    useEffect(() => {
        fetch(ApiDetails + "pegasus/visionary/property/get/Specific/property", {
            method: "POST", body: JSON.stringify({
                property_id: property_id, landlord_id: JSON.parse(originalText)[0][1],
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                // console.log(data);
                if (response.status === 200) {
                    //Fetch Property Tenants
                    fetch(ApiDetails + "pegasus/visionary/tenant/get/property/tenants", {
                        method: "POST", body: JSON.stringify({property_id: property_id}),
                    })
                        .then(async (response) => {
                            let tenants = await response.json();
                            // console.log(tenants);
                            if (response.status === 200) {
                                // console.log(data);
                                setProperty(data);
                                setExpectedIncome(data.expected_income);
                                setIncomeReceived(data.income_received);
                                setPenalties(data.apply_penalty);
                                setTenants(tenants);
                                setTenantsLength(tenants.length);
                                // tenantsLength = tenants.length;
                                setSearchResults(tenants);
                                setWaterBill(data.water_bill);
                                setElectricityBill(data.electricity_bill);
                                setMinimumWaterBIll(parseFloat(data.minimum_water_bill));
                                setMinimumElecBIll(parseFloat(data.minimum_electricity_bill));

                                let tenantsWithNoMeterReadings = tenants.filter((tenant) => {
                                    if (tenant.meter_reading === undefined) {
                                        return tenant;
                                    }
                                });

                                if (tenantsWithNoMeterReadings.length > 0) {
                                    setWarning(true);
                                }

                                setFinished(true);

                                setBackDrop(<></>);
                                try {
                                    let pic;

                                    fetch(ApiDetails + "pegasus/visionary/property/get/Specific/property/photo", {
                                        method: "POST", body: JSON.stringify({
                                            property_id: data.property_id,
                                        }),
                                    })
                                        .then(async (response) => {
                                            let data = await response.json();
                                            if (response.status === 200) {
                                                pic = data.property_image;

                                                if (pic.base64 !== "No File Found") {
                                                    setPropertyImage("data:image/jpeg;base64," + pic.base64);
                                                }
                                                // console.log(pic);
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                } catch (e) {
                                    // console.log(e);
                                }
                            } else {
                                // props.snackBar({
                                //   text: "Failed fetching tenant details.",
                                //   type: "error",
                                // });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            props.snackBar({
                                text: "Could not set property photo.", type: "error",
                            });
                        });
                } else {
                    // props.snackBar({
                    //   text: "Failed fetching tenant details.",
                    //   type: "error",
                    // });
                }
            })
            .catch((err) => {
                console.log(err);
                // props.snackBar({
                //   text: "Something Went Wrong. Call Customer Care For Assistance.",
                //   type: "error",
                // });
            });
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    //Updated Properties
    let [currentTenants] = useState(null);

    let [billedTenants, setBilledTenants] = useState([]);
    let [elecBilledTenants, setElecBilledTenants] = useState([]);

    let [meterReadings, setMeterReadings] = useState([]);
    let [electricityMeterReadings, setElectricityMeterReadings] = useState([]);
    const [warning, setWarning] = useState("");

    //Navigator
    const navigate = useNavigate();

    //Modal states
    const [arrearsModal, setArrearsModal] = useState(false);
    const [transactionModal, setTransactionModal] = useState(false);
    const [addTenantModal, setAddTenantModal] = useState(false);
    const [deleteModal, setOpen] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editPaymentDetailsModal, setEditPaymentDetailsModal] = useState(false);
    const [locationModal, setLocationModal] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [unitsModal, setUnitsModal] = useState(false);
    const [billTenantsModal, setBillTenantsModal] = useState(false);

    const [agencyTokenModal, setAgencyTokenModal] = useState(false);
    const [depositModal, setDepositModal] = useState(false);
    const [unitReportModal, setUnitReportModal] = useState(false);

    const [refundableDeposit, setRefundableDeposit] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);

    const [depositSet, setDepositSet] = useState(false);

    const [inputs, setInputs] = useState();

    const [expensesIncurred, setExpensesIncurred] = useState(1);
    let [depositList, setDepositList] = useState([new Map()]);
    const [expenseItem, setExpenseItem] = useState(new Map());

    const calculateRefundableDeposit = () => {

        try {
            expenseItem.forEach((value, key) => {
                let array = key.split(" ");
                let uniqueString = array[array.length - 1];

                if (expenseItem.has("refundable " + uniqueString)) {
                    let refundableValue = expenseItem.get("refundable " + uniqueString);

                    if (!(refundableValue.toString().trim().toUpperCase() === "YES" || refundableValue.toString().trim().toUpperCase() === "NO")) {
                        throw new Error("Fill in either YES OR NO for refundable")
                    }

                } else {
                    throw new Error("Fill in if refundable for all entries")
                }
            });
        } catch (e) {
            // console.log(e)
            props.snackBar({
                text: e.message, type: "error",
            });
            return;
        }

        let depositAmount = 0;

        expenseItem.forEach((value, key) => {
            if (key.toString().includes("value")) {
                depositAmount += parseFloat(value);
            }
        });

        setTotalDeposit(depositAmount)

        expenseItem.set("Total deposit", depositAmount)

        let refundableDepositAmount = 0;

        let handledKeys = [];

        expenseItem.forEach((value, key) => {
            let array = key.split(" ");
            let uniqueString = array[array.length - 1];


            if (handledKeys.includes(uniqueString)) {
                return
            }

            if (expenseItem.has("refundable " + uniqueString)) {
                let refundableValue = expenseItem.get("refundable " + uniqueString);

                if (refundableValue.toString().toUpperCase().trim() === "YES") {
                    refundableDepositAmount += parseFloat(expenseItem.get("value for description " + uniqueString))
                }

            }
            handledKeys.push(uniqueString)

        });

        setRefundableDeposit(refundableDepositAmount)
        expenseItem.set("Refundable deposit", refundableDepositAmount)

        setDepositList(new Map([...expenseItem]))

        expenseItem.delete("Refundable deposit")
        expenseItem.delete("Total deposit")

        setDepositSet(true)
    };
    const handleChange = (props) => (event) => {
        // kkk;
        expenseItem.set(event.target.name, event.target.value);
        setDepositSet(false)

        // setDepositList(depositMap)
    };

    const [loading1, setLoading1] = React.useState(false);

    //Add unit parameters
    const [unitState, setUnitState] = useState("");
    const [unitName, setUnitName] = useState("");
    const [unitMeterReading, setUnitMeterReading] = useState("");
    const [electricityUnitMeterReading, setElectricityUnitMeterReading] = useState("");

    //Transaction Date
    const [dateFrom, setDateFrom] = React.useState(new Date());

    //Units State
    const [allUnits, setAllUnits] = useState([]);
    const [units, setUnits] = React.useState(null);
    const [selectedUnit, setSelectedUnit] = React.useState(null);

    const [isStudent, setIsStudent] = React.useState("NO");

    const [addTenantPropertyName, setAddTenantPropertyName] = React.useState("Select Property");
    let [propertyID, setPropertyID] = React.useState(null);

    let [tenantInfo, setTenantInfo] = React.useState({
        tenant_id: "", name: "", last_name: "", unit: "", property_id: "", account_number: "",
    });

    const [waterBill, setWaterBill] = React.useState(property.water_bill);
    const [minimumWaterBill, setMinimumWaterBIll] = React.useState(parseFloat(property.minimum_water_bill));

    const [electricityBill, setElectricityBill] = React.useState(property.electricity_bill);
    const [minimumElecBill, setMinimumElecBIll] = React.useState(parseFloat(property.minimum_electricity_bill));

    const [searchResults, setSearchResults] = useState(tenants);
    const [searchUpdateResults, setSearchUpdatedResults] = useState(currentTenants);

    const [accountType, setAccountType] = useState({label: "Buy Goods", value: 'CustomerBuyGoodsOnline'});


    let initialValues = {
        id: "", first_name: "", description: "", amount: "",
    };
    let initialValues4 = {
        id: "", first_name: "", date: dateFrom, amount: "",
    };

    const initialValues3 = {
        property_id: property.property_id,
        property_name: property.property_name,
        other_charges: property.other_charges,
        location: property.location,
        water_bill: property.water_bill,
        electricity_bill: property.electricity_bill,
        minimum_electricity_bill: property.minimum_electricity_bill,
        minimum_water_bill: property.minimum_water_bill,
        total_property_units: property.total_property_units,
        query_number: property.query_number,
        payment_details: property.payment_details,
        occupancy: property.occupancy,
        dummy: "",
        minimum_days_to_vacate: property.minimum_days_to_vacate,
    };

    const initialValues5 = {
        property_id: property.property_id,
        penalty_date: property.penalty_date,
        reminder_date: property.reminder_date,
        penalty_percentage: property.penalty_percentage,
    };

    const initialValues6 = {
        pass_key: property.pass_key,
        ho_number: property.ho_number,
        short_code: property.short_code,
        till_number: property.till_number,
        property_id: property.property_id,
        consumer_secret: property.consumer_secret,

    };

    const initialValues2 = {
        first_name: "",
        landlord_id: JSON.parse(originalText)[0][1],
        occupation_or_profession: "",
        last_name: "",
        phone_number: "",
        secondary_phone_number: "",
        national_id: "",
        next_of_kin_relationship: "",
        next_of_kin_first_name: "",
        next_of_kin_last_name: "",
        next_of_kin_national_id: "",
        next_of_kin_contacts: "",
        institution_name: "",
        place_of_work: "",
        reason_for_relocating: "",
        previous_residence: "",
        email_address: "",
        rent: "",
        deposit_held: "",
    };

    //Tenant Photo
    const [tenantPhoto, setTenantPhoto] = useState(null);
    const [photo, setPhoto] = useState(null);

    //Tenant National ID
    const [photoOfNationalID, setPhotoOfNationalID] = useState(null);
    const [nationalID, setNationalID] = useState(null);

    //Tenant Contract
    const [copyOfContract, setCopyOfContract] = useState(null);
    const [contract, setContract] = useState(null);

    //Tenant Checklist
    const [inventoryChecklist, setInventoryChecklist] = useState(null);
    const [checklist, setChecklist] = useState(null);

    const [tenantLoading, setTenantLoading] = React.useState(false);

    const [depositAsArrears, setDepositAsArrears] = React.useState(true);
    const [rentAsArrears, setRentAsArrears] = React.useState(true);


    function getBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setPropertyImage(reader.result);
            // console.log(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }

    function uploadPropertyImage(e) {
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            getBase64(e.target.files[0]);

            let values = {
                landlord_id: JSON.parse(originalText)[0][1],
                property_name: property.property_name,
                property_id: property.property_id,
            };

            let formData = new FormData();
            //Add values to formData
            formData.append("body", JSON.stringify(values));
            formData.append("property_image", e.target.files[0]);

            fetch(ApiDetails + "pegasus/visionary/property/update/image", {
                method: "POST", body: formData,
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        getPropertyDetails();
                        setUpdateState(!updateState);
                        props.snackBar({
                            text: "Property Details Updated Successfully", type: "success",
                        });
                    } else if (response.status === 401) {
                        props.snackBar({
                            text: "Maximum concurrent logins reached", type: "warning",
                        });
                    } else if (response.status === 500) {
                        props.snackBar({
                            text: "Something went wrong. Please try again later.", type: "error",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later", type: "error",
                    });
                });
        } else {
            props.snackBar({
                text: "Please Upload An Image File", type: "warning",
            });
        }
    }

    function handleFile1(e) {
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            setPhoto(filename);
            setTenantPhoto(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload An Image File", type: "warning",
            });
        }
    }

    function handleFile2(e) {
        let filename = getFileName(e);
        if (checkImageFile(filename)) {
            setNationalID(filename);
            setPhotoOfNationalID(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload An Image File", type: "warning",
            });
        }
    }

    function handleFile3(e) {
        let filename = getFileName(e);
        if (checkTextFile(filename)) {
            setContract(filename);
            setCopyOfContract(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload A text,doc,docx or pdf File", type: "warning",
            });
        }
    }

    function handleFile4(e) {
        let filename = getFileName(e);
        if (checkTextFile(filename)) {
            setChecklist(filename);
            setInventoryChecklist(e.target.files[0]);
        } else {
            props.snackBar({
                text: "Please Upload A text,doc,docx or pdf File", type: "warning",
            });
        }
    }

    const getFileName = (e) => {
        let startIndex = e.target.value.indexOf("\\") >= 0 ? e.target.value.lastIndexOf("\\") : e.target.value.lastIndexOf("/");
        let filename = e.target.value.substring(startIndex);
        if (filename.indexOf("\\") === 0 || filename.indexOf("/") === 0) {
            filename = filename.substring(1);
        }
        return filename;
    };

    function getExtension(filename) {
        let parts = filename.split(".");
        return parts[parts.length - 1];
    }

    function checkImageFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "jpg":
            case "jpeg":
            case "png":
            case "jepg":
                //etc
                return true;
        }
        return false;
    }

    function checkTextFile(filename) {
        let ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case "txt":
            case "doc":
            case "docx":
            case "pdf":
                //etc
                return true;
        }
        return false;
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const handleClose = () => {
        setOpen(false);
        setEditModal(false);
        setUnitsModal(false);
        setArrearsModal(false);
        setMessageModal(false);
        setDepositModal(false);
        setLocationModal(false);
        setAddTenantModal(false);
        setTransactionModal(false);
        setBillTenantsModal(false);
        setAgencyTokenModal(false);
        setEditPaymentDetailsModal(false)
    };
    const handleDelete = () => {
        setLoading(true);

        //Make API call to delete tenant
        fetch(ApiDetails + "pegasus/visionary/tenant/delete", {
            method: "POST", body: JSON.stringify({
                ...tenantInfo,
                property_name: property.property_name,
                landlord_email_address: JSON.parse(originalText)[5][1],
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    // getUserDetails2({
                    //   landlord_id: JSON.parse(originalText)[0][1],
                    // });
                    getPropertyDetails();
                    props.snackBar({
                        text: "Tenant Deleted Successful", type: "success",
                    });
                    handleClose();
                    // window.location.href = "/tenantList";
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
    };

    const getPropertyDetails = () => {
        fetch(ApiDetails + "pegasus/visionary/property/get/Specific/property", {
            method: "POST", body: JSON.stringify({property_id: property_id}),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    //Fetch Property Tenants
                    fetch(ApiDetails + "pegasus/visionary/tenant/get/property/tenants", {
                        method: "POST", body: JSON.stringify({property_id: property_id}),
                    })
                        .then(async (response) => {
                            let tenants = await response.json();
                            if (response.status === 200) {
                                setProperty(data);
                                setTenants(tenants);
                                tenantsLength = tenants.length;
                                setSearchResults(tenants);
                                setWaterBill(data.water_bill);
                                setMinimumWaterBIll(parseFloat(data.minimum_water_bill));
                                let tenantsWithNoMeterReadings = tenants.filter((tenant) => {
                                    if (tenant.meter_reading === undefined) {
                                        return tenant;
                                    }
                                });

                                if (tenantsWithNoMeterReadings.length > 0) {
                                    setWarning(true);
                                } else {
                                    setWarning(false);
                                }
                                setBackDrop(<></>);
                            } else {
                                setProperty(data);
                                setWaterBill(data.water_bill);
                                setMinimumWaterBIll(parseFloat(data.minimum_water_bill));
                                props.snackBar({
                                    text: "Failed fetching tenants.", type: "error",
                                });
                            }
                        })
                        .catch((err) => {
                            setProperty(data);
                            setWaterBill(data.water_bill);
                            setMinimumWaterBIll(parseFloat(data.minimum_water_bill));
                            console.log(err);
                            props.snackBar({
                                text: "Failed fetching tenants.", type: "error",
                            });
                        });
                } else {
                    props.snackBar({
                        text: "Failed fetching property details.", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Get property details error", // text: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error",
                });
            });
    };
    const getPropertyTenants = () => {
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/get/property/tenants", {
            method: "POST", body: JSON.stringify({property_id: property_id}),
        })
            .then(async (response) => {
                let tenants = await response.json();
                if (response.status === 200) {
                    setTenants(tenants);
                    tenantsLength = tenants.length;
                    setSearchResults(tenants);
                    setUnits(null);
                    setSelectedUnit(null);
                    setAddTenantPropertyName("Select Property");
                } else {
                    props.snackBar({
                        text: "Failed fetching tenant .", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.", type: "error",
                });
            });
    };
    const searchUser = (e) => {
        setSearchResults(Array.from(tenants).filter((item) => (item.first_name.toString().toLowerCase() + " " + item.last_name.toString().toLowerCase()).includes(e.target.value.toLowerCase())));
    };
    const addArrears = (values) => {
        //Add arrear to database
        let body = {
            tenant_id: tenantInfo.id, description: values.description, amount: values.amount,
        };

        fetch(ApiDetails + "pegasus/visionary/tenant/add/arrears", {
            method: "POST", body: JSON.stringify(body),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    getPropertyDetails();
                    setUpdateState(!updateState);
                    props.snackBar({
                        text: "Arrear Added", type: "success",
                    });
                    handleClose();
                } else {
                    props.snackBar({
                        text: "Something Went Wrong. Try Again Later", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.", type: "error",
                });
            });
    };
    const addTransaction = (values) => {
        setTransactionLoading(true);
        // return;
        //Add arrear to database
        /*
            "TransactionType": "Pay Bill",
                    "TransID": "QIA3PD6BCB",
                    "TransTime": "20220910102100",
                    "TransAmount": "50.00",
                    "BusinessShortCode": "4103275",
                    "BillRefNumber": "M1",
                    "InvoiceNumber": "",
                    "OrgAccountBalance": "170.00",
                    "ThirdPartyTransID": "",
                    "MSISDN": "2547 ***** 249",
                    "FirstName": "DEREK"
            */
        let body = {
            TransactionType: "Pay Bill",
            TransID: "**********", // TransTime: "20220910102100",
            TransTime: dateFrom,
            TransAmount: values.amount,
            BusinessShortCode: "4103275",
            BillRefNumber: tenantInfo.account_number,
            InvoiceNumber: "",
            OrgAccountBalance: "170.00",
            ThirdPartyTransID: "",
            MSISDN: "2547 ***** 249",
            FirstName: tenantInfo.name,
            tenant_id: tenantInfo.id,
            landlord_id: JSON.parse(originalText)[0][1],
        };
        if (values.amount === "") {
            props.snackBar({text: "Amount Can't Be Empty", type: "error"});
            return;
        }

        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/payments/", {
            method: "POST", body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    setUpdateState(!updateState);
                    getPropertyTenants();
                    props.snackBar({
                        text: "Transaction Added", type: "success",
                    });
                    handleClose();
                    // props.snackBar({ text: "RESPONSE 200", type: "success" });
                } else {
                    handleClose();
                    props.snackBar({
                        text: "Something Went Wrong, Please Try Again Later ", type: "error",
                    });
                }
                setTransactionLoading(false);
            })
            .catch((err) => {
                console.log(err);
                handleClose();
                props.snackBar({
                    text: "Something Went Wrong, Please Try Again Later ", type: "error",
                });
            });
    };
    const addTenant = (values) => {

        const replacer = (key, value) => {
            if (value instanceof Map) {
                return {
                    dataType: 'Map', value: Array.from(value.entries()), // or with spread: value: [...value]
                };
            } else {
                return value;
            }
        }

        setTenantLoading(true);
        if (selectedUnit === null) {
            setTenantLoading(false);
            props.snackBar({
                text: "Please Select Property Unit", type: "error",
            });
            return;
        }

        if (values.rent === "") {
            setTenantLoading(false);
            props.snackBar({
                text: "Please Input Rent", type: "error",
            });
            return;
        }
        if (values.phone_number === "" || values.phone_number.length < 10) {
            setTenantLoading(false);
            props.snackBar({
                text: "Enter valid phone number", type: "error",
            });
            return;
        }
        values.rent_as_arrear = rentAsArrears;
        values.deposit_as_arrears = depositAsArrears;
        values.is_student = isStudent;
        values.is_student = isStudent;
        values.landlord_email_address = JSON.parse(originalText)[5][1];
        values.landlord_id = JSON.parse(originalText)[0][1];
        values.property_name = property.property_name;
        values.property_id = property.property_id;
        values.house_number = selectedUnit;
        values.deposit = Array.from(depositList.entries());
        values.deposit_held = (depositList.get("Refundable deposit"));

        let formData = new FormData();
        //Add values to formData
        //formData.append("details", [detailsMap]);
        formData.append("body", JSON.stringify(values));
        formData.append("photo_of_national_id", photoOfNationalID);
        formData.append("copy_of_contract", copyOfContract);
        formData.append("inventory_checklist", inventoryChecklist);
        formData.append("tenant_photo", tenantPhoto);

        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/tenant/add/tenant", {
            method: "POST", body: formData,
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setRentAsArrears(true);
                    setDepositAsArrears(true);
                    getPropertyTenants();
                    getPropertyDetails();
                    handleClose();
                    props.snackBar({
                        text: "Tenant Added Successful", type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached", type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.", type: "error",
                    });
                } else if (response.status === 500) {
                    if (data["Message"].includes("duplicate key")) {
                        setTenantLoading(false);

                        props.snackBar({
                            text: "Phone Number Already Exists", type: "error",
                        });
                        return;
                    }
                    props.snackBar({
                        text: "Something Went Wrong Please Try Again Later", type: "error",
                    });
                }
                setTenantLoading(false);
            })
            .catch((err) => {
                setTenantLoading(false);

                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
    };
    const getUnits = () => {
        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/property/getUnits", {
            method: "POST", body: JSON.stringify({property_id: propertyID}),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setUnits(data);
                    props.snackBar({
                        text: "Property Units Retreived Successful", type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached", type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.", type: "error",
                    });
                } else if (response.status === 500) {
                    if (data["Message"].includes("duplicate key")) {
                        if (data["Message"].includes("owners_email_address_uindex")) {
                            props.snackBar({
                                text: "Email Address Already Exists", type: "error",
                            });
                            return;
                        }
                    }
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
    };

    const addUnit = () => {
        if (unitName.trim() === "" || unitMeterReading.trim() === "" || electricityUnitMeterReading.trim() === "") {
            props.snackBar({
                text: "Kindly Fill In All Details", type: "error",
            });
            return;
        }

        fetch(ApiDetails + "pegasus/visionary/property/add/unit", {
            method: "POST", body: JSON.stringify({
                property_id: property.property_id,
                unit: unitName,
                state: "VACANT",
                meter_reading: unitMeterReading,
                electricity_meter_reading: electricityUnitMeterReading,
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setAllUnits(data);
                    getPropertyDetails();
                    props.snackBar({
                        text: "Unit Added Successful", type: "success",
                    });
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
        handleClose();
    };

    const DeleteUnit = (unitId) => {
        fetch(ApiDetails + "pegasus/visionary/property/delete/unit", {
            method: "POST", body: JSON.stringify({
                property_id: property.property_id,
                id: unitId,
            }),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    setAllUnits(data);
                    getPropertyDetails();
                    props.snackBar({
                        text: "Unit Deleted Successful", type: "success",
                    });
                } else if (response.status === 403) {
                    props.snackBar({
                        text: "Unit is currently occupied.", type: "error",
                    });
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
        handleClose();
    };


    const getAllUnits = () => {
        //Make API call
        //"proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/property/getAllUnits", {
            method: "POST", body: JSON.stringify({property_id: property.property_id}),
        })
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    // console.log(data);
                    setAllUnits(data);
                    props.snackBar({
                        text: "Property Units Retreived Successful", type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached", type: "warning",
                    });
                } else if (response.status === 400) {
                    props.snackBar({
                        text: "Invalid Credentials Provided.", type: "error",
                    });
                } else if (response.status === 500) {
                    if (data["Message"].includes("duplicate key")) {
                        if (data["Message"].includes("owners_email_address_uindex")) {
                            props.snackBar({
                                text: "Email Address Already Exists", type: "error",
                            });
                            return;
                        }
                    }
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
    };
    const updateUnits = () => {
        let finalChanges = [];
        // console.log(meterReadings);
        // console.log(electricityMeterReadings);

        if (meterReadings > electricityMeterReadings) {
            meterReadings.forEach((reading) => {
                electricityMeterReadings.forEach((readings2) => {
                    if (reading.unit === readings2.unit) {
                        finalChanges = [...finalChanges, {
                            unit: reading.unit,
                            meter_reading: reading.meter_reading,
                            electricity_meter_reading: readings2.electricity_meter_reading,
                        },];
                        meterReadings = meterReadings.filter((reading) => reading.unit !== readings2.unit);
                        electricityMeterReadings = electricityMeterReadings.filter((reading) => reading.unit !== readings2.unit);
                    }
                });
            });
        } else {
            electricityMeterReadings.forEach((reading) => {
                meterReadings.forEach((readings2) => {
                    if (reading.unit === readings2.unit) {
                        finalChanges = [...finalChanges, {
                            unit: reading.unit,
                            meter_reading: readings2.meter_reading,
                            electricity_meter_reading: reading.electricity_meter_reading,
                        },];
                        meterReadings = meterReadings.filter((reading) => reading.unit !== readings2.unit);
                        electricityMeterReadings = electricityMeterReadings.filter((reading) => reading.unit !== readings2.unit);
                    }
                });
            });
        }

        let finalMeterReadings = [];
        meterReadings.forEach((reading) => {
            reading = {...reading, electricity_meter_reading: 0};
            finalMeterReadings = [...finalMeterReadings, reading];
        });

        let finalElectricityMeterReadings = [];
        electricityMeterReadings.forEach((reading) => {
            reading = {...reading, meter_reading: 0};
            finalElectricityMeterReadings = [...finalElectricityMeterReadings, reading,];
        });

        finalChanges = [...finalChanges, ...finalMeterReadings, ...finalElectricityMeterReadings,];
        // console.log(finalChanges);
        // return;
        // Make API call
        // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
        fetch(ApiDetails + "pegasus/visionary/property/update/Units", {
            method: "POST", body: JSON.stringify({
                property_id: property.property_id, units: finalChanges,
            }),
        })
            .then(async (response) => {
                // let data = await response.json();
                if (response.status === 200) {
                    // console.log(data);
                    // setAllUnits(data);
                    getPropertyDetails();
                    props.snackBar({
                        text: "Units Updated Successful", type: "success",
                    });
                    handleClose();
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
    };

    const downloadUnitReport = () => {
        setBillLoading(true)
        let fileName;

        fetch(ApiDetails + "pegasus/visionary/property/unit/report", {
            method: "POST", body: JSON.stringify({
                property_id: property.property_id, id: selectedUnit,
            }),
        })
            .then(async (response) => {
                // let data = await response.json();
                if (response.status === 200) {
                    // console.log(data);
                    // setAllUnits(data);
                    setBillLoading(false)

                    // getPropertyDetails();
                    props.snackBar({
                        text: "Download will start shortly", type: "success",
                    });


                    for (let pair of response.headers.entries()) {
                        // accessing the entries
                        if (pair[0] === "content-disposition") {
                            // key I'm looking for in this instance
                            fileName = pair[1]; // saving that value where I can use it
                        }
                    }

                    props.snackBar({
                        text: "File Download Will Start In A Few ",
                        type: "success",
                    });

                    let blob = await response.blob();

                    let href = window.URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    handleClose();
                } else if (response.status === 404) {
                    setBillLoading(false)
                    props.snackBar({
                        text: "Unit records not found", type: "error",
                    });
                } else if (response.status === 500) {
                    setBillLoading(false)
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
            })
            .catch((err) => {
                setBillLoading(false)
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
    };

    const billTenants = async () => {
        // console.log(billedTenants);
        // console.log(elecBilledTenants);

        setBillLoading(true);

        if (currentTenants !== null) {
            if (// billedTenants.length < currentTenants.length ||
                // elecBilledTenants.length < currentTenants.length
                billedTenants.length < tenantsLength || elecBilledTenants.length < tenantsLength) {
                props.snackBar({
                    text: "Please Make sure to bill all tenants both water and electricity", type: "error",
                });
                setBillLoading(false);

                return;
            }
        } else {
            // console.log("Billed tenants :" + billedTenants.length);
            // console.log("All tenants :" + tenantsLength);
            if (// billedTenants.length < tenants.length ||
                // elecBilledTenants.length < tenants.length
                billedTenants.length < tenantsLength || elecBilledTenants.length < tenantsLength) {
                console.log("here");
                props.snackBar({
                    text: "Please Make sure to bill all tenants both water and electricity", type: "error",
                });
                setBillLoading(false);

                return;
            }
        }

        let finalBill = [];

        if (billedTenants > elecBilledTenants) {
            billedTenants.forEach((tenant) => {
                elecBilledTenants.forEach((tenant2) => {
                    if (tenant.tenant_id === tenant2.tenant_id) {
                        finalBill = [...finalBill, {
                            tenant_id: tenant.tenant_id,
                            water_bill: tenant.finalWaterBill,
                            electricity_bill: tenant2.electricity_bill,
                            total_bill: parseInt(tenant.rent.replace(",", "")) + parseInt(tenant.arrears) + parseFloat(tenant2.electricity_bill) + parseFloat(tenant.finalWaterBill),
                            meter_reading: tenant.meter_reading,
                            electricity_meter_reading: tenant2.electricity_meter_reading,
                            landlord_id: JSON.parse(originalText)[0][1],
                        },];
                        billedTenants = billedTenants.filter((tenant) => tenant.tenant_id !== tenant2.tenant_id);
                        elecBilledTenants = elecBilledTenants.filter((tenant) => tenant.tenant_id !== tenant2.tenant_id);
                    }
                });
            });
        } else {
            elecBilledTenants.forEach((tenant) => {
                billedTenants.forEach((tenant2) => {
                    if (tenant.tenant_id === tenant2.tenant_id) {
                        finalBill = [...finalBill, {
                            tenant_id: tenant.tenant_id,
                            water_bill: tenant2.finalWaterBill,
                            electricity_bill: tenant.electricity_bill,
                            total_bill: parseInt(tenant.rent) + parseInt(tenant.arrears) + parseFloat(tenant.electricity_bill) + parseFloat(tenant2.finalWaterBill),
                            meter_reading: tenant2.meter_reading,
                            electricity_meter_reading: tenant.electricity_meter_reading,
                            landlord_id: JSON.parse(originalText)[0][1],
                        },];
                        billedTenants = billedTenants.filter((tenant) => tenant.tenant_id !== tenant2.tenant_id);
                        elecBilledTenants = elecBilledTenants.filter((tenant) => tenant.tenant_id !== tenant2.tenant_id);
                    }
                });
            });
        }

        let finalWaterBills = [];
        billedTenants.forEach((reading) => {
            reading = {
                tenant_id: reading.tenant_id,
                water_bill: reading.finalWaterBill,
                electricity_bill: 0,
                total_bill: parseInt(reading.rent) + parseInt(reading.arrears) + parseFloat(reading.finalWaterBill),
                meter_reading: reading.meter_reading,
                electricity_meter_reading: 0,
                landlord_id: JSON.parse(originalText)[0][1],
            };
            finalWaterBills = [...finalWaterBills, reading];
        });

        let finalElectricityBills = [];
        elecBilledTenants.forEach((reading) => {
            reading = {
                tenant_id: reading.tenant_id,
                water_bill: 0,
                electricity_bill: reading.electricity_bill,
                total_bill: parseInt(reading.rent) + parseInt(reading.arrears) + parseFloat(reading.electricity_bill),
                meter_reading: 0,
                electricity_meter_reading: reading.electricity_meter_reading,
                landlord_id: JSON.parse(originalText)[0][1],
            };
            finalElectricityBills = [...finalElectricityBills, reading];
        });

        finalBill = [...finalBill, ...finalWaterBills, ...finalElectricityBills];

        if (currentTenants !== null) {
            if (finalBill.length < currentTenants.length) {
                props.snackBar({
                    text: "Please Make Sure To Bill AllTenants", type: "error",
                });
                setBillLoading(false);

                return;
            }
        } else {
            // console.log("Billed tenants :" + billedTenants.length);
            // console.log("All tenants :" + tenantsLength);
            if (finalBill.length < tenantsLength) {
                // console.log("here");
                props.snackBar({
                    text: "Please Make Sure To Bill AllTenants", type: "error",
                });
                setBillLoading(false);

                return;
            }
        }

        if (selectedMonth === null) {
            props.snackBar({
                text: "Kindly select billing for month", type: "error",
            });
            setBillLoading(false);
            return;
        }

        let continueBilling = false;

        await confirm({
            description: `You are about to bill for the month of 
         ${getMonthName(parseInt(month.getMonth())).toUpperCase()} , ${parseInt(month.getFullYear())}`
        })
            .then(() => {
                continueBilling = true
            })
            .catch(() => continueBilling = false);

        if (!continueBilling) {
            fetch(ApiDetails + "pegasus/visionary/property/enableBilling", {
                method: "POST", body: JSON.stringify({
                    property_id: property.property_id,
                }),
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        window.location.reload(false);
                    } else {
                        props.snackBar({
                            text: "Failed updating tenant details.", type: "error",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    props.snackBar({
                        text: "Something Went Wrong. Call Customer Care For Assistance.", type: "error",
                    });
                });
            return;
        }


        //Make Billing API Call
        fetch(ApiDetails + "pegasus/visionary/property/bill/tenants", {
            method: "POST", body: JSON.stringify([...finalBill, {
                month: parseInt(month.getMonth()),
                year: parseInt(month.getFullYear()),
                property_id: property.property_id,
                property_name: property.property_name,
                email_address: JSON.parse(originalText)[5][1],
            },]),
        })
            .then(async (response) => {
                // let data = await response.json();
                if (response.status === 200) {
                    // await getPropertyDetails();
                    // setUpdateState(!updateState);

                    props.snackBar({
                        text: "Property Tenants Billed Successfully.", type: "success",
                    });
                    setBillLoading(false);
                    window.location.reload(false);

                    // handleClose();
                    // window.location.reload(false);
                } else {
                    props.snackBar({
                        text: "Failed Billing tenants.", type: "error",
                    });
                    setBillLoading(false);

                    handleClose();
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Call Customer Care For Assistance.", type: "error",
                });
                setBillLoading(false);

                handleClose();
            });
    };
    const updateProperty = (values) => {
        fetch(ApiDetails + "pegasus/visionary/property/update", {
            method: "POST", body: JSON.stringify({
                ...values,
                old_name: property.property_name,
                property_image: property.property_image,
                email_address: JSON.parse(originalText)[5][1],
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    window.location.reload(false);
                    props.snackBar({
                        text: "Property Details Updated Successfully", type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached", type: "warning",
                    });
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
    };

    const updatePropertyPaymentDetails = (values) => {
        if (accountType === null) {
            props.snackBar({
                text: "Kindly select account type", type: "error",
            });
            return

        }

        let body = {
            ...values, transaction_type: accountType
        }
        fetch(ApiDetails + "pegasus/visionary/property/update/payment/details", {
            method: "POST", body: JSON.stringify(body),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    window.location.reload(false);
                    props.snackBar({
                        text: "Payment Details Updated", type: "success",
                    });
                } else {
                    props.snackBar({
                        text: "An error occurred", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
    };
    const updatePenaltyDetails = (values) => {
        console.log(values);
        // return;

        fetch(ApiDetails + "pegasus/visionary/property/penalty/details", {
            method: "POST", body: JSON.stringify({...values, property_id: property.property_id}),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    getPropertyDetails();
                    setUpdateState(!updateState);
                    props.snackBar({
                        text: "Property Details Updated Successfully", type: "success",
                    });
                } else if (response.status === 401) {
                    props.snackBar({
                        text: "Maximum concurrent logins reached", type: "warning",
                    });
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
            });
    };

    const sendBroadcastMessage = () => {
        setMessageLoading(true);
        if (broadcastMessage === "") {
            props.snackBar({
                text: "Can not send an empty message", type: "error",
            });
            setMessageLoading(false);
            return;
        }
        let values = {
            message: broadcastMessage, property_id: property.property_id, landlord_id: JSON.parse(originalText)[0][1],
        };

        fetch(ApiDetails + "pegasus/visionary/messages/broadcast", {
            method: "POST", body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    setUpdateState(!updateState);
                    props.snackBar({
                        text: "Message Sent Successfully", type: "success",
                    });
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
                setMessageLoading(false);
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
                setMessageLoading(false);
            });
    };

    const broadCastCredentials = () => {

        let values = {
            property_id: property.property_id, landlord_id: JSON.parse(originalText)[0][1],
        };

        fetch(ApiDetails + "pegasus/visionary/messages/broadcast/credentials", {
            method: "POST", body: JSON.stringify(values),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    handleClose();
                    setUpdateState(!updateState);
                    props.snackBar({
                        text: "Messages Sent Successfully", type: "success",
                    });
                } else if (response.status === 500) {
                    props.snackBar({
                        text: "Something went wrong. Please try again later.", type: "error",
                    });
                }
                setMessageLoading(false);
            })
            .catch((err) => {
                console.log(err);
                props.snackBar({
                    text: "Something Went Wrong. Try Again Later", type: "error",
                });
                setMessageLoading(false);
            });
    };

    //Loading button states
    const [billLoading, setBillLoading] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [messageLoading, setMessageLoading] = React.useState(false);
    const [transactionLoading, setTransactionLoading] = React.useState(false);

    const [removedWaterBilling, setRemovedWaterBilling] = useState([])
    const [removedElectricityBilling, setRemovedElectricityBilling] = useState([])

    const classes = useStyles();
    const columns =
        [
            {
                field: "user", headerAlign: "center", headerName: "Name", minWidth: 200, flex: 1, // width: 200,
                renderCell: (params) => {
                    return (<div
                        style={{
                            display: "flex",
                        }}
                        className="userListUser"
                    >
                        <div
                            style={{
                                margin: "0 5px",
                            }}
                        >
                            {params.row.state === "NOTICE" ? (<WarningAmberOutlined
                                style={{
                                    color: "#F0972A",
                                }}
                            />) : (<CheckCircleOutlineOutlined
                                style={{
                                    color: "#57AF51",
                                }}
                            />)}
                        </div>
                        <div>
                            {params.row.first_name} {params.row.last_name}
                        </div>
                    </div>);
                },
            },
            {
                field: "house_number",
                headerAlign: "center",
                align: "center",
                headerName: "HSE NO",
                minWidth: 100,
                flex: 1, // width: 100,
                sortOrder: "ASC",
            },
            {
                field: "phone_number", align: "center", headerName: "Phone Number", minWidth: 150, flex: 1, // width: 150,
            },
            {
                field: "rent", headerName: "Rent", minWidth: 120, flex: 1, // width: 120,
                align: "center", headerAlign: "center", renderCell: (params) => {
                    return (<div className="userListAction">
                        {parseInt(params.row.rent.toString().replace(",", "")).toLocaleString("en-US", {
                            style: "currency", currency: "KSH",
                        })}
                    </div>);
                },
            },
            {
                field: "bill", headerName: "Wallet", minWidth: 120, flex: 1, // width: 180,
                headerAlign: "center", align: "center", renderCell: (params) => {
                    return (<div>
                        <div style={{color: "red"}}>
                            {params.row.status === "PENDING" ? (<>
                                    <div style={{color: "red"}}>
                                        {params.row.Wallet.toLocaleString("en-US", {
                                            style: "currency", currency: "KSH",
                                        })}
                                    </div>
                                </>)

                                : (<>
                                    <div style={{color: "green"}}>
                                        {params.row.Wallet.toLocaleString("en-US", {
                                            style: "currency", currency: "KSH",
                                        })}
                                    </div>
                                </>)}
                        </div>
                    </div>);
                },
            },
            {
                field: "", headerName: "Pay", minWidth: 80, flex: 1, // width: 80,
                headerAlign: "center", align: "center", renderCell: (params) => {
                    return (<>
                        {params.row.agency_id !== 0 && params.row.agency_id !== JSON.parse(originalText)[0][1] ? (<></>) : (
                            <div
                                style={{
                                    width: "100px", display: "flex", justifyContent: "center", cursor: "pointer",
                                }}
                            >
                                <PaymentsOutlined
                                    style={{
                                        scale: "1.5", color: "green",
                                    }}
                                    onClick={() => {
                                        setTenantInfo({
                                            id: params.row.tenant_id,
                                            name: params.row.first_name,
                                            last_name: params.row.last_name,
                                            account_number: params.row.account_number,
                                        });
                                        setTransactionModal(true);
                                    }}
                                />
                            </div>)}
                    </>);
                },
            },
            {
                field: "status",
                headerName: "Status",
                minWidth: 120,
                flex: 1,
                // width: 150,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => {
                    let state = params.row.status;
                    if (state === "PENDING") {
                        return (
                            <div className="userListAction">

                                <Chip label="PENDING" color="error" variant="outlined"/>
                            </div>
                        );
                    } else if (state === "COMPLETED") {
                        return (
                            <div className="userListAction">

                                <Chip label="PAID" color="success" variant="outlined"/>
                            </div>
                        );
                    } else {
                        return (
                            <div className="userListAction">

                                <Chip label="partial" color="warning" variant="outlined"/>


                                {/*<Button*/}
                                {/*  variant="outlined"*/}
                                {/*  style={{*/}
                                {/*    color: "black",*/}
                                {/*    width: "100px",*/}
                                {/*    border: "none",*/}
                                {/*    padding: "5px 7px",*/}
                                {/*    borderRadius: "5px",*/}
                                {/*    backgroundColor: "#ffd87c",*/}
                                {/*  }}*/}
                                {/*>*/}
                                {/*  {params.row.status}*/}
                                {/*</Button>*/}
                            </div>
                        );
                    }
                },
            },
            {
                field: "login_successful",
                headerName: "Logged in",
                flex: 1,
                minWidth: 80,
                headerAlign: "center",
                renderCell: (params) => {
                    return (
                        <div className="userListAction">
                            {params.row.login_successful ? (
                                <Chip
                                    label="Yes"
                                    color="success"
                                    variant="outlined"
                                    icon={<DoneOutlined/>}
                                />
                            ) : (
                                <Chip
                                    label="No"
                                    color="error"
                                    variant="outlined"
                                    icon={<ErrorIcon/>}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                field: "action",
                headerName: "Actions",
                minWidth: 230,
                flex: 1,
                // width: 180,
                headerAlign: "center",
                renderCell: (params) => {
                    return (
                        <>
                            {params.row.agency_id !== 0 &&
                            params.row.agency_id !== JSON.parse(originalText)[0][1] ? (
                                <></>
                            ) : (
                                <div className="userListAction">

                                    <Chip
                                        label="View Details"
                                        color={"info"}
                                        onClick={() => {
                                            // navigate("/tenant/" + params.row.tenant_id, {
                                            //   state: {
                                            //     tenant: data,
                                            //     arrears: arrears,
                                            //     transactions: transactions,
                                            //     propertyUnits: units,
                                            //   },
                                            // });

                                            navigate("/tenant/" + params.row.tenant_id, {
                                                state: {
                                                    tenant_id: params.row.tenant_id,
                                                },
                                            });
                                        }}
                                        onDelete={() => {
                                            // navigate("/tenant/" + params.row.tenant_id, {
                                            //   state: {
                                            //     tenant: data,
                                            //     arrears: arrears,
                                            //     transactions: transactions,
                                            //     propertyUnits: units,
                                            //   },
                                            // });

                                            navigate("/tenant/" + params.row.tenant_id, {
                                                state: {
                                                    tenant_id: params.row.tenant_id,
                                                },
                                            });
                                        }}
                                        deleteIcon={<MoreVert/>}
                                        variant="outlined"
                                    />

                                    {/* <Chip
                                        label="Delete"
                                        color={"error"}
                                        onClick={() => {
                                            setTenantInfo({
                                                tenant_id: params.row.tenant_id,
                                                name: params.row.first_name,
                                                last_name: params.row.last_name,
                                                unit: params.row.house_number,
                                                property_id: params.row.property_id,
                                            });
                                            setOpen(true);
                                        }}
                                        onDelete={() => {
                                            setTenantInfo({
                                                tenant_id: params.row.tenant_id,
                                                name: params.row.first_name,
                                                last_name: params.row.last_name,
                                                unit: params.row.house_number,
                                                property_id: params.row.property_id,
                                            });
                                            setOpen(true);
                                        }}
                                        deleteIcon={<DeleteIcon/>}
                                        variant="outlined"
                                    />*/}
                                </div>
                            )}
                        </>
                    );
                },
            },
        ];
    const billingColumns =
        [
            {
                field: "user", headerName: "Name", flex: 1, minWidth: 120, sortOrder: "asc", renderCell: (params) => {
                    return (<div className="userListUser">
                        {params.row.first_name} {params.row.last_name}
                    </div>);
                },
            },
            {
                field: "house_number", // align: "center",
                headerName: "HSE NO", flex: 1, minWidth: 80,
            },
            {
                field: "rent", headerName: "Rent", flex: 1, minWidth: 50, // align: "center",
                renderCell: (params) => {
                    return (<div style={{color: "green"}}>
                        {parseInt(params.row.rent.toString().replace(",", "")).toLocaleString("en-US", {
                            style: "currency", currency: "KSH",
                        })}
                    </div>);
                },
            },
            {
                field: "arrears", headerName: "Wallet", flex: 1, minWidth: 50, // align: "center",
                renderCell: (params) => {
                    return (<div>
                        <div style={{color: "red"}}>
                            {params.row.status === "PENDING" ? (<>
                                    <div style={{color: "red"}}>
                                        {params.row.Wallet.toLocaleString("en-US", {
                                            style: "currency", currency: "KSH",
                                        })}
                                    </div>
                                </>)

                                : (<>
                                    <div style={{color: "green"}}>
                                        {params.row.Wallet.toLocaleString("en-US", {
                                            style: "currency", currency: "KSH",
                                        })}
                                    </div>
                                </>)}
                        </div>
                    </div>);
                },
            },
            {
                field: "meter_reading", headerName: "Prior Water Reading", flex: 1, minWidth: 120, // align: "center",
                renderCell: (params) => {
                    return <div>{params.row.meter_reading}</div>;
                },
            },
            {
                field: "current_meter_reading", headerName: "Water Reading", flex: 1, minWidth: 100, // align: "center",
                renderCell: (params) => {
                    return (<div className="userListAction">
                        <TextField
                            type={"number"}
                            placeholder="Enter Reading ..."
                            onChange={(e) => {
                                let filteredTenantArray = billedTenants.filter((tenant) => {
                                    return tenant.tenant_id !== params.row.tenant_id;
                                });

                                if (e.target.value === undefined || e.target.value === null || e.target.value === "") {
                                    return;
                                }

                                let consumedUnits = e.target.value - params.row.meter_reading;
                                consumedUnits = consumedUnits.toFixed(1);

                                let finalWaterBill = parseFloat(consumedUnits) * parseInt(waterBill);

                                if (finalWaterBill < minimumWaterBill) {
                                    finalWaterBill = minimumWaterBill;
                                }

                                setBilledTenants([...filteredTenantArray, {
                                    tenant_id: params.row.tenant_id,
                                    rent: parseInt(params.row.rent.replace(",", "")),
                                    arrears: parseInt(params.row.arrears),
                                    finalWaterBill: finalWaterBill,
                                    total_bill: parseInt(params.row.rent.replace(",", "")) + parseInt(params.row.arrears) + parseFloat(finalWaterBill),
                                    meter_reading: e.target.value,
                                    landlord_id: JSON.parse(originalText)[0][1],
                                },]);
                            }}
                            disabled={!params.row.billing_enabled}
                        />
                    </div>);
                },
            },
            {
                field: "electricity_meter_reading", headerName: "Prior Elec Reading", flex: 1, minWidth: 80,
            },
            {
                field: "current_electricity_meter_reading",
                headerName: "Electricity Reading",
                flex: 1,
                minWidth: 120,
                renderCell: (params) => {
                    return (<div className="userListAction">
                        <TextField
                            type={"number"}
                            placeholder="Enter Reading ..."
                            onChange={(e) => {
                                let filteredTenantArray = elecBilledTenants.filter((tenant) => {
                                    return tenant.tenant_id !== params.row.tenant_id;
                                });
                                if (e.target.value === undefined || e.target.value === null || e.target.value === "") {
                                    return;
                                }
                                let consumedUnits = e.target.value - params.row.electricity_meter_reading;
                                consumedUnits = consumedUnits.toFixed(1);

                                // console.log(
                                //   "Prior elec reading: " + params.row.electricity_meter_reading
                                // );
                                // console.log("Current elec reading: " + e.target.value);
                                // console.log("Consumed units: " + consumedUnits);
                                let finalElecBill = parseFloat(consumedUnits) * parseInt(electricityBill);

                                // console.log("Final elec bill: " + finalElecBill);

                                if (finalElecBill < minimumElecBill) {
                                    finalElecBill = minimumElecBill;
                                }

                                setElecBilledTenants([...filteredTenantArray, {
                                    tenant_id: params.row.tenant_id,
                                    rent: parseInt(params.row.rent.replace(",", "")),
                                    arrears: parseInt(params.row.arrears),
                                    electricity_bill: finalElecBill,
                                    total_bill: parseInt(params.row.rent.replace(",", "")) + parseInt(params.row.arrears) + parseFloat(finalElecBill),
                                    electricity_meter_reading: e.target.value,
                                    landlord_id: JSON.parse(originalText)[0][1],
                                },]);
                            }}
                            disabled={!params.row.billing_enabled}
                        />
                    </div>);
                },
            },
            {
                field: "billing_enabled", headerName: "Bill tenant?", flex: 1, minWidth: 100, // align: "center",
                renderCell: (params) => {
                    return (<div className="userListAction">
                        {params.row.billing_enabled ? (<Switch
                            onChange={() => {
                                // console.log("Before changing : " + tenantsLength);
                                //API call to disable tenant billing

                                fetch(ApiDetails + "pegasus/visionary/tenant/billing", {
                                    method: "POST", body: JSON.stringify({
                                        id: params.row.tenant_id, value: false,
                                    }),
                                })
                                    .then(async (response) => {
                                        if (response.status === 200) {
                                            getPropertyDetails();

                                            //Remove electricity billing if any
                                            let removedElecBill;
                                            let filteredTenantArray = elecBilledTenants.filter((tenant) => {
                                                if (tenant.tenant_id !== params.row.tenant_id) {
                                                    return tenant
                                                } else {
                                                    removedElecBill = tenant
                                                }
                                                // return tenant.tenant_id !== params.row.tenant_id;
                                            });
                                            setElecBilledTenants([...filteredTenantArray]);

                                            // console.log("Stima billed number : "+filteredTenantArray.length)

                                            //Add to removed electricity billings
                                            setRemovedElectricityBilling([...removedElectricityBilling, removedElecBill])

                                            //Remove water billing if any
                                            let removedWaterBill;
                                            let filteredTenantArray2 = billedTenants.filter((tenant) => {
                                                if (tenant.tenant_id !== params.row.tenant_id) {
                                                    return tenant
                                                } else {
                                                    removedWaterBill = tenant
                                                }
                                            });
                                            setBilledTenants([...filteredTenantArray2]);
                                            // console.log("Water billed number : "+filteredTenantArray2.length)


                                            //Add to removed water billings
                                            setRemovedWaterBilling([...removedWaterBilling, removedWaterBill])

                                            setTenantsLength(tenantsLength - 1);
                                            tenantsLength = tenantsLength - 1;
                                            // console.log("After changing : " + tenantsLength);

                                            setUpdateState(!updateState);
                                        } else {
                                            props.snackBar({
                                                text: "Failed updating tenant details.", type: "error",
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        props.snackBar({
                                            text: "Something Went Wrong. Call Customer Care For Assistance.",
                                            type: "error",
                                        });
                                    });
                            }}
                            {...label}
                            defaultChecked
                        />) : (<Switch
                            onChange={() => {
                                //API call to enable tenant billing
                                // console.log(tenantsLength);
                                // setTenantsLength = tenantsLength + 1;

                                fetch(ApiDetails + "pegasus/visionary/tenant/billing", {
                                    method: "POST", body: JSON.stringify({
                                        id: params.row.tenant_id, value: true,
                                    }),
                                })
                                    .then(async (response) => {
                                        if (response.status === 200) {
                                            getPropertyDetails();

                                            //Get removed electricity billing if any
                                            let removedElecBill;
                                            let filteredTenantArray = removedElectricityBilling.filter((tenant) => {
                                                if (tenant.tenant_id !== params.row.tenant_id) {
                                                    return tenant;
                                                } else {
                                                    removedElecBill = tenant
                                                }
                                                // return tenant.tenant_id !== params.row.tenant_id;
                                            });

                                            //Return electricity bill to map
                                            setElecBilledTenants([...elecBilledTenants, removedElecBill]);

                                            //Set removed electricity bill to new value
                                            setRemovedElectricityBilling([...filteredTenantArray])


                                            //Get removed water billing if any
                                            let removedWaterBill;
                                            let filteredTenantArray2 = removedWaterBilling.filter((tenant) => {
                                                if (tenant.tenant_id !== params.row.tenant_id) {
                                                    return tenant;
                                                } else {
                                                    removedWaterBill = tenant
                                                }
                                                // return tenant.tenant_id !== params.row.tenant_id;
                                            });

                                            //Return water bill to map
                                            setBilledTenants([...billedTenants, removedWaterBill]);

                                            //Set removed water bill to new value
                                            setRemovedWaterBilling([...filteredTenantArray2])


                                            setUpdateState(!updateState);
                                            tenantsLength = tenantsLength + 1;
                                        } else {
                                            props.snackBar({
                                                text: "Failed updating tenant details.", type: "error",
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        props.snackBar({
                                            text: "Something Went Wrong. Call Customer Care For Assistance.",
                                            type: "error",
                                        });
                                    });
                            }}
                            {...label}
                        />)}
                    </div>);
                },
            },
        ];

    const unitsColumns = [
        {
        field: "unit", headerName: "Unit",
        flex: 1,
        minWidth: 0, sortOrder: "asc",
    }, {
        field: "state", headerName: "State",
        flex: 1,
        minWidth: 100
    }, {
        field: "meter_reading", headerName: "Water", flex: 1,
        minWidth: 100,
    }, {
        field: "electricity_meter_reading", headerName: "Electricity ", flex: 1,
        minWidth: 100,
    }, {
        field: "water", headerName: "Update Water", flex: 1,
        minWidth: 150, renderCell: (params) => {
            return (<div className="userListAction">
                <TextField
                    type={"number"}
                    placeholder="Enter Reading ..."
                    onChange={(e) => {
                        let filteredTenantArray = meterReadings.filter((tenant) => {
                            return tenant.unit !== params.row.unit;
                        });

                        // console.log(filteredTenantArray);

                        // let newReading = e.target.value;
                        let newReading = parseFloat(e.target.value);

                        setMeterReadings([...filteredTenantArray, {
                            unit: params.row.unit, meter_reading: newReading,
                        },]);
                    }}
                />
            </div>);
        },
    }, {
        field: "electricity", headerName: "Update Electricity ", flex: 1,
        minWidth: 150, renderCell: (params) => {
            return (<div className="userListAction">
                <TextField
                    type={"number"}
                    placeholder="Enter Reading ..."
                    onChange={(e) => {
                        let filteredTenantArray = electricityMeterReadings.filter((tenant) => {
                            return tenant.unit !== params.row.unit;
                        });

                        // console.log(filteredTenantArray);

                        // let newReading = e.target.value;
                        let newReading = parseFloat(e.target.value);

                        setElectricityMeterReadings([...filteredTenantArray, {
                            unit: params.row.unit, electricity_meter_reading: newReading,
                        },]);
                    }}
                />
            </div>);
        },
    },
        {
            field: "", headerName: "", flex: 1,
            minWidth: 150, renderCell: (params) => {
                return (<div className="userListAction">
                    <Button variant="outlined"
                            onClick={async () => {

                                //Seek confirmation

                                let deleteUnit = false;
                                console.log(params)

                                await confirm({
                                    description: `You are about to delete unit ${params.row.unit}`
                                })
                                    .then(() => {
                                        deleteUnit = true
                                    })
                                    .catch(() => deleteUnit = false);


                                if (deleteUnit) {
                                    DeleteUnit(params.row.id)
                                }
                            }}
                            startIcon={<DeleteIcon/>}>
                        Delete
                    </Button>
                </div>);
            },
        }
    ];

    return (<>


        This is the one running
        <div
            id={"fullScreenBlurrBackground"}
            style={{
                display: displayImage ? "block" : "none",
                zIndex: "8999",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
            }}
            onClick={() => {
                setDisplayImage(!displayImage);
            }}
        ></div>
        <div
            style={{
                display: displayImage ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
            }}
        >
            <div
                style={{
                    display: "block",
                    zIndex: "9999",
                    width: "90vw",
                    height: "90vh",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat no-repeat",
                    backgroundPosition: "center center", // backgroundColor: "black",
                    backgroundImage: `url(${propertyImage})`,
                }}
            >
                <div
                    style={{
                        top: "10px", left: "10px",
                    }}
                >
                    <Close
                        style={{
                            scale: "2", color: "blue",
                        }}
                        onClick={() => {
                            setDisplayImage(!displayImage);
                        }}
                    />
                </div>
            </div>
        </div>

        <div className="property">
            {/*Deposit modal*/}
            <Dialog
                open={depositModal}
                fullWidth
                maxWidth="sm"
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    <div
                        style={{
                            display: "flex", justifyContent: "center", alignItems: "center",
                        }}
                    >
                        Add deposit
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: "flex", justifyContent: "space-between",
                            }}
                        >
                            <Typography>Deposit details</Typography>
                            <Button
                                onClick={() => {
                                    setExpensesIncurred(expensesIncurred + 1);
                                    setInputs([...Array(expensesIncurred)].map((elementInArray, index) => (
                                        <div key={index}>
                                            <br/>
                                            <div
                                                style={{
                                                    display: "flex", justifyContent: "space-between",
                                                }}
                                            >
                                                <TextField
                                                    label="Description"
                                                    type={"text"}
                                                    variant="outlined"
                                                    name={"description " + (index + 1)}
                                                    required
                                                    inputRef={textRef}
                                                    style={{marginTop: "10px", width: "35%"}}
                                                    onChange={handleChange()}
                                                />
                                                <TextField
                                                    label="Amount"
                                                    type={"number"}
                                                    name={"value for description " + (index + 1)}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "35%"}}
                                                    onChange={handleChange()}
                                                />

                                                <TextField
                                                    label="refundable"
                                                    type={"text"}
                                                    name={"refundable " + (index + 1)}
                                                    variant="outlined"
                                                    style={{marginTop: "10px", width: "20%"}}
                                                    onChange={handleChange()}
                                                />

                                            </div>
                                        </div>)));
                                }}
                            >
                                <span style={{color: "green"}}>Add Deposit</span>
                            </Button>
                        </div>
                        <div>{inputs}</div>
                        <br/>
                        <div
                            style={{
                                width: "100%", display: "flex", justifyContent: "right", alignItems: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    calculateRefundableDeposit();
                                    // console.log("Adding deposit")
                                }}
                                variant="outlined"
                            >
                                Calculate
                            </Button>
                        </div>

                        <br/>
                        <div
                            style={{
                                width: "100%", display: "flex", justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green", flex: "2",
                                }}
                            >
                                Total deposit :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                    display: "flex",
                                    justifyContent: "right",
                                    fontSize: "30sp",
                                    fontWeight: "bold"
                                }}
                            >
                                {totalDeposit.toLocaleString("en-US", {
                                    style: "currency", currency: "KSH",
                                })}
                            </div>
                        </div>

                        <div
                            style={{
                                marginTop: "10px", width: "100%", display: "flex", justifyContent: "left",
                            }}
                        >
                            <div
                                style={{
                                    color: "green", flex: "2",
                                }}
                            >
                                Refundable deposit :
                            </div>
                            <div
                                style={{
                                    flex: "1",
                                    display: "flex",
                                    justifyContent: "right",
                                    fontSize: "30sp",
                                    fontWeight: "bold"
                                }}
                            >
                                {refundableDeposit.toLocaleString("en-US", {
                                    style: "currency", currency: "KSH",
                                })}
                            </div>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Button
                        onClick={() => {
                            // window.location.reload(false);

                            handleClose();
                            setAddTenantModal(true)
                        }}
                    >
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>

                    <div>
                        {/*<Button*/}
                        {/*    style={{*/}
                        {/*        marginRight: "10px"*/}
                        {/*    }}*/}
                        {/*    onClick={() => {*/}
                        {/*        // Handle form reset;*/}
                        {/*        setExpensesIncurred(1);*/}
                        {/*        setInputs();*/}
                        {/*        setExpenseItem(new Map())*/}
                        {/*        setDepositList(new Map())*/}
                        {/*        setTotalDeposit(0)*/}
                        {/*        setRefundableDeposit(0)*/}
                        {/*        setDepositSet(false);*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <RestartAlt/>*/}

                        {/*    <span style={{color: "blue", marginLeft: "5px"}}>Reset</span>*/}
                        {/*</Button>*/}
                        <LoadingButton
                            size="small"
                            onClick={() => {
                                // console.log("Adding expenses")
                                handleClose();
                                setAddTenantModal(true)
                            }}
                            disabled={!depositSet}
                            loading={loading1}
                            startIcon={<SendIcon/>}
                            style={{color: "green"}}
                            loadingPosition="start"
                        >
                            Agree
                        </LoadingButton>
                    </div>
                </DialogActions>
            </Dialog>


            {backDrop}
            {/*LOCATION MODAL*/}
            <Dialog
                classes={{paper: classes.dialogPaper}}
                open={agencyTokenModal}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Enter provided agency token</DialogTitle>
                <DialogContent>
                    <TextField
                        style={{
                            width: "100%", marginTop: "20px",
                        }}
                        id="outlined-basic"
                        label="Enter token"
                        value={agency_id}
                        variant="outlined"
                        onChange={(e) => {
                            setAgency_id(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Close</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={() => {
                            console.log(agency_id);
                            console.log(property_id);
                            setLoading(true);
                            // return;
                            fetch(ApiDetails + "pegasus/visionary/authorization/change_agency", {
                                method: "POST", body: JSON.stringify({
                                    property_id: property_id, agency_id: agency_id,
                                }),
                            })
                                .then(async (response) => {
                                    if (response.status === 200) {
                                        setLoading(false);
                                        props.snackBar({
                                            text: "Property management transferred to agent.", type: "success",
                                        });
                                        setTimeout(() => {
                                            window.location.href = "/propertyList";
                                        }, 2500);
                                    } else {
                                        setLoading(false);

                                        props.snackBar({
                                            text: "Failed transferring property to agent.", type: "error",
                                        });
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                    props.snackBar({
                                        text: "Something Went Wrong. Call Customer Care For Assistance.", type: "error",
                                    });
                                });
                        }}
                        loading={loading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/*LOCATION MODAL*/}
            <Dialog
                classes={{paper: classes.dialogPaper}}
                open={locationModal}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    Set {property.property_name} precise location
                </DialogTitle>
                <DialogContent>
                    <PropertiesMap height="70vh" width={"100vw"}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={handleDelete}
                        loading={loading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Agree
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/*MESSAGE MODAL*/}
            <Dialog
                classes={{paper: classes.dialogPaper}}
                open={messageModal}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>{property.property_name} broadcast message</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            width: "calc(100% - 20px)", padding: "10px",
                        }}
                    ></div>
                    <Textarea
                        minRows={5}
                        placeholder="Type message .."
                        onChange={(event) => {
                            setBroadcastMessage(event.target.value);
                        }}
                        style={{width: "100%"}}
                    />
                    {/*<TextareaAutosize*/}
                    {/*  aria-label="minimum height"*/}
                    {/*  minRows={5}*/}
                    {/*  placeholder="Type message .."*/}
                    {/*  onChange={(event) => {*/}
                    {/*    setBroadcastMessage(event.target.value);*/}
                    {/*  }}*/}
                    {/*  style={{ width: "100%" }}*/}
                    {/*/>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={sendBroadcastMessage}
                        loading={messageLoading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Send
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/*DELETE MODAL*/}
            <Dialog
                open={deleteModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{color: "red"}}>
                    Delete {tenantInfo.name}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography>
                            Deleting a Tenant will completely remove them and their
                            respective documents from the system and render a house vacant.
                        </Typography>
                        <br/>
                        <Typography>
                            Are you sure you want to delete this tenant ?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Disagree</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={handleDelete}
                        loading={loading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                    >
                        Agree
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/*Arrears MODAL*/}
            <Dialog
                open={arrearsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center", display: "flex", justifyContent: "center",
                        }}
                    >
                        Add Arrear
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={initialValues} onSubmit={addArrears}>
                        {() => (<Form noValidate>
                            <Field
                                as={TextField}
                                name="first_name"
                                label="Tenant Name"
                                value={tenantInfo.name + " " + tenantInfo.last_name}
                                variant="outlined"
                                fullwidth
                                required
                                disabled={true}
                                style={{marginTop: "8px", width: "100%"}}
                            />
                            <Field
                                as={TextField}
                                name="amount"
                                label="Amount"
                                type="number"
                                fullwidth
                                required
                                variant="outlined"
                                style={{
                                    marginTop: "10px", marginBottom: "10px", width: "100%",
                                }}
                            />
                            <Field
                                as={TextareaAutosize}
                                name="description"
                                required
                                fullwidth
                                aria-label="minimum height"
                                minRows={3}
                                placeholder="Arrear description ... "
                                style={{width: "100%"}}
                            />
                            <div
                                style={{
                                    width: "100%", display: "flex", justifyContent: "end", marginTop: "20px",
                                }}
                            >
                                <Button onClick={handleClose}>
                                    <span style={{color: "red"}}>Cancel</span>
                                </Button>
                                <Button type="submit">
                                    <span style={{color: "green"}}>Submit</span>
                                </Button>
                            </div>
                        </Form>)}
                    </Formik>
                </DialogContent>
            </Dialog>
            {/*Transaction MODAL*/}
            <Dialog
                open={transactionModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center", display: "flex", justifyContent: "center",
                        }}
                    >
                        Payment Details
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px",
                    }}
                >
                    <Formik initialValues={initialValues4} onSubmit={addTransaction}>
                        {() => (<Form noValidate>
                            <Field
                                as={TextField}
                                name="first_name"
                                label="Tenant Name"
                                value={tenantInfo.name + " " + tenantInfo.last_name}
                                variant="outlined"
                                fullwidth
                                required
                                disabled={true}
                                style={{marginTop: "8px", width: "100%"}}
                            />
                            <Field
                                as={TextField}
                                name="amount"
                                label="Amount"
                                type="number"
                                fullwidth
                                required
                                variant="outlined"
                                style={{
                                    marginTop: "10px", marginBottom: "10px", width: "100%",
                                }}
                            />
                            <div
                                style={{
                                    marginRight: "10px",
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        disableFuture
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Date "
                                        openTo="year"
                                        views={["year", "month", "day"]}
                                        value={dateFrom}
                                        onChange={(newValue) => {
                                            setDateFrom(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div
                                style={{
                                    width: "100%", display: "flex", justifyContent: "end", marginTop: "20px",
                                }}
                            >
                                <Button onClick={handleClose}>
                                    <span style={{color: "red"}}>Cancel</span>
                                </Button>
                                <LoadingButton
                                    size="small"
                                    type={"submit"}
                                    // onClick={sendBroadcastMessage}
                                    loading={transactionLoading}
                                    startIcon={<SendIcon/>}
                                    style={{color: "green"}}
                                    loadingPosition="start"
                                >
                                    Agree
                                </LoadingButton>
                            </div>
                        </Form>)}
                    </Formik>
                </DialogContent>
            </Dialog>
            {/*Add Tenant MODAL*/}
            <Dialog
                maxWidth={"md"}
                open={addTenantModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center", display: "flex", justifyContent: "center",
                        }}
                    >
                        Add Tenant
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px", zoom: matches ? "65%" : "100%",
                    }}
                >
                    <Formik
                        initialValues={initialValues2}
                        // validationSchema={validationSchema}
                        onSubmit={addTenant}
                    >
                        {(props) => (<Form noValidate>
                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="first_name"
                                    label="First Name"
                                    variant="outlined"
                                    error={props.errors.first_name && props.touched.first_name}
                                    helperText={<ErrorMessage name="first_name"/>}
                                    required
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="last_name"
                                    label="Last Name"
                                    error={props.errors.last_name && props.touched.last_name}
                                    helperText={<ErrorMessage name="last_name"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="phone_number"
                                    label="Phone Number"
                                    error={props.errors.phoneNumber && props.touched.phoneNumber}
                                    helperText={<ErrorMessage name="phoneNumber"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "32%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="secondary_phone_number"
                                    label="Secondary Phone Number"
                                    error={props.errors.secondary_phone_number && props.touched.secondary_phone_number}
                                    helperText={<ErrorMessage name="secondary_phone_number"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "32%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="national_id"
                                    label="National ID"
                                    type="number"
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "32%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <FormControl
                                    required
                                    style={{
                                        marginTop: "10px", width: "49%",
                                    }}
                                    label="Property *"
                                    name="is_student"
                                >
                                    <InputLabel id="demo-simple-select-required-label">
                                        Student
                                    </InputLabel>
                                    <Select
                                        name="is_student"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={isStudent}
                                        label="Property *"
                                        onChange={(e) => {
                                            setIsStudent(e.target.value);
                                        }}
                                    >
                                        <MenuItem value="YES">
                                            <em>YES</em>
                                        </MenuItem>
                                        <MenuItem value="NO">
                                            <em>NO</em>
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>Required</FormHelperText>
                                </FormControl>
                                {isStudent === "YES" ? (<>
                                    <Field
                                        as={TextField}
                                        name="institution_name"
                                        label="Institution Name"
                                        variant="outlined"
                                        error={props.errors.first_name && props.touched.first_name}
                                        helperText={<ErrorMessage name="first_name"/>}
                                        required
                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                </>) : (<>
                                    <Field
                                        as={TextField}
                                        name="institution_name"
                                        label="Institution Name"
                                        variant="outlined"
                                        disabled={true}
                                        error={props.errors.first_name && props.touched.first_name}
                                        helperText={<ErrorMessage name="first_name"/>}
                                        required
                                        style={{marginTop: "10px", width: "49%"}}
                                    />
                                </>)}
                                <br/>
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="occupation_or_profession"
                                    label="Occupation"
                                    variant="outlined"
                                    error={props.errors.first_name && props.touched.first_name}
                                    helperText={<ErrorMessage name="first_name"/>}
                                    required
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="place_of_work"
                                    label="Place Of Work"
                                    error={props.errors.last_name && props.touched.last_name}
                                    helperText={<ErrorMessage name="last_name"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="reason_for_relocating"
                                    label="Reason For Relocation"
                                    variant="outlined"
                                    error={props.errors.first_name && props.touched.first_name}
                                    helperText={<ErrorMessage name="first_name"/>}
                                    required
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="previous_residence"
                                    label="Previous Residence"
                                    error={props.errors.last_name && props.touched.last_name}
                                    helperText={<ErrorMessage name="last_name"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between", alignItems: "center",
                                }}
                            >
                                <FormControl
                                    name="property_name"
                                    required
                                    style={{
                                        marginTop: "10px", width: "49%",
                                    }}
                                    label="Property *"
                                >
                                    <InputLabel id="demo-simple-select-required-label">
                                        Select Property
                                    </InputLabel>
                                    <Select
                                        name="property_name"
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        value={addTenantPropertyName}
                                        label="Property *"
                                        onChange={(e) => {
                                            // console.log(e.target.value);
                                            setAddTenantPropertyName(e.target.value);
                                            propertyID = e.target.value;
                                            setPropertyID(e.target.value);
                                            getUnits();
                                        }}
                                    >
                                        <MenuItem value={property.property_id}>
                                            {property.property_name}
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>Required</FormHelperText>
                                </FormControl>
                                <br/>

                                {units !== null ? (<>
                                    <FormControl
                                        name="house_number"
                                        required
                                        style={{
                                            marginTop: "10px", width: "49%",
                                        }}
                                        label="Property *"
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Select House NO
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            value={selectedUnit}
                                            label="Unit *"
                                            onChange={(e) => {
                                                setSelectedUnit(e.target.value);
                                                console.log(e.target.value)
                                            }}
                                        >

                                            {units.map((unit, index) => {
                                                let hse = unit.state;
                                                if (hse === "OCCUPIED") {
                                                    return (<MenuItem
                                                        key={index}
                                                        value={unit.unit}
                                                        style={{
                                                            display: "flex", justifyContent: "space-between",
                                                        }}
                                                        disabled={true}
                                                    >
                                                        <span>{unit.unit}</span>
                                                        <small>{unit.state.toLowerCase()}</small>
                                                    </MenuItem>);
                                                } else {
                                                    return (<MenuItem
                                                        key={index}
                                                        value={unit.unit}
                                                        style={{
                                                            display: "flex", justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <span>{unit.unit}</span>
                                                        <small>{unit.state.toLowerCase()}</small>
                                                    </MenuItem>);
                                                }
                                            })}
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                    <br/>
                                </>) : (<>
                                    <FormControl
                                        name="house_number"
                                        required
                                        style={{
                                            marginTop: "10px", width: "49%",
                                        }}
                                        label="Property *"
                                        disabled={true}
                                    >
                                        <InputLabel id="demo-simple-select-required-label">
                                            Select House NO
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-required-label"
                                            id="demo-simple-select-required"
                                            value={selectedUnit}
                                            label="Unit *"
                                            onChange={(e) => {
                                                setSelectedUnit(e.target.value);
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText>Required</FormHelperText>
                                    </FormControl>
                                    <br/>
                                </>)}
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="rent"
                                    label="Rent"
                                    type="number"
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                {/*<Field*/}
                                {/*    as={TextField}*/}
                                {/*    name="deposit_held"*/}
                                {/*    label="Deposit Held"*/}
                                {/*    type="number"*/}
                                {/*    required*/}
                                {/*    variant="outlined"*/}
                                {/*    style={{marginTop: "10px", width: "49%"}}*/}
                                {/*/>*/}


                                <Button onClick={() => {
                                    setAddTenantModal(false)
                                    setDepositModal(true)
                                    // console.log("Adding deposit")
                                }}>
                                    <span style={{color: "green"}}>Add deposit</span>
                                </Button>
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >

                                <div

                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%"
                                    }}>
                                    <div
                                        style={{
                                            marginTop: "10px", width: "49%", display: "flex",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <span>Add Deposit As Arrear</span>
                                        {/*<Switch {...label} defaultChecked*/}
                                        {/*        onChange={setDepositAsArrears(!depositAsArrears)}/>*/}

                                        <Switch
                                            checked={depositAsArrears}
                                            onChange={() => setDepositAsArrears(!depositAsArrears)}
                                            inputProps={{'aria-label': 'controlled'}}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginTop: "10px", width: "49%", display: "flex",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <span>Add Rent As Arrear</span>
                                        {/*<Switch {...label} defaultChecked onChange={setRentAsArrears(!rentAsArrears)}/>*/}
                                        <Switch
                                            checked={rentAsArrears}
                                            onChange={() => setRentAsArrears(!rentAsArrears)}
                                            inputProps={{'aria-label': 'controlled'}}
                                        />
                                    </div>
                                </div>

                            </div>

                            <br/>
                            <Typography style={{textAlign: "center"}}>
                                NEXT OF KIN DETAILS
                            </Typography>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="next_of_kin_first_name"
                                    label="First Name"
                                    error={props.errors.first_name && props.touched.first_name}
                                    helperText={<ErrorMessage name="first_name"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "32%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="next_of_kin_last_name"
                                    label="Last Name"
                                    error={props.errors.first_name && props.touched.first_name}
                                    helperText={<ErrorMessage name="first_name"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "32%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="next_of_kin_relationship"
                                    label="Relationship"
                                    error={props.errors.first_name && props.touched.first_name}
                                    helperText={<ErrorMessage name="first_name"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "32%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="next_of_kin_contacts"
                                    label="Phone Number"
                                    error={props.errors.phoneNumber && props.touched.phoneNumber}
                                    helperText={<ErrorMessage name="phoneNumber"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="next_of_kin_national_id"
                                    label="National ID"
                                    type="number"
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>

                            <br/>
                            <Typography style={{textAlign: "center"}}>
                                TENANT FILES
                            </Typography>
                            <br/>
                            <div
                                style={{display: "flex", justifyContent: "space-between"}}
                            >
                    <span
                        style={{
                            width: "49%", display: "flex", justifyContent: "space-between",
                        }}
                    >
                      {tenantPhoto === null ? (<>
                          <span>Tenant Photo </span>
                          <Button variant="outlined" component="label">
                              Upload File
                              <input
                                  type="file"
                                  onChange={(e) => handleFile1(e)}
                                  hidden
                              />
                          </Button>
                      </>) : (<>
                          <div
                              style={{
                                  display: "flex", justifyContent: "space-between", width: "100%",
                              }}
                          >
                              <span>{photo}</span>
                              <DeleteOutline
                                  className="userListDelete"
                                  onClick={() => {
                                      setTenantPhoto(null);
                                      setPhoto(null);
                                  }}
                              />
                          </div>
                      </>)}
                    </span>
                                <span
                                    style={{
                                        width: "49%", display: "flex", justifyContent: "space-between",
                                    }}
                                >
                      {photoOfNationalID === null ? (<>
                          <span>Photo Of National ID </span>
                          <Button variant="outlined" component="label">
                              Upload File
                              <input
                                  type="file"
                                  onChange={(e) => handleFile2(e)}
                                  hidden
                              />
                          </Button>
                      </>) : (<>
                          <>
                              <div
                                  style={{
                                      display: "flex", justifyContent: "space-between", width: "100%",
                                  }}
                              >
                                  <span>{nationalID}</span>
                                  <DeleteOutline
                                      className="userListDelete"
                                      onClick={() => {
                                          setPhotoOfNationalID(null);
                                          setNationalID(null);
                                      }}
                                  />
                              </div>
                          </>
                      </>)}
                    </span>
                            </div>
                            <br/>
                            <div
                                style={{display: "flex", justifyContent: "space-between"}}
                            >
                    <span
                        style={{
                            width: "49%", display: "flex", justifyContent: "space-between",
                        }}
                    >
                      {copyOfContract === null ? (<>
                          {" "}
                          <span>Copy Of Contract</span>
                          <Button variant="outlined" component="label">
                              Upload File
                              <input
                                  type="file"
                                  onChange={(e) => handleFile3(e)}
                                  hidden
                              />
                          </Button>
                      </>) : (<>
                          <>
                              <div
                                  style={{
                                      display: "flex", justifyContent: "space-between", width: "100%",
                                  }}
                              >
                                  <span>{contract}</span>
                                  <DeleteOutline
                                      className="userListDelete"
                                      onClick={() => {
                                          setCopyOfContract(null);
                                          setContract(null);
                                      }}
                                  />
                              </div>
                          </>
                      </>)}
                    </span>
                                <span
                                    style={{
                                        width: "49%", display: "flex", justifyContent: "space-between",
                                    }}
                                >
                      {inventoryChecklist === null ? (<>
                          <span>Inventory Checklist</span>
                          <Button variant="outlined" component="label">
                              Upload File
                              <input
                                  type="file"
                                  onChange={(e) => handleFile4(e)}
                                  hidden
                              />
                          </Button>
                      </>) : (<>
                          <>
                              <div
                                  style={{
                                      display: "flex", justifyContent: "space-between", width: "100%",
                                  }}
                              >
                                  <span>{checklist}</span>
                                  <DeleteOutline
                                      className="userListDelete"
                                      onClick={() => {
                                          setInventoryChecklist(null);
                                          setChecklist(null);
                                      }}
                                  />
                              </div>
                          </>
                      </>)}
                    </span>
                            </div>

                            <br/>
                            <div
                                style={{
                                    width: "100%", display: "flex", justifyContent: "end", marginTop: "20px",
                                }}
                            >
                                <Button onClick={handleClose}>
                                    <span style={{color: "red"}}>Cancel</span>
                                </Button>
                                <LoadingButton
                                    type={"submit"}
                                    size="small"
                                    // onClick={addTenant}
                                    loading={tenantLoading}
                                    startIcon={<SendIcon/>}
                                    style={{color: "green"}}
                                    loadingPosition="start"
                                >
                                    Submit
                                </LoadingButton>
                            </div>
                        </Form>)}
                    </Formik>
                </DialogContent>
            </Dialog>
            {/*Bill Tenants MODAL*/}
            <Dialog
                fullWidth
                maxWidth="xl"
                open={billTenantsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center", display: "flex", justifyContent: "center",
                        }}
                    >
                        Billing Tenants
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px", display: "flex", justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            height: "500px", // width: "fit-content",
                            width: "95vw",
                        }}
                    >
                        <div
                            style={{
                                display: "flex", alignItems: "center", alignContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    marginRight: "10px",
                                }}
                            >
                                Billing for which month?
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    // disableFuture
                                    id="outlined-basic"
                                    variant="outlined"
                                    label="Date"
                                    openTo="year"
                                    views={["year", "month"]}
                                    value={month}
                                    onChange={(newValue) => {
                                        setMonth(newValue);
                                        setSelectedMonth(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        {currentTenants === null ? (<>
                            {warning ? (<div
                                style={{
                                    color: "red",
                                }}
                            >
                                Kindly fill all units' details before billing.
                            </div>) : (<></>)}
                            <DataGrid
                                className={classes.root}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field: "house_number", sort: "asc"}],
                                    },
                                }}
                                rows={searchResults}
                                disableSelectionOnClick
                                rowHeight={45}
                                columns={billingColumns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                            />
                        </>) : (<>
                            {warning ? (<div
                                style={{
                                    color: "red",
                                }}
                            >
                                Kindly fill all units' meter readings before billing..
                            </div>) : (<></>)}
                            <DataGrid
                                className={classes.root}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field: "house_number", sort: "asc"}],
                                    },
                                }}
                                rows={searchUpdateResults}
                                disableSelectionOnClick
                                rowHeight={45}
                                columns={billingColumns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                            />
                        </>)}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            fetch(ApiDetails + "pegasus/visionary/property/enableBilling", {
                                method: "POST", body: JSON.stringify({
                                    property_id: property.property_id,
                                }),
                            })
                                .then(async (response) => {
                                    if (response.status === 200) {
                                        window.location.reload(false);
                                    } else {
                                        props.snackBar({
                                            text: "Failed updating tenant details.", type: "error",
                                        });
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                    props.snackBar({
                                        text: "Something Went Wrong. Call Customer Care For Assistance.", type: "error",
                                    });
                                });
                            handleClose();
                        }}
                    >
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={billTenants}
                        loading={billLoading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                        disabled={!!warning}
                    >
                        Bill
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/*Bill Tenants MODAL*/}
            <Dialog
                fullWidth
                maxWidth="md"
                open={unitReportModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center", display: "flex", justifyContent: "center",
                        }}
                    >
                        Generate unit report
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        display: "flex", justifyContent: "center",
                    }}
                >

                    <div
                        style={{
                            display: "flex", justifyContent: "space-around", alignItems: "center", width: "100%"
                        }}
                    >
                        <FormControl
                            name="property_name"
                            required
                            style={{
                                marginTop: "10px",
                                width: "40%"
                            }}
                            label="Property *"
                        >
                            <InputLabel id="demo-simple-select-required-label">
                                Select Property
                            </InputLabel>
                            <Select
                                name="property_name"
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={addTenantPropertyName}
                                label="Property *"
                                onChange={(e) => {
                                    // console.log(e.target.value);
                                    setAddTenantPropertyName(e.target.value);
                                    propertyID = e.target.value;
                                    setPropertyID(e.target.value);
                                    getUnits();
                                }}
                            >
                                <MenuItem value={property.property_id}>
                                    {property.property_name}
                                </MenuItem>
                            </Select>
                            <FormHelperText>Required</FormHelperText>
                        </FormControl>
                        <br/>

                        {units !== null ? (<>
                            <FormControl
                                name="house_number"
                                required
                                style={{
                                    marginTop: "10px", width: "40%",
                                }}
                                label="Property *"
                            >
                                <InputLabel id="demo-simple-select-required-label">
                                    Select House NO
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={selectedUnit}
                                    label="Unit *"
                                    onChange={(e) => {
                                        setSelectedUnit(e.target.value);
                                        // console.log("Selected unit : " + e.target.value)
                                    }}
                                >

                                    {units.map((unit, index) => {
                                        // console.log(unit)
                                        return (<MenuItem
                                            key={index}
                                            value={unit.id}
                                            style={{
                                                display: "flex", justifyContent: "space-between",
                                            }}
                                        >
                                            <span>{unit.unit}</span>
                                        </MenuItem>);

                                    })}
                                </Select>
                                <FormHelperText>Required</FormHelperText>
                            </FormControl>
                            <br/>
                        </>) : (<>
                            <FormControl
                                name="house_number"
                                required
                                style={{
                                    marginTop: "10px", width: "40%",
                                }}
                                label="Property *"
                                disabled={true}
                            >
                                <InputLabel id="demo-simple-select-required-label">
                                    Select House NO
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={selectedUnit}
                                    label="Unit *"
                                    onChange={(e) => {
                                        setSelectedUnit(e.target.value);
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                </Select>
                                <FormHelperText>Required</FormHelperText>
                            </FormControl>
                            <br/>
                        </>)}
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setUnitReportModal(false)
                        }}
                    >
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={
                            downloadUnitReport
                        }
                        loading={billLoading}
                        startIcon={<SendIcon/>}
                        style={{color: "green"}}
                        loadingPosition="start"
                        disabled={!!warning}
                    >
                        Generate
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/*Bill Tenants MODAL*/}
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={unitsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center", display: "flex", justifyContent: "center",
                        }}
                    >
                        Edit Property Units
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        padding: "10px", display: "flex", justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            height: "500px", width: "100%",
                        }}
                    >
                        <div
                            style={{
                                border: "2px solid blue", borderRadius: "2px", padding: "5px", marginBottom: "5px", // display:"none"
                            }}
                        >
                            <h3
                                style={{
                                    display: "flex", justifyContent: "center",
                                }}
                            >
                                Add Unit
                            </h3>

                            <div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <TextField
                                        onChange={(e) => {
                                            setUnitName(e.target.value);
                                        }}
                                        label="Unit"
                                        variant="outlined"
                                        required
                                        style={{marginTop: "10px", width: "30%"}}
                                    />
                                    <TextField
                                        onChange={(e) => {
                                            setUnitMeterReading(e.target.value);
                                        }}
                                        label="Meter Reading"
                                        variant="outlined"
                                        required
                                        style={{marginTop: "10px", width: "30%"}}
                                    />
                                    <TextField
                                        onChange={(e) => {
                                            setElectricityUnitMeterReading(e.target.value);
                                        }}
                                        label="Electricity Reading"
                                        variant="outlined"
                                        required
                                        style={{marginTop: "10px", width: "30%"}}
                                    />

                                </div>
                                <div
                                    style={{
                                        display: "flex", justifyContent: "end",
                                    }}
                                >
                                    <Button
                                        onClick={addUnit}
                                        variant="outlined"
                                        style={{
                                            color: "white",
                                            text_transform: "uppercase",
                                            marginBottom: "10px", // width: "200px",
                                            line_height: "50px",
                                            border_radius: "5px",
                                            background: "#00B000",
                                            text_align: "center",
                                            box_shadow: "inset 0 0 25px rgba(0,0,0,.25)",
                                        }}
                                    >
                                        Upload
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <DataGrid
                            className={classes.root}
                            initialState={{
                                sorting: {
                                    sortModel: [{field: "unit", sort: "asc"}],
                                },
                            }}
                            rows={allUnits}
                            disableSelectionOnClick
                            rowHeight={45}
                            columns={unitsColumns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>
                    <Button onClick={updateUnits}>
                        <span style={{color: "green"}}>Update</span>
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Edit Property MODAL*/}
            <Dialog
                maxWidth={"md"}
                open={editModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{
                        width: "90%",
                    }}
                >
                    <div
                        style={{
                            alignItems: "center", display: "flex", justifyContent: "center",
                        }}
                    >
                        Edit Property
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        border: "2px solid blue",
                        borderRadius: "5px",
                        padding: "10px",
                        margin: "5px",
                        pointerEvents: property.agency_id !== 0 && property.agency_id !== JSON.parse(originalText)[0][1] ? "none" : "",
                    }}
                >
                    {finished ? (<Formik
                        initialValues={initialValues3}
                        validationSchema={updatePropertySchema}
                        onSubmit={updateProperty}
                    >
                        {(props) => (<Form noValidate>
                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="property_name"
                                    label="Property Name"
                                    variant="outlined"
                                    error={props.errors.property_name && props.touched.property_name}
                                    helperText={<ErrorMessage name="property_name"/>}
                                    required
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="location"
                                    label="Property Location"
                                    error={props.errors.location && props.touched.location}
                                    helperText={<ErrorMessage name="location"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="query_number"
                                    label="Query Number"
                                    error={props.errors.query_number && props.touched.query_number}
                                    helperText={<ErrorMessage name="query_number"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="payment_details"
                                    label="Payment Details"
                                    error={props.errors.payment_details && props.touched.payment_details}
                                    helperText={<ErrorMessage name="payment_details"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="minimum_water_bill"
                                    label="Min Water Bill"
                                    error={props.errors.minimum_water_bill && props.touched.minimum_water_bill}
                                    helperText={<ErrorMessage name="minimum_water_bill"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="water_bill"
                                    label="Water Bill"
                                    error={props.errors.water_bill && props.touched.water_bill}
                                    helperText={<ErrorMessage name="water_bill"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="minimum_electricity_bill"
                                    label="Min Electricity Bill"
                                    error={props.errors.minimum_electricity_bill && props.touched.minimum_electricity_bill}
                                    helperText={<ErrorMessage name="minimum_electricity_bill"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="electricity_bill"
                                    label="Electricity Bill"
                                    error={props.errors.electricity_bill && props.touched.electricity_bill}
                                    helperText={<ErrorMessage name="electricity_bill"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>

                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between",
                                }}
                            >
                                <Field
                                    as={TextField}
                                    name="other_charges"
                                    label="Other Charges"
                                    type={"number"}
                                    error={props.errors.other_charges && props.touched.other_charges}
                                    helperText={<ErrorMessage name="other_charges"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                                <Field
                                    as={TextField}
                                    name="minimum_days_to_vacate"
                                    label="Minimum months before vacating"
                                    type={"number"}
                                    error={props.errors.minimum_days_to_vacate && props.touched.minimum_days_to_vacate}
                                    helperText={<ErrorMessage name="minimum_days_to_vacate"/>}
                                    required
                                    variant="outlined"
                                    style={{marginTop: "10px", width: "49%"}}
                                />
                            </div>

                            <br/>

                            <div
                                style={{
                                    width: "100%", display: "flex", justifyContent: "end", // marginTop: "20px",
                                }}
                            >
                                <Button type="submit">
                                    <span style={{color: "green"}}>Save</span>
                                </Button>
                            </div>
                        </Form>)}
                    </Formik>) : (<></>)}
                    <hr
                        style={{
                            color: "blue", marginBottom: "5px",
                        }}
                    />
                    <div>
                        {finished ? (<div
                            style={{
                                display: "flex", justifyContent: "space-between",
                            }}
                        >
                            <div>
                    <span
                        style={{
                            fontSize: "18px", fontWeight: "bold", color: "#398DD2",
                        }}
                    >
                      Enable Penalties
                    </span>
                                <br/>
                                <Typography
                                    style={{
                                        color: "#959EAD", width: "80%",
                                    }}
                                >
                                    All payments done past the specified date shall incur the
                                    specified percentage penalty
                                </Typography>
                            </div>
                            {penalties === "YES" ? (<Switch
                                onChange={() => {
                                    let body = {
                                        property_id: property_id, apply_penalty: "NO",
                                    };
                                    // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
                                    fetch(ApiDetails + "pegasus/visionary/property/penalty", {
                                        method: "POST", body: JSON.stringify(body),
                                    })
                                        .then(async (response) => {
                                            if (response.status === 200) {
                                                setPenalties("NOT");
                                                props.snackBar({
                                                    text: "Changes have Been Saved", type: "success",
                                                });
                                            } else {
                                                props.snackBar({
                                                    text: "Something Went Wrong. Try Again Later", type: "error",
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                            props.snackBar({
                                                text: "Something Went Wrong. Try Again Later", type: "error",
                                            });
                                        });
                                }}
                                {...label}
                                defaultChecked
                            />) : (<Switch
                                onChange={() => {
                                    let body = {
                                        property_id: property_id, apply_penalty: "YES",
                                    };
                                    // "proxy":"http://0.0.0.0:8080/pegasus/visionary",
                                    fetch(ApiDetails + "pegasus/visionary/property/penalty", {
                                        method: "POST", body: JSON.stringify(body),
                                    })
                                        .then(async (response) => {
                                            if (response.status === 200) {
                                                setPenalties("YES");
                                                props.snackBar({
                                                    text: "Changes have Been Saved", type: "success",
                                                });
                                            } else {
                                                props.snackBar({
                                                    text: "Something Went Wrong. Try Again Later", type: "error",
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                            props.snackBar({
                                                text: "Something Went Wrong. Try Again Later", type: "error",
                                            });
                                        });
                                }}
                                {...label}
                                // defaultChecked
                            />)}
                        </div>) : (<></>)}
                        <br/>
                        {finished ? (<Formik
                            initialValues={initialValues5}
                            // validationSchema={updatePropertySchema}
                            onSubmit={updatePenaltyDetails}
                        >
                            {(props) => (<Form noValidate>
                                <div
                                    style={{
                                        display: "flex", justifyContent: "space-between",
                                    }}
                                >
                                    <Field
                                        as={TextField}
                                        name={"reminder_date"}
                                        label="Reminder Date"
                                        variant="outlined"
                                        required
                                        style={{marginTop: "10px", width: "30%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name="penalty_date"
                                        label="Penalty Date"
                                        required
                                        variant="outlined"
                                        disabled={penalties !== "YES"}
                                        style={{marginTop: "10px", width: "30%"}}
                                    />
                                    <Field
                                        as={TextField}
                                        name={"penalty_percentage"}
                                        label="Percentage Penalty"
                                        required
                                        variant="outlined"
                                        disabled={penalties !== "YES"}
                                        style={{marginTop: "10px", width: "30%"}}
                                    />
                                </div>
                                <br/>
                                <div
                                    style={{
                                        width: "100%", display: "flex", justifyContent: "end", // marginTop: "20px",
                                    }}
                                >
                                    <Button type={"submit"}>
                                        <span style={{color: "green"}}>Save</span>
                                    </Button>
                                </div>
                            </Form>)}
                        </Formik>) : (<></>)}

                        {/*<br />*/}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>
                </DialogActions>
            </Dialog>

            {/*Edit Property Payment details MODAL*/}
            <Dialog
                maxWidth="xl"
                open={editPaymentDetailsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    style={{
                        width: "90%",
                    }}
                >
                    <div
                        style={{
                            alignItems: "center", display: "flex", justifyContent: "center",
                        }}
                    >
                        Payment details
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{

                        padding: "10px",
                        margin: "5px",
                        pointerEvents: property.agency_id !== 0 && property.agency_id !== JSON.parse(originalText)[0][1] ? "none" : "",
                    }}
                >
                    {finished ? (<div
                        >
                            <Formik
                                initialValues={initialValues6}
                                validationSchema={updatePropertyPaymentDetailsSchema}
                                onSubmit={updatePropertyPaymentDetails}
                            >
                                {(props) => (<Form noValidate>
                                    <div
                                        style={{
                                            display: "flex", justifyContent: "space-between", width: "80vw"
                                        }}
                                    >
                                        <Field
                                            as={TextField}
                                            name="ho_number"
                                            label="HO Number"
                                            variant="outlined"
                                            error={props.errors.ho_number && props.touched.ho_number}
                                            helperText={<ErrorMessage name="ho_number"/>}
                                            required
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                        <Field
                                            as={TextField}
                                            name="till_number"
                                            label="Till / Paybill number"
                                            error={props.errors.till_number && props.touched.till_number}
                                            helperText={<ErrorMessage name="till_number"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "flex", justifyContent: "space-between", width: "80vw"

                                        }}
                                    >
                                        <Field
                                            as={TextField}
                                            name="short_code"
                                            label="Short Code"
                                            error={props.errors.short_code && props.touched.short_code}
                                            helperText={<ErrorMessage name="short_code"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                        <Field
                                            as={TextField}
                                            name="pass_key"
                                            label="Pass Key"
                                            error={props.errors.pass_key && props.touched.pass_key}
                                            helperText={<ErrorMessage name="pass_key"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                    </div>

                                    <div
                                        style={{
                                            display: "flex", justifyContent: "space-between", width: "80vw"

                                        }}
                                    >
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={[{
                                                label: "Pay Bill", value: 'CustomerPayBillOnline'
                                            }, {label: "Buy Goods", value: 'CustomerBuyGoodsOnline'},]}
                                            disabled={true}
                                            value={{label: "Buy Goods", value: 'CustomerBuyGoodsOnline'}}
                                            style={{marginTop: "10px", width: "49%"}}
                                            onChange={(e, value) => {
                                                setAccountType(value.value)
                                                console.log(value)
                                            }}
                                            renderInput={(params) => (<TextField
                                                id="outlined-basic"
                                                label="Select Account Type "
                                                variant="outlined"
                                                name="property"
                                                {...params}
                                            />)}
                                        />
                                        <Field
                                            as={TextField}
                                            name="consumer_secret"
                                            label="Consumer Secret"
                                            error={props.errors.consumer_secret && props.touched.consumer_secret}
                                            helperText={<ErrorMessage name="consumer_secret"/>}
                                            required
                                            variant="outlined"
                                            style={{marginTop: "10px", width: "49%"}}
                                        />
                                    </div>

                                    <div>
                                        {/*<a href="https://org.ke.m-pesa.com/orglogin.action?language=en" target={"_blank"}>MPESA ORGANIZATION POSTAL</a>*/}
                                    </div>
                                    <div
                                        style={{
                                            display: "flex", justifyContent: "right", width: "80vw"

                                        }}
                                    >
                                        <Button
                                            type={'submit'}>
                                            <span style={{color: "green"}}>Submit</span>
                                        </Button>
                                    </div>
                                </Form>)}
                            </Formik>
                        </div>

                    ) : (<></>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        <span style={{color: "red"}}>Cancel</span>
                    </Button>

                </DialogActions>
            </Dialog>


            {/*Property details*/}
            <div className="propertyShowDetails">

                <div
                    // className="propertyFeaturedItemBox"
                    style={{
                        display: "flex", justifyContent: "center", marginRight: "10px",
                    }}
                >
                    {propertyImage === null ? (<img
                        src={require("../../assets/img1.jpg")}
                        alt=""
                        style={{
                            width: "150px", height: "140px", borderRadius: "10px", object_fit: "cover",
                        }}
                    />) : (<img
                        src={`${propertyImage}`}
                        alt=""
                        style={{
                            width: matches ? "" : "150px", height: "140px", borderRadius: "10px", object_fit: "cover",
                        }}
                        onClick={() => {
                            setDisplayImage(!displayImage);
                            console.log("Setting image");
                        }}
                    />)}

                    <div
                        style={{
                            display: "flex", flexDirection: "column", justifyContent: "end",
                        }}
                    >
                        {matches ? (<></>) : (<>
                            <input
                                style={{
                                    display: "none",
                                }}
                                ref={ref}
                                onChange={(e) => {
                                    uploadPropertyImage(e);
                                }}
                                type="file"
                            />

                            <div
                                style={{
                                    marginLeft: "5px",
                                }}
                            >
                                <EditOutlined
                                    style={{color: "#398DD2"}}
                                    onClick={handleClick}
                                />
                            </div>
                        </>)}
                    </div>
                </div>
                <div
                    className="propertyFeaturedItemBox"
                    style={{
                        display: "flex", flexDirection: "column", justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex", alignItems: "center",
                        }}
                    >
                        <AccountCircleOutlined
                            className="sidebarListItemDetails"
                            style={{
                                color: "black",
                            }}
                        />
                        {/*<Typography style={{ paddingRight: "20px" }}>Name :</Typography>*/}
                        <span
                            style={{fontSize: "15px", marginLeft: "10px"}}
                            className="userShowUserTitle"
                        >
                {property.property_name}
              </span>
                    </div>
                    <div
                        style={{
                            display: "flex", alignItems: "center",
                        }}
                    >
                        <LocationOnOutlined
                            className="sidebarListItemDetails"
                            style={{
                                color: "black",
                            }}
                        />
                        {/*<Typography style={{ paddingRight: "20px" }}>Location :</Typography>*/}
                        <span
                            style={{fontSize: "15px", marginLeft: "10px"}}
                            className="userShowUserTitle"
                        >
                {property.location}
              </span>
                    </div>
                    <div
                        style={{
                            display: "flex", alignItems: "center",
                        }}
                    >
                        <ApartmentOutlined
                            className="sidebarListItemDetails"
                            style={{
                                color: "black",
                            }}
                        />
                        {/*<Typography style={{ paddingRight: "20px" }}>Location :</Typography>*/}

                        <span
                            style={{fontSize: "15px", marginLeft: "10px"}}
                            className="userShowUserTitle"
                        >
                {property.total_property_units} units
              </span>
                    </div>
                    <div
                        style={{
                            display: "flex", alignItems: "center",
                        }}
                    >
                        <PercentOutlined
                            className="sidebarListItemDetails"
                            style={{
                                color: "black",
                            }}
                        />
                        {/*<Typography style={{ paddingRight: "20px" }}>Location :</Typography>*/}
                        <span
                            style={{fontSize: "15px", marginLeft: "10px"}}
                            className="userShowUserTitle"
                        >
                {property.occupancy}
              </span>
                    </div>
                </div>
                <div className="propertyFeaturedItemBox">
                    <span className="featuredTitle">Payment details</span>
                    <div className="featuredMoneyContainer">
              <span
                  className="featuredMoney"
                  style={{
                      width: "100%",
                  }}
              >
                <div
                    style={{
                        width: "100%", display: "flex", alignItems: "center",
                    }}
                >
                  <span
                      style={{
                          color: "#398DD2", display: "flex", justifyContent: "space-between", alignItems: "center",
                      }}
                  >
                    Expected{" "}
                      <div
                          style={{
                              color: "green", fontSize: "18px", marginLeft: "20px",
                          }}
                      >
                      {expectedIncome.toLocaleString("en-US", {
                          style: "currency", currency: "KSH",
                      })}
                    </div>
                  </span>
                </div>
                <div
                    style={{
                        width: "100%", display: "flex", alignItems: "center",
                    }}
                >
                  <span
                      style={{
                          color: "#398DD2", display: "flex", justifyContent: "space-between",
                      }}
                  >
                    Received{" "}
                      <div
                          style={{
                              color: "green", fontSize: "18px", marginLeft: "20px",
                          }}
                      >
                      {incomeReceived.toLocaleString("en-US", {
                          style: "currency", currency: "KSH",
                      })}
                    </div>
                  </span>
                </div>
              </span>
                    </div>
                    <div className="featuredSub">For the month of {currentMonth}</div>
                </div>
                {property.agency_id !== 0 && property.agency_id !== JSON.parse(originalText)[0][1] ? (
                    <div className="propertyFeaturedItemBox">
                        <span className="featuredTitle">Manager</span>
                        <div className="featuredMoneyContainer">
                <span
                    className="featuredMoney"
                    style={{
                        width: "100%",
                    }}
                >
                  <div
                      style={{
                          width: "100%", display: "flex", alignItems: "center",
                      }}
                  >
                    <span style={{color: "#398DD2"}}>
                      Your property is being managed by{" "}
                        {property.property_manager_name}
                    </span>
                  </div>
                </span>
                        </div>
                    </div>) : (<>
                    {property.agency_id !== 0 ? (<div className="propertyFeaturedItemBox">
                        <span className="featuredTitle">Landlord details</span>
                        <div className="featuredMoneyContainer">
                    <span
                        className="featuredSub"
                        style={{
                            width: "100%",
                        }}
                    >
                      <div style={{display: "flex", flexDirection: "column"}}>
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                          <span
                              style={{
                                  flex: "20%",
                              }}
                          >
                            Name
                          </span>
                          <span
                              style={{
                                  flex: "70%",
                              }}
                          >
                            {property.property_landlord_name}
                          </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                          <span
                              style={{
                                  flex: "20%",
                              }}
                          >
                            email
                          </span>
                          <span
                              style={{
                                  flex: "70%",
                              }}
                          >
                            {property.property_landlord_email_address}
                          </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                          <span
                              style={{
                                  flex: "20%",
                              }}
                          >
                            Number
                          </span>
                          <span
                              style={{
                                  flex: "70%",
                              }}
                          >
                            {property.property_landlord_phone_number}
                          </span>
                        </div>
                      </div>
                    </span>
                        </div>
                    </div>) : (<></>)}
                </>)}


                <div
                    style={{
                        display: "flex", flexDirection: "column", justifyContent: "end",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex", alignItems: "center", textAlign: "center",
                        }}
                    >
                        {/*<Typography sx={{ minWidth: 100 }}>Contact</Typography>*/}
                        <Button
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                            }}
                        >
                            More
                        </Button>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleCloseMenu}
                        onClick={handleCloseMenu}
                        PaperProps={{
                            elevation: 0, sx: {
                                overflow: "visible",
                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                    width: 32, height: 32, ml: -0.5, mr: 1,
                                },
                                "&:before": {
                                    content: '""',
                                    display: "block",
                                    position: "absolute",
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: "background.paper",
                                    transform: "translateY(-50%) rotate(45deg)",
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{horizontal: "right", vertical: "top"}}
                        anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                    >
                        <MenuItem
                            style={{
                                padding: "10px , 0px",
                                pointerEvents: property.agency_id !== 0 && property.agency_id !== JSON.parse(originalText)[0][1] ? "none" : "",
                            }}
                            onClick={() => {
                                setMessageModal(true);
                            }}
                        >
                            <Message
                                style={{
                                    paddingRight: "10px",
                                }}
                            />{" "}
                            Broadcast Message
                        </MenuItem>
                        <MenuItem
                            style={{
                                padding: "10px , 0px",
                                pointerEvents: property.agency_id !== 0 && property.agency_id !== JSON.parse(originalText)[0][1] ? "none" : "",
                            }}
                            onClick={() => {
                                broadCastCredentials()
                            }}
                        >
                            <SecurityOutlined
                                style={{
                                    paddingRight: "10px",
                                }}
                            />{" "}
                            Broadcast Credentials
                        </MenuItem>

                        <MenuItem
                            style={{
                                padding: "10px , 0px",
                                pointerEvents: property.agency_id !== 0 && property.agency_id !== JSON.parse(originalText)[0][1] ? "none" : "",
                            }}
                            onClick={() => {
                                setUnits([])
                                setUnitReportModal(true)
                            }}
                        >
                            <SecurityOutlined
                                style={{
                                    paddingRight: "10px",
                                }}
                            />{" "}
                            Download unit report
                        </MenuItem>

                        <MenuItem
                            style={{
                                padding: "10px , 0px",
                            }}
                            onClick={() => {
                                setEditModal(true);
                            }}
                        >
                            <EditOutlined
                                style={{
                                    paddingRight: "10px",
                                }}
                            />{" "}
                            Edit Details
                        </MenuItem>

                        <MenuItem
                            style={{
                                padding: "10px , 0px",
                            }}
                            onClick={() => {
                                setEditPaymentDetailsModal(true);
                            }}
                        >
                            <EditOutlined
                                style={{
                                    paddingRight: "10px",
                                }}
                            />{" "}
                            Edit Payment Details
                        </MenuItem>
                        <MenuItem
                            style={{
                                padding: "10px , 0px",
                            }}
                            onClick={async () => {
                                await getAllUnits();
                                setUnitsModal(true);
                            }}
                        >
                            <HomeOutlined
                                style={{
                                    paddingRight: "10px",
                                }}
                            />{" "}
                            Edit Units
                        </MenuItem>
                        <MenuItem
                            style={{
                                padding: "10px , 0px",
                                pointerEvents: property.agency_id !== 0 && property.agency_id !== JSON.parse(originalText)[0][1] ? "none" : "",
                            }}
                            onClick={() => {
                                setBillTenantsModal(true);
                            }}
                        >
                            <RequestQuoteOutlined
                                style={{
                                    paddingRight: "10px",
                                }}
                            />{" "}
                            Bill Tenants
                        </MenuItem>
                        <MenuItem
                            style={{
                                padding: "10px , 0px",
                            }}
                            onClick={() => {
                                setAgencyTokenModal(true);
                            }}
                        >
                            <AddLink
                                style={{
                                    paddingRight: "10px",
                                }}
                            />{" "}
                            Link property to agency
                        </MenuItem>

                        {property.agency_id !== 0 && property.agency_id !== JSON.parse(originalText)[0][1] ? (<MenuItem
                            style={{
                                padding: "10px , 0px", pointerEvents: "none",
                            }}
                        >
                            <WarningAmberOutlined
                                style={{
                                    paddingRight: "10px",
                                }}
                            />{" "}
                            <span
                                style={{
                                    color: "red",
                                }}
                            >
                    Some features mights be disabled for managed properties
                  </span>
                        </MenuItem>) : (<p></p>)}
                    </Menu>
                </div>
            </div>
            <div
                style={{
                    width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        paddingTop: "10px", display: "flex", justifyContent: "center",
                    }}
                >
                    <TextField
                        id="outlined-basic"
                        label="Search tenants ... "
                        variant="outlined"
                        name="firstname"
                        onChange={(e) => {
                            searchUser(e);
                        }}
                        style={{paddingRight: "10px"}}
                    />
                </div>

                {matches ? (<AddCircleOutlined
                    onClick={() => {
                        setAddTenantModal(true);
                    }}
                    variant="outlined"
                    style={{
                        marginLeft: "10px", marginRight: "10px", color: "green", scale: "1.5",
                    }}
                />) : (<Button
                    onClick={() => {
                        setAddTenantModal(true);
                    }}
                    variant="outlined"
                    style={{
                        color: "white",
                        text_transform: "uppercase",
                        width: "200px",
                        line_height: "50px",
                        border_radius: "5px",
                        background: "#00B000",
                        text_align: "center",
                        box_shadow: "inset 0 0 25px rgba(0,0,0,.25)",
                    }}
                >
                    Add Tenant
                </Button>)}
            </div>
            {/*  Property tenants*/}
            <div
                style={{
                    height: "calc(100vh - 308px)", marginTop: "10px",
                }}
            >
                {currentTenants === null ? (<>
                    <DataGrid
                        getRowId={(row) => row.tenant_id}
                        initialState={{
                            sorting: {
                                sortModel: [{field: "house_number", sort: "asc"}],
                            },
                        }}
                        className={classes.root}
                        rows={searchResults}
                        disableSelectionOnClick
                        rowHeight={45}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </>) : (<>
                    <DataGrid
                        getRowId={(row) => row.tenant_id}
                        initialState={{
                            sorting: {
                                sortModel: [{field: "house_number", sort: "asc"}],
                            },
                        }}
                        className={classes.root}
                        rows={searchUpdateResults}
                        disableSelectionOnClick
                        rowHeight={45}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </>)}
            </div>
        </div>
    </>);
}