import React from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import "./FormPage.css";
import {Autocomplete, TextField, Typography} from "@mui/material";
import {showSnackbar} from "../../../../../components/SnackBar/SnackBar";
import {ApiDetails} from "../../../../../dummyData";

export default function FormComponent(props) {

    const {propertyDetails, originalDetails} = props; // Access tenant files from props


    const validationSchema = Yup.object({
        property_name: Yup.string().required("Property name is required"),
        location: Yup.string().required("Location is required"),
        reminder_date: Yup.number()
            .required("Reminder date is required")
            .min(2, "Reminder date must be between 2–28 of any month")
            .max(28, "Reminder date must be between 2–28 of any month"),
        minimum_days_to_vacate: Yup.number()
            .required("Minimum days to vacate is required")
            .min(0, "Minimum days to vacate must be at least 0"),
        water_bill: Yup.number().required("Water bill is required"),
        minimum_water_bill: Yup.number().required("Minimum water bill is required"),
        electricity_bill: Yup.number().required("Electricity bill is required"),
        minimum_electricity_bill: Yup.number().required(
            "Minimum electricity bill is required"
        ),
        other_charges: Yup.number().required("Other charges are required"),
        query_number: Yup.string().required("Query number is required"),
        payment_details: Yup.string().required("Payment details are required"),
        apply_penalty: Yup.string()
            .oneOf(["YES", "NO"], "Apply penalty must be YES or NO")
            .required("Apply penalty is required"),
        penalty_date: Yup.number()
            .required("Penalty date is required")
            .min(2, "Penalty date must be between 2–28 of any month")
            .max(28, "Penalty date must be between 2–28 of any month"),
        penalty_percentage: Yup.number().nullable(),
    });

    const initialValues = {
        property_name: propertyDetails.property_name,
        location: propertyDetails.location,
        reminder_date: propertyDetails.reminder_date === "" ? 5 : propertyDetails.reminder_date,
        minimum_days_to_vacate: propertyDetails.minimum_days_to_vacate,
        water_bill: propertyDetails.water_bill,
        minimum_water_bill: propertyDetails.minimum_water_bill,
        electricity_bill: propertyDetails.electricity_bill,
        minimum_electricity_bill: propertyDetails.minimum_electricity_bill,
        other_charges: propertyDetails.other_charges,
        query_number: propertyDetails.query_number,
        payment_details: propertyDetails.payment_details,
        apply_penalty: propertyDetails.apply_penalty,
        penalty_date: propertyDetails.penalty_date === "" ? 11 : propertyDetails.penalty_date,
        penalty_percentage: propertyDetails.penalty_percentage === "" ? 10 : propertyDetails.penalty_percentage,
    };

    const handleSubmitOld = (values) => {
        console.log("Form Data Submitted:", values);
        showSnackbar({
            title: "Success!",
            message: "Form submitted successfully.",
            type: "success",
            position: {vertical: "bottom", horizontal: "right"},
        });
    };

    const handleSubmit = (values) => {
        fetch(ApiDetails + "pegasus/visionary/property/update", {
            method: "POST", body: JSON.stringify({
                ...values,
                property_id: propertyDetails.property_id,
                // old_name: propertyDetails.property_name,
                // property_image: propertyDetails.property_image,
                // email_address: JSON.parse(originalDetails)[5][1],
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    showSnackbar({
                        title: "Success!",
                        message: "Property details updated successfully.",
                        type: "success",
                        position: {vertical: "bottom", horizontal: "right"},
                    });
                    window.location.reload(false);
                } else {

                    showSnackbar({
                        title: "Error!",
                        message: "Something went wrong. Please try again later.",
                        type: "error",
                        position: {vertical: "bottom", horizontal: "right"},
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong. Please try again later.",
                    type: "error",
                    position: {vertical: "bottom", horizontal: "right"},
                });
            });
    };


    if (propertyDetails.property_id === "") return <div>Loading...</div>;

    return (


        <div className="container">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form className="form">
                        {/* Property Details */}
                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Property Name:</Typography>
                                <Field
                                    type="text"
                                    id="property_name"
                                    name="property_name"
                                    placeholder="Enter Property Name"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="property_name"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">Location:</Typography>
                                <Field
                                    type="text"
                                    id="location"
                                    name="location"
                                    placeholder="Enter Location"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="location"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Reminder Date:</Typography>
                                <Field
                                    type="number"
                                    id="reminder_date"
                                    name="reminder_date"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="reminder_date"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">
                                    Minimum Months to Vacate:
                                </Typography>
                                <Field
                                    type="number"
                                    id="minimum_days_to_vacate"
                                    name="minimum_days_to_vacate"
                                    placeholder="Enter Minimum Days"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="minimum_days_to_vacate"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        {/* Billing Information */}
                        <h3 className="section-title">Billing Information</h3>
                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Water Bill:</Typography>
                                <Field
                                    type="number"
                                    id="water_bill"
                                    name="water_bill"
                                    placeholder="Enter Water Bill"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="water_bill"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">Minimum Water Bill:</Typography>
                                <Field
                                    type="number"
                                    id="minimum_water_bill"
                                    name="minimum_water_bill"
                                    placeholder="Enter Minimum Water Bill"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="minimum_water_bill"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Electricity Bill:</Typography>
                                <Field
                                    type="number"
                                    id="electricity_bill"
                                    name="electricity_bill"
                                    placeholder="Enter Electricity Bill"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="electricity_bill"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">
                                    Minimum Electricity Bill:
                                </Typography>
                                <Field
                                    type="number"
                                    id="minimum_electricity_bill"
                                    name="minimum_electricity_bill"
                                    placeholder="Enter Minimum Electricity Bill"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="minimum_electricity_bill"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Other Charges:</Typography>
                                <Field
                                    type="number"
                                    id="other_charges"
                                    name="other_charges"
                                    placeholder="Enter Other Charges"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="other_charges"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Query Number:</Typography>
                                <Field
                                    type="text"
                                    id="query_number"
                                    name="query_number"
                                    placeholder="Enter Query Number"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="query_number"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Payment Details:</Typography>
                                <Field
                                    as="textarea"
                                    id="payment_details"
                                    name="payment_details"
                                    placeholder="Enter Payment Details"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="payment_details"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        {/* Penalty Information */}
                        <h3 className="section-title">Penalty Information</h3>
                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1"></Typography>
                                <Field name="apply_penalty">
                                    {({field, form}) => (
                                        <Autocomplete
                                            options={["YES", "NO"]}
                                            getOptionLabel={(option) => option}
                                            value={field.value || ""}
                                            onChange={(event, newValue) => {
                                                form.setFieldValue(field.name, newValue || ""); // Update form value
                                            }}
                                            style={{paddingTop: "10px"}}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Apply Penalty"
                                                    variant="outlined"
                                                    error={
                                                        form.touched[field.name] &&
                                                        Boolean(form.errors[field.name])
                                                    }
                                                    helperText={
                                                        form.touched[field.name] &&
                                                        form.errors[field.name]
                                                    }
                                                />
                                            )}
                                        />
                                    )}
                                </Field>

                            </div>
                            <div className="form-group">
                                <Typography variant="subtitle1">Penalty Date:</Typography>
                                <Field
                                    type="number"
                                    id="penalty_date"
                                    name="penalty_date"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="penalty_date"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group">
                                <Typography variant="subtitle1">Penalty Percentage:</Typography>
                                <Field
                                    type="number"
                                    id="penalty_percentage"
                                    name="penalty_percentage"
                                    placeholder="Enter Penalty Percentage"
                                    className="light-blue"
                                />
                                <ErrorMessage
                                    name="penalty_percentage"
                                    component="div"
                                    className="error-message"
                                />
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="form-row">
                            <button type="submit" className="button primary">
                                Submit Form
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
