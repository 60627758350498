import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Tabs,
    Tab,
    Button,
    IconButton,
    Avatar,
    Divider,
    Fade,
} from "@mui/material";
import {styled} from "@mui/system";
import Transactions from "../Transactions/Transactions";
import Arrears from "../Arrears/Arrears";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {TextField} from "@material-ui/core";
import NonEditableRichTextEditor from "../../../../components/RichTextEditorNotEditable/NonEditableRichTextEditor";
import {convertFromRaw, EditorState} from "draft-js";
import Tooltip from "@mui/material/Tooltip";
import {DeleteOutline} from "@mui/icons-material";
import Notices from "../Notices/Notices";

const Account = (props) => {

    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };



    return (
        <>

            {/*<Grid item xs={15} md={8}>*/}
            {/* Tabs Section */}
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                sx={{marginBottom: 2}}
            >
                <Tab label="Transactions"/>
                <Tab label="Arrears"/>
                <Tab label="Vacating Notice"/>
            </Tabs>

            {/* Tab Content */}
            {activeTab === 0 && (
                <Transactions tenantID={props.tenantID}/>
            )}

            {activeTab === 1 && (
                <Arrears tenantID={props.tenantID}/>
            )}

            {activeTab === 2 && (
                <Notices tenantID={props.tenantID}/>
            )}

        </>
    );
};

export default Account;
