import React, { useEffect, useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ApiDetails, KEY } from "../../dummyData";
import Tooltip from "@mui/material/Tooltip";
import {
  ChatBubbleOutlineOutlined,
  DoneAllOutlined,
  Info,
} from "@material-ui/icons";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import RichTextEditor from "../../components/RichTextEditorEditable/RichTextEditor";
import NonEditableRichTextEditor from "../../components/RichTextEditorNotEditable/NonEditableRichTextEditor";
import Confetti from "react-confetti";
import { useWindowSize } from "@react-hook/window-size";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@material-ui/core/Typography";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { InputLabel, TextField } from "@material-ui/core";
import CryptoJS from "crypto-js";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>,
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TenantIssues(props) {
  const matches = useMediaQuery("(max-width:700px)");

  let myMapDetailsDecrypted = CryptoJS.AES.decrypt(
    localStorage.getItem("myMap"),
    KEY
  );
  let originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

  // const { width, height } = useWindowSize();
  //Loading Screen
  const [backDrop, setBackDrop] = useState(
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={null}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  const [all, setAll] = useState(false);
  const [issues, setIssues] = useState([]);

  const [issueId, setIssueId] = useState([]);
  const [houseNumber, setHouseNumber] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [tenantId, setTenantId] = useState([]);
  const [tenantNames, setTenantNames] = useState([]);
  const [propertyName, setPropertyName] = useState([]);

  const [expensesIncurred, setExpensesIncurred] = useState(1);

  const [expenses, setExpenses] = useState([]);

  const [inputs, setInputs] = useState();

  const [details, setDetails] = useState(new Map());

  const [loading, setLoading] = React.useState(false);
  const [expenseItem] = useState(new Map());

  const [issuesDisplaying, setIssuesDisplaying] = useState("Pending issues");

  //Message
  const [message, setMessage] = useState("");

  const divRef = useRef(null);

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  }, [issues]);

  //Modals
  const [closeIssueModal, setCloseIssueModal] = useState(false);
  const [issueMessageReplyModal, setIssueMessageReplyModal] = useState(false);
  const [moreInfoModal, setMoreInfoModal] = useState(false);

  //Trying To Use UseEffect To Set Component Data
  useEffect(() => {
    props.refreshSideBar();
    fetch(ApiDetails + "pegasus/visionary/issues/pending", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
      }),
    })
      .then(async (response) => {
        let data = await response.json();
        if (response.status === 200) {
          console.log(data)
          setIssues(data);
          props.snackBar({
            text: "Issues Fetched successfully.",
            type: "success",
          });
        } else {
          props.snackBar({
            text: "Failed fetching issues.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
    setBackDrop(<></>);
  }, []);

  const handleChange = (props) => (event) => {
    expenseItem.set(event.target.name, event.target.value);
    setExpenses([...expenses, expenseItem]);
  };

  const handleClose = () => {
    setMoreInfoModal(false);
    setCloseIssueModal(false);
    setIssueMessageReplyModal(false);
  };

  const sendResponse = (id) => {
    setLoading(true);
    fetch(ApiDetails + "pegasus/visionary/issues/response/Message", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
        phoneNumber: phoneNumber,
        tenantNames: tenantNames,
        tenantId: tenantId,
        message: message,
      }),
    })
      .then(async (response) => {
        // console.log(data);
        if (response.status === 200) {
          if (all) {
            getIssues();
          } else {
            getAllIssues();
          }

          setLoading(false);
          handleClose();
        } else {
          setLoading(false);
          handleClose();

          props.snackBar({
            text: "Failed closing issue.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        handleClose();

        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
  };
  const closeIssue = (id) => {
    // console.log(expenses[0]);
    let issueExpenses = new Map(expenses[0]);
    let sortedMap = new Map();

    issueExpenses.forEach(function (value, key) {
      let newKey = value;
      let includes = key;
      // console.log(key + " = " + value);
      if (!key.includes("value")) {
        issueExpenses.forEach(function (value, key) {
          // console.log(key + " = " + value);
          if (key.includes(includes)) {
            sortedMap.set(newKey, value);
          }
        });
      }
    });
    // console.log(sortedMap);
    // return;
    setLoading(true);

    fetch(ApiDetails + "pegasus/visionary/issues/close", {
      method: "POST",
      body: JSON.stringify({
        id: id,
        house_number: houseNumber,
        property_name: propertyName,
        landlord_id: JSON.parse(originalText)[0][1],
        resolution: JSON.stringify(
          convertToRaw(editorState.getCurrentContent())
        ),
        expenses: Object.fromEntries(sortedMap),
        phoneNumber: phoneNumber,
        tenantId: tenantId,
      }),
    })
      .then(async (response) => {
        // console.log(data);
        if (response.status === 200) {
          if (all) {
            getIssues();
          } else {
            getAllIssues();
          }

          setLoading(false);
          handleClose();
        } else {
          setLoading(false);
          handleClose();

          props.snackBar({
            text: "Failed closing issue.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        handleClose();

        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
  };
  const getIssues = () => {
    fetch(ApiDetails + "pegasus/visionary/issues/pending", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
      }),
    })
      .then(async (response) => {
        let data = await response.json();
        // console.log(data);
        if (response.status === 200) {
          setIssues(data);
          setAll(false);
          props.snackBar({
            text: "Issues fetched successfully.",
            type: "success",
          });
        } else {
          props.snackBar({
            text: "Failed fetching issues.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
  };
  const getAllIssues = () => {
    fetch(ApiDetails + "pegasus/visionary/issues", {
      method: "POST",
      body: JSON.stringify({
        landlord_id: JSON.parse(originalText)[0][1],
      }),
    })
      .then(async (response) => {
        let data = await response.json();
        // console.log(data);
        if (response.status === 200) {
          setIssues(data);
          setAll(true);
          props.snackBar({
            text: "Issues fetched successfully.",
            type: "success",
          });
        } else {
          props.snackBar({
            text: "Failed fetching issues.",
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        props.snackBar({
          text: "Something Went Wrong. Call Customer Care For Assistance.",
          type: "error",
        });
      });
  };

  return (
    <div
      style={{
        padding: "0 10px",
      }}
    >
      {backDrop}

      {/*Vacating Details MODAL*/}
      <Dialog
        open={issueMessageReplyModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Reply to {tenantNames}'s issue
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "10px",
          }}
        >
          <div>
            <div
              style={{
                width: "calc(100% - 20px)",
                padding: "10px",
              }}
            ></div>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              placeholder="Type message .."
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              style={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <Button onClick={handleClose}>
              <span style={{ color: "red" }}>Close</span>
            </Button>
            <LoadingButton
              onClick={() => {
                sendResponse(issueId);
              }}
              size="small"
              // onClick={addTenant}
              loading={loading}
              startIcon={<SendIcon />}
              style={{ color: "green" }}
              loadingPosition="start"
            >
              Submit
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
      {/*Vacating Details MODAL*/}
      <Dialog
        open={closeIssueModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Issue Resolution
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "10px",
          }}
        >
          <div>
            <Typography>
              Please provide more information on how the issues was solved
            </Typography>
            <RichTextEditor
              richTextValue={editorState}
              change={(value) => {
                setEditorState(value);
              }}
            />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Expenses Incurred</Typography>
              <Button
                onClick={() => {
                  setExpensesIncurred(expensesIncurred + 1);
                  setInputs(
                    [...Array(expensesIncurred)].map(
                      (elementInArray, index) => (
                        <div key={index}>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextField
                              label="Description"
                              type={"text"}
                              variant="outlined"
                              name={"description " + (index + 1)}
                              required
                              style={{ marginTop: "10px", width: "49%" }}
                              onChange={handleChange("property" + (index + 1))}
                            />
                            <TextField
                              label="Amount"
                              type={"number"}
                              name={"value for description " + (index + 1)}
                              variant="outlined"
                              style={{ marginTop: "10px", width: "49%" }}
                              onChange={handleChange("property" + (index + 1))}
                            />
                          </div>
                        </div>
                      )
                    )
                  );
                }}
              >
                <span style={{ color: "green" }}>Add Expense</span>
              </Button>
            </div>
            <div>{inputs}</div>

            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              <Button onClick={handleClose}>
                <span style={{ color: "red" }}>Close</span>
              </Button>
              <LoadingButton
                onClick={() => {
                  closeIssue(issueId);
                }}
                size="small"
                // onClick={addTenant}
                loading={loading}
                startIcon={<SendIcon />}
                style={{ color: "green" }}
                loadingPosition="start"
              >
                Submit
              </LoadingButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/*Vacating Details MODAL*/}
      <Dialog
        open={moreInfoModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {details.get("tenant_names") +
              "'s issue on " +
              details.get("problem_type") +
              " - " +
              details.get("problem")}
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "10px",
          }}
        >
          {moreInfoModal ? (
            <>
              {details.get("resolution") === undefined ? (
                <></>
              ) : (
                <>
                  <InputLabel>More information on the issue</InputLabel>
                  <NonEditableRichTextEditor
                    richTextValue={EditorState.createWithContent(
                      convertFromRaw(JSON.parse(details.get("description")))
                    )}
                  />
                </>
              )}

              <br />
              {details.get("resolution") === undefined ? (
                <></>
              ) : (
                <>
                  <InputLabel>Solution implemented</InputLabel>
                  <NonEditableRichTextEditor
                    richTextValue={EditorState.createWithContent(
                      convertFromRaw(JSON.parse(details.get("resolution")))
                    )}
                  />
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <br />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <Button onClick={handleClose}>
              <span style={{ color: "red" }}>Close</span>
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            textAlign: "center",
            paddingTop: "20px",
            marginBottom: "20px",
          }}
        >
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#398DD2",
            }}
          >
            Tenant's Issues
          </h2>
        </div>

        <FormControl
          name="property_name"
          required
          style={{
            marginTop: "10px",
            width: "49%",
          }}
          label="Property *"
        >
          <InputLabel id="demo-simple-select-required-label">
            Select type
          </InputLabel>
          <Select
            name="property_name"
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={issuesDisplaying}
            onChange={(e) => {
              if (!e.target.value) {
                getIssues();
                setIssuesDisplaying("Pending issues");
              } else {
                getAllIssues();
                setIssuesDisplaying("All issues");
              }
            }}
          >
            <MenuItem value={true}>
              <em>All issues</em>
            </MenuItem>
            <MenuItem value={false}>
              <em>Pending issues</em>
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          height: "500px",
          overflow: "auto",
        }}
      >
        {/*Issues Div*/}
        {issues.length === 0 ? (
          <>
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              NO PENDING ISSUES FOUND
              <Confetti />
            </div>
          </>
        ) : (
          <></>
        )}
        {issues.map((e, key) => {
          let issue = new Map(Object.entries(e));
          let status = issue.get("status");
          let id = issue.get("id");
          return (
            <div key={key}>
              {status === "OPEN" ? (
                <div
                  key={id}
                  style={{
                    marginBottom: "20px",
                    padding: "5px 5px",
                    borderRadius: "5px",
                    background: "rgba(255,0,0,0.3)",
                    border: " 2px solid red",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          marginRight: "10px",
                        }}
                      >
                        {issue.get("tenant_names")}
                      </div>
                      <small>{issue.get("phone_number")}</small>
                      <br />
                      <small>
                        {issue.get("property_name")} -{" "}
                        {issue.get("house_number")}
                      </small>
                    </div>

                    {matches ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginRight: "10px",
                          }}
                        >
                          {issue.get("problem_type")}
                        </div>
                        <small>{issue.get("problem")}</small>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginRight: "10px",
                          }}
                        >
                          {issue.get("problem_type")}
                        </div>
                        <small>- {issue.get("problem")}</small>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        {matches ? (
                          <>
                            {issue.get("date_created").toString().split(",")[0]}{" "}
                            {", "}
                            {issue.get("date_created").toString().split(",")[1]}
                          </>
                        ) : (
                          <>{issue.get("date_created")}</>
                        )}
                      </div>

                      <Tooltip title="Reply">
                        <ChatBubbleOutlineOutlined
                          style={{
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            color: "#398DD2",
                          }}
                          onClick={() => {
                            // setIssueId(id);
                            // setHouseNumber(issue.get("house_number"));
                            // console.log(issue);
                            setPhoneNumber(issue.get("phone_number"));
                            setTenantId(issue.get("tenant_id"));
                            setTenantNames(issue.get("tenant_names"));
                            setPropertyName(issue.get("property_name"));
                            setIssueMessageReplyModal(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Make as done">
                        <DoneAllOutlined
                          style={{
                            color: "#398DD2",
                          }}
                          onClick={() => {
                            setIssueId(id);
                            setHouseNumber(issue.get("house_number"));
                            setPhoneNumber(issue.get("phone_number"));
                            setTenantId(issue.get("tenant_id"));
                            setPropertyName(issue.get("property_name"));
                            setCloseIssueModal(true);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>

                  <div>
                    {(() => {
                      try {
                        return (
                            <NonEditableRichTextEditor
                                richTextValue={EditorState.createWithContent(
                                    convertFromRaw(JSON.parse(issue.get("description")))
                                )}
                            />
                        );
                      } catch (error) {
                        console.error("Failed to parse JSON, trying raw conversion", error);
                        try {
                          return (
                              <NonEditableRichTextEditor
                                  richTextValue={EditorState.createWithContent(
                                      convertFromRaw(issue.get("description"))
                                  )}
                              />
                          );
                        } catch (fallbackError) {
                          console.error("Failed to convert raw description", fallbackError);
                          return <div>Error rendering description</div>;
                        }
                      }
                    })()}
                  </div>

                </div>
              ) : (
                  <div
                      key={id}
                      style={{
                        marginBottom: "20px",
                        padding: "5px 5px",
                        borderRadius: "5px",
                        background: "rgba(0,255,0,0.3)",
                        border: " 2px solid green",
                      }}
                  >
                    <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                    >
                      <div>
                        <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "20px",
                          marginRight: "10px",
                        }}
                      >
                        {issue.get("tenant_names")}
                      </div>
                      <small>{issue.get("phone_number")}</small>
                      <br />
                      <small>
                        {issue.get("property_name")} -{" "}
                        {issue.get("house_number")}
                      </small>
                    </div>

                    {matches ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginRight: "10px",
                          }}
                        >
                          {issue.get("problem_type")}
                        </div>
                        <small>{issue.get("problem")}</small>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            marginRight: "10px",
                          }}
                        >
                          {issue.get("problem_type")}
                        </div>
                        <small>- {issue.get("problem")}</small>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        {matches ? (
                          <>
                            {issue.get("date_created").toString().split(",")[0]}{" "}
                            {", "}
                            {issue.get("date_created").toString().split(",")[1]}
                          </>
                        ) : (
                          <>{issue.get("date_created")}</>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "end",
                        }}
                      >
                        <div>
                          <Tooltip title="More information">
                            <Info
                              onClick={() => {
                                setDetails(issue);
                                setMoreInfoModal(true);
                              }}
                              style={{
                                color: "#398DD2",
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <NonEditableRichTextEditor
                      richTextValue={EditorState.createWithContent(
                        convertFromRaw(JSON.parse(issue.get("description")))
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
        <div ref={divRef} />
      </div>
    </div>
  );
}
