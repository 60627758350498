import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Tabs,
    Tab,
    Button,
    IconButton,
    Avatar,
    Divider,
    Fade, TextField,
} from "@mui/material";
import {styled} from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import {ApiDetails, KEY} from "../../../dummyData";
import Chip from "@mui/material/Chip";
import FormComponent from "./Form/FormComponent";
import MessagesComponent from "./Message/MessagesComponent";
import TenantFiles from "./Download Files/TenantFiles";
import TenantReport from "./Report/TenantReport";
import CryptoJS from "crypto-js";
import {showSnackbar} from "../../../components/SnackBar/SnackBar";
import imageAvatar from "../../../assets/Tenant_avatar.jpg";
import Account from "./Account/Account";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {Field, Form, Formik} from "formik";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import LeftSide from "./Left Side/LeftSide";
import RightSide from "./Right Side/RightSide";
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@material-ui/core/styles";
import Switch from "@mui/material/Switch";
import {useConfirm} from "material-ui-confirm";
import useGoBack from "../../../components/useGoBack";


const useStyles = makeStyles({
    root: {
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
            outline: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
            fontSize: 16,
            color: "darkblue",
            backgroundColor: "rgb(245,245,245)",
        },
    },
});


const StyledDataGrid = styled(DataGrid)(({theme}) => ({
    "& .MuiDataGrid-root": {
        height: "400px", // Prevents it from exceeding 500px
        minHeight: "200px", // Ensures it remains visible even with few rows
        overflowY: "auto",  // Enables scrolling when needed
    },
    "& .MuiDataGrid-columnHeaders": {
        fontSize: "1rem",
        fontWeight: 700,
        backgroundColor: "#E3F2FD", // Light blue background
        color: "#0D47A1", // Dark blue text
    },
    "& .MuiDataGrid-row:hover": {
        backgroundColor: "#f5faf5", // Light green hover color
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none",
    },
    "& .MuiDataGrid-cell": {
        fontSize: "0.9rem",
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>,
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
    dialogTitle: {
        textAlign: "center",
        fontWeight: "bold",
        color: "#2C5283",
        padding: "16px",
        borderBottom: "2px solid #2C5283",
    },
    dialogContent: {
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    container: {
        height: "500px",
        width: "100%",
        maxWidth: "90vw",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
        // padding: "15px",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    },
    billingHeader: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "20px",
    },
    label: {
        fontSize: "1rem",
        fontWeight: 600,
        color: "#444",
    },
    cancelButton: {
        color: "#d32f2f",
        borderColor: "#d32f2f",
        "&:hover": {
            backgroundColor: "rgba(211, 47, 47, 0.1)",
        },
    },
    billButton: {
        backgroundColor: "#2C5283",
        color: "white",
        "&:hover": {
            backgroundColor: "#1E3A63",
        },
    },
};

const CustomerPage = (props) => {

    let myMapDetailsDecrypted = "";

    try {
        myMapDetailsDecrypted = CryptoJS.AES.decrypt(localStorage.getItem("myMap"), KEY);

    } catch (e) {
        console.log(e);
    }

    const [tenantList, setTenantList] = useState([]);


    const originalText = myMapDetailsDecrypted.toString(CryptoJS.enc.Utf8);

    const label = {inputProps: {"aria-label": "Switch demo"}};


    const [tenantData, setTenantData] = useState({
        first_name: "n/a",
        last_name: "n/a",
        phone_number: "",
        secondary_phone_number: "",
        email_address: "",
        account_number: "",
        house_number: "",
        Wallet: 0,
        status: "",
        state: ""
    });

    const [files, setFiles] = useState({
        "tenant_photo": {
            "base64": "No File Found",
            "extension": "Not Specified"
        },
        "photo_of_national_id": {
            "base64": "No File Found",
            "extension": "Not Specified"
        },
        "copy_of_contract": {
            "base64": "No File Found",
            "extension": "Not Specified"
        },
        "inventory_checklist": {
            "base64": "No File Found",
            "extension": "Not Specified"
        }
    });

    useEffect(() => {
        // Fetch the tenant data
        fetch(ApiDetails + "pegasus/visionary/tenant/get/specific/tenant", {
            method: "POST",
            body: JSON.stringify({tenant_id: props.tenant_id}),
            headers: {"Content-Type": "application/json"},
        })
            .then((response) => response.json())
            .then((data) => {

                setTenant(data);
                setTenantData(data);
                setTenantList([data]);

                setWaterBill(data.water_bill)
                setElectricityBill(data.electricity_bill)

                setMinimumWaterBill(data.minimum_water_bill)
                setMinimumElecBIll(data.minimum_electricity_bill)


            })
            .catch((err) => console.error(err));

    }, []);

    useEffect(() => {
        // Fetch the image from the server
        fetch(
            ApiDetails +
            "pegasus/visionary/tenant/fetch/tenant/files",
            {
                method: "POST",
                body: JSON.stringify({
                    tenant_id: props.tenant_id,
                }),
            }
        )
            .then(async (response) => {
                let data = await response.json();
                if (response.status === 200) {
                    let pic;
                    pic = data.tenant_photo;
                    setFiles(data)


                    if (
                        pic.base64 !== "No File Found" &&
                        pic.base64.toString().trim() !== ""
                    ) {
                        // console.log("TRUE")
                        setImageSrc("data:image/jpeg;base64," + pic.base64);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const [imageSrc, setImageSrc] = useState("");

    const [bills, setBills] = useState([]);


    const getTenantBills = () => {
        fetch(ApiDetails + "pegasus/visionary/tenant/bills", {
            method: "POST",
            body: JSON.stringify({
                tenant_id: props.tenant_id,
            }),
        })
            .then(async (response) => {
                let bills = await response.json();
                if (response.status === 200) {
                    console.log(bills);

                    //Fetch tenant transactions
                    // setBills(bills);
                    // setInvoicesModal(true);

                    showSnackbar({
                        title: "Success!",
                        message: "Bill retrieved successfully.",
                        type: "success", // success | error | warning | info
                        position: {vertical: "bottom", horizontal: "right"}, // Custom position
                    });

                } else if (response.status === 404) {
                    showSnackbar({
                        title: "Error!",
                        message: "Bill not found.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                } else {

                    showSnackbar({
                        title: "Error!",
                        message: "Failed fetching tenant's latest bill..",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
            });
    };

    const [billTenantsModal, setBillTenantsModal] = useState(false);
    let [billedTenants, setBilledTenants] = useState([]);

    const [waterBill, setWaterBill] = useState();
    const [minimumWaterBill, setMinimumWaterBill] = useState();

    const [electricityBill, setElectricityBill] = React.useState(0);
    const [minimumElecBill, setMinimumElecBIll] = React.useState(0);

    let [elecBilledTenants, setElecBilledTenants] = useState([]);

    const [removedWaterBilling, setRemovedWaterBilling] = useState([]);
    const [removedElectricityBilling, setRemovedElectricityBilling] = useState([]);

    let [tenantsLength, setTenantsLength] = useState(1);

    const getRowStyle = (params) => ({
        backgroundColor: params.row.billing_enabled ? "transparent" : "#f0f0f0",
        color: params.row.billing_enabled ? "inherit" : "#888",
        pointerEvents: params.row.billing_enabled ? "auto" : "none",
        opacity: params.row.billing_enabled ? 1 : 0.6,
    });

    const billingColumns = [
        {
            field: "user",
            headerName: "Name",
            flex: 1,
            minWidth: 120,
            sortOrder: "asc",
            renderCell: (params) => {
                return (
                    <div className="userListUser">
                        {params.row.first_name} {params.row.last_name}
                    </div>
                );
            },
        },
        {
            field: "house_number", // align: "center",
            headerName: "HSE NO",
            flex: 1,
            minWidth: 80,
        },
        {
            field: "rent",
            headerName: "Rent",
            flex: 1,
            minWidth: 50, // align: "center",
            renderCell: (params) => {
                return (
                    <div style={{color: "green"}}>
                        {parseInt(
                            params.row.rent.toString().replace(",", "")
                        ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "KSH",
                        })}
                    </div>
                );
            },
        },
        {
            field: "bill",
            headerName: "Wallet",
            minWidth: 120,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <span
                    style={{
                        ...getRowStyle(params),
                        color: params.row.status === "PENDING" ? "#D32F2F" : "#388E3C",
                    }}
                >
                {params.row.Wallet.toLocaleString("en-US", {
                    style: "currency",
                    currency: "KSH",
                })}
            </span>
            ),
        },

        {
            field: "meter_reading",
            headerName: "Prior Water Reading",
            flex: 1,
            minWidth: 120, // align: "center",
            renderCell: (params) => {
                return <div>{params.row.meter_reading}</div>;
            },
        },
        {
            field: "current_meter_reading",
            headerName: "Water Reading",
            flex: 1,
            minWidth: 100, // align: "center",
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        <TextField
                            type={"number"}
                            placeholder="Enter Reading ..."
                            onChange={(e) => {
                                let filteredTenantArray = billedTenants.filter((tenant) => {
                                    return tenant.tenant_id !== params.row.tenant_id;
                                });

                                if (
                                    e.target.value === undefined ||
                                    e.target.value === null ||
                                    e.target.value === ""
                                ) {
                                    return;
                                }

                                let consumedUnits = e.target.value - params.row.meter_reading;
                                consumedUnits = consumedUnits.toFixed(1);

                                let finalWaterBill =
                                    parseFloat(consumedUnits) * parseInt(waterBill);

                                if (finalWaterBill < minimumWaterBill) {
                                    finalWaterBill = minimumWaterBill;
                                }

                                setBilledTenants([
                                    ...filteredTenantArray,
                                    {
                                        tenant_id: params.row.tenant_id,
                                        rent: parseInt(params.row.rent.replace(",", "")),
                                        arrears: parseInt(params.row.arrears),
                                        finalWaterBill: parseFloat(finalWaterBill),
                                        total_bill:
                                            parseInt(params.row.rent.replace(",", "")) +
                                            parseInt(params.row.arrears) +
                                            parseFloat(finalWaterBill),
                                        meter_reading: e.target.value,
                                        landlord_id: JSON.parse(originalText)[0][1],
                                    },
                                ]);
                            }}
                            disabled={!params.row.billing_enabled}
                        />
                    </div>
                );
            },
        },

        {
            field: "electricity_meter_reading",
            headerName: "Prior Elec Reading",
            flex: 1,
            minWidth: 80,
        },
        {
            field: "current_electricity_meter_reading",
            headerName: "Electricity Reading",
            flex: 1,
            minWidth: 120,
            renderCell: (params) => {
                return (
                    <div className="userListAction">
                        <TextField
                            type={"number"}
                            placeholder="Enter Reading ..."
                            onChange={(e) => {
                                let filteredTenantArray = elecBilledTenants.filter((tenant) => {
                                    return tenant.tenant_id !== params.row.tenant_id;
                                });
                                if (
                                    e.target.value === undefined ||
                                    e.target.value === null ||
                                    e.target.value === ""
                                ) {
                                    return;
                                }
                                let consumedUnits =
                                    e.target.value - params.row.electricity_meter_reading;
                                consumedUnits = consumedUnits.toFixed(1);

                                // console.log(
                                //   "Prior elec reading: " + params.row.electricity_meter_reading
                                // );
                                // console.log("Current elec reading: " + e.target.value);
                                // console.log("Consumed units: " + consumedUnits);
                                let finalElecBill =
                                    parseFloat(consumedUnits) * parseInt(electricityBill);

                                // console.log("Final elec bill: " + finalElecBill);

                                if (finalElecBill < minimumElecBill) {
                                    finalElecBill = minimumElecBill;
                                }

                                setElecBilledTenants([
                                    ...filteredTenantArray,
                                    {
                                        tenant_id: params.row.tenant_id,
                                        rent: parseInt(params.row.rent.replace(",", "")),
                                        arrears: parseInt(params.row.arrears),
                                        electricity_bill: parseFloat(finalElecBill),
                                        total_bill:
                                            parseInt(params.row.rent.replace(",", "")) +
                                            parseInt(params.row.arrears) +
                                            parseFloat(finalElecBill),
                                        electricity_meter_reading: e.target.value,
                                        landlord_id: JSON.parse(originalText)[0][1],
                                    },
                                ]);
                            }}
                            disabled={!params.row.billing_enabled}
                        />
                    </div>
                );
            },
        },
    ];

    const getBillingMonth = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth(); // 0-based index (Jan = 0, Feb = 1, etc.)
        const day = today.getDate();

        if (day >= new Date(year, month + 1, 0).getDate() - 4 || day <= 10) {
            // Last 5 days of the month OR first 10 days of the new month
            return new Date(year, month + 1, 1); // Select Next Month
        } else {
            return new Date(year, month, 1); // Select Current Month
        }
    };

    const [month, setMonth] = useState(getBillingMonth());

    const [selectedMonth, setSelectedMonth] = useState(getBillingMonth());

    const [billLoading, setBillLoading] = React.useState(false);

    const confirm = useConfirm();

    function getMonthName(monthNumber) {
        const date = new Date();
        date.setMonth(monthNumber);

        return date.toLocaleString("en-US", {month: "long"});
    }

    const [tenant, setTenant] = useState({
        tenant_id: "",
        landlord_id: "",
        first_name: "",
        house_number: "",
        occupation_or_profession: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        national_id: "",
        next_of_kin_relationship: "",
        next_of_kin_first_name: "",
        next_of_kin_last_name: "",
        next_of_kin_national_id: "",
        next_of_kin_contacts: "",
        is_student: "",
        institution_name: "",
        place_of_work: "",
        reason_for_relocating: "",
        previous_residence: "",
        landlord_email_address: "",
        onboarding_secret: "SECRET",
        property_name: "",
        rent: "",
        property_id: "",
        arrears: "",
        amount: "",
        account_number: "",
        account_balance: "",
        bill: "",
        status: "",
        tenant_photo: "",
        inventory_checklist: "",
        copy_of_contract: "",
        photo_of_national_id: "",
        deposit_held: "",
        secondary_phone_number: "",
        bill_details: false,
        notice: {
            reasons_for_vacating: "",
        },
    });

    const [warning, setWarning] = useState("");

    const classes = useStyles();

    const handleClose = () => {
        setBillTenantsModal(false);
    };

    const [htmlContent, setHtmlContent] = useState('');

    const billTenant = async () => {
        // console.log(billedTenants);
        // console.log(elecBilledTenants);

        setBillLoading(true);

        if (
            billedTenants.length < tenantsLength ||
            elecBilledTenants.length < tenantsLength
        ) {
            showSnackbar({
                title: "Error!",
                message: "Please Make sure to bill both water and electricity",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });

            setBillLoading(false);

            return;
        }

        let finalBill = [];

        if (billedTenants > elecBilledTenants) {
            billedTenants.forEach((tenant) => {
                elecBilledTenants.forEach((tenant2) => {
                    if (tenant.tenant_id === tenant2.tenant_id) {
                        finalBill = [
                            ...finalBill,
                            {
                                tenant_id: tenant.tenant_id,
                                water_bill: tenant.finalWaterBill,
                                electricity_bill: tenant2.electricity_bill,
                                total_bill:
                                    parseInt(tenant.rent.replace(",", "")) +
                                    parseInt(tenant.arrears) +
                                    parseFloat(tenant2.electricity_bill) +
                                    parseFloat(tenant.finalWaterBill),
                                meter_reading: tenant.meter_reading,
                                electricity_meter_reading: tenant2.electricity_meter_reading,
                                landlord_id: JSON.parse(originalText)[0][1],
                            },
                        ];
                        billedTenants = billedTenants.filter(
                            (tenant) => tenant.tenant_id !== tenant2.tenant_id
                        );
                        elecBilledTenants = elecBilledTenants.filter(
                            (tenant) => tenant.tenant_id !== tenant2.tenant_id
                        );
                    }
                });
            });
        } else {
            elecBilledTenants.forEach((tenant) => {
                billedTenants.forEach((tenant2) => {
                    if (tenant.tenant_id === tenant2.tenant_id) {
                        finalBill = [
                            ...finalBill,
                            {
                                tenant_id: tenant.tenant_id,
                                water_bill: tenant2.finalWaterBill,
                                electricity_bill: tenant.electricity_bill,
                                total_bill:
                                    parseInt(tenant.rent) +
                                    parseInt(tenant.arrears) +
                                    parseFloat(tenant.electricity_bill) +
                                    parseFloat(tenant2.finalWaterBill),
                                meter_reading: tenant2.meter_reading,
                                electricity_meter_reading: tenant.electricity_meter_reading,
                                landlord_id: JSON.parse(originalText)[0][1],
                            },
                        ];
                        billedTenants = billedTenants.filter(
                            (tenant) => tenant.tenant_id !== tenant2.tenant_id
                        );
                        elecBilledTenants = elecBilledTenants.filter(
                            (tenant) => tenant.tenant_id !== tenant2.tenant_id
                        );
                    }
                });
            });
        }

        let finalWaterBills = [];
        billedTenants.forEach((reading) => {
            reading = {
                tenant_id: reading.tenant_id,
                water_bill: reading.finalWaterBill,
                electricity_bill: 0,
                total_bill:
                    parseInt(reading.rent) +
                    parseInt(reading.arrears) +
                    parseFloat(reading.finalWaterBill),
                meter_reading: reading.meter_reading,
                electricity_meter_reading: 0,
                landlord_id: JSON.parse(originalText)[0][1],
            };
            finalWaterBills = [...finalWaterBills, reading];
        });

        let finalElectricityBills = [];
        elecBilledTenants.forEach((reading) => {
            reading = {
                tenant_id: reading.tenant_id,
                water_bill: 0,
                electricity_bill: reading.electricity_bill,
                total_bill:
                    parseInt(reading.rent) +
                    parseInt(reading.arrears) +
                    parseFloat(reading.electricity_bill),
                meter_reading: 0,
                electricity_meter_reading: reading.electricity_meter_reading,
                landlord_id: JSON.parse(originalText)[0][1],
            };
            finalElectricityBills = [...finalElectricityBills, reading];
        });

        finalBill = [...finalBill, ...finalWaterBills, ...finalElectricityBills];

        if (selectedMonth === null) {
            showSnackbar({
                title: "Error!",
                message: "Kindly select the month you are billing for.",
                type: "error", // Options: success, error, warning, info
                position: {vertical: "bottom", horizontal: "right"}, // Options for position
            });
            setBillLoading(false);
            return;
        }

        let continueBilling = false;

        // eslint-disable-next-line no-restricted-globals
        await confirm({
            description: `You are about to bill for the month of 
         ${getMonthName(parseInt(month.getMonth())).toUpperCase()} , ${parseInt(
                month.getFullYear()
            )}`,
        })
            .then(() => {
                continueBilling = true;
            })
            .catch(() => (continueBilling = false));

        if (!continueBilling) {
            fetch(ApiDetails + "pegasus/visionary/property/enableBilling", {
                method: "POST",
                body: JSON.stringify({
                    property_id: tenant.property_id,
                }),
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        window.location.reload(false);
                    } else {
                        showSnackbar({
                            title: "Error!",
                            message: "Failed updating tenant details.",
                            type: "error", // Options: success, error, warning, info
                            position: {vertical: "bottom", horizontal: "right"}, // Options for position
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    showSnackbar({
                        title: "Error!",
                        message: "Something Went Wrong. Call Customer Care For Assistance.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                });
            return;
        }

        //Make Billing API Call
        fetch(ApiDetails + "pegasus/visionary/property/bill/tenants", {
            method: "POST",
            body: JSON.stringify([
                ...finalBill,
                {
                    month: parseInt(month.getMonth()),
                    year: parseInt(month.getFullYear()),
                    property_id: tenant.property_id,
                    property_name: tenant.property_name,
                    // email_address: JSON.parse(originalText)[5][1],
                },
            ]),
        })
            .then(async (response) => {
                if (response.status === 200) {
                    // await getPropertyDetails();
                    // setUpdateState(!updateState);
                    let data = await response.json();

                    console.log(data)

                    setHtmlContent(data.htmlReport);

                    showSnackbar({
                        title: "Success!",
                        message: "Tenants billed successfully.",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });

                    setBillLoading(false);

                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Failed Billing tenants.",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                    setBillLoading(false);

                    handleClose();
                }
            })
            .catch((err) => {
                console.log(err);

                showSnackbar({
                    title: "Error!",
                    message: "Something Went Wrong. Call Customer Care For Assistance.",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });

                setBillLoading(false);

                handleClose();
            });
    };

    const downloadPDF = () => {
        //Make Billing API Call
        fetch(ApiDetails + "pegasus/visionary/property/bill/report", {
            method: "POST", body: JSON.stringify({
                pdf: htmlContent
            }),
        })
            .then(async (response) => {
                if (response.status === 200) {

                    let fileName;

                    for (let pair of response.headers.entries()) {
                        // accessing the entries
                        if (pair[0] === "content-disposition") {
                            // key I'm looking for in this instance
                            fileName = pair[1]; // saving that value where I can use it
                        }
                    }

                    showSnackbar({
                        title: "Success!",
                        message: "File download will start shortly",
                        type: "success", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });


                    let blob = await response.blob();

                    let href = window.URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    handleClose();


                } else {
                    showSnackbar({
                        title: "Error!",
                        message: "Failed downloading report",
                        type: "error", // Options: success, error, warning, info
                        position: {vertical: "bottom", horizontal: "right"}, // Options for position
                    });
                    setBillLoading(false);

                    handleClose();
                }
            })
            .catch((err) => {
                console.log(err);
                showSnackbar({
                    title: "Error!",
                    message: "Something went wrong while downloading report",
                    type: "error", // Options: success, error, warning, info
                    position: {vertical: "bottom", horizontal: "right"}, // Options for position
                });
                setBillLoading(false);

                handleClose();
            });
    };

    const goBack = useGoBack();


    return (
        <>
            {/*Bill Tenants MODAL*/}
            <Dialog
                fullWidth
                maxWidth="xl"
                open={billTenantsModal}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Billing Tenants
                    </div>
                </DialogTitle>

                {htmlContent !== "" ? (
                        <div style={{borderRadius: '10px'}}>
                            {/* Render the HTML content */}
                            <div dangerouslySetInnerHTML={{__html: htmlContent}}/>
                        </div>
                    ) :
                    (

                        <DialogContent
                            style={{
                                padding: "10px",
                                display: "flex",
                                justifyContent: "center",
                                height: "400px",
                            }}
                        >
                            <div
                                style={{
                                    height: "300px", // width: "fit-content",
                                    width: "95vw",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "start",
                                        gap: 1.5,
                                        p: 2,
                                        backgroundColor: "#F7F9FC",
                                        borderRadius: "8px",
                                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                                        // width: "100%"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            color: "#333",
                                            mb: 1
                                        }}
                                    >
                                        Billing for which month?
                                    </Typography>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            id="outlined-basic"
                                            views={["year", "month"]}
                                            value={month}
                                            onChange={(newValue) => {
                                                console.log("Selected value :: ")
                                                console.log(newValue)

                                                setMonth(newValue);
                                                setSelectedMonth(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    // fullWidth
                                                    variant="outlined"
                                                    sx={{
                                                        backgroundColor: "white",
                                                        borderRadius: "6px",
                                                        "& .MuiOutlinedInput-root": {
                                                            fontSize: "14px",
                                                            padding: "10px",
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            fontSize: "14px",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <>
                                    <StyledDataGrid
                                        style={{height:"200px"}}
                                        getRowId={(row) => row.tenant_id}
                                        className={classes.root}
                                        initialState={{
                                            sorting: {
                                                sortModel: [{field: "house_number", sort: "asc"}],
                                            },
                                        }}
                                        rows={tenantList}
                                        disableSelectionOnClick
                                        rowHeight={45}
                                        columns={billingColumns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                    />
                                </>
                            </div>
                        </DialogContent>
                    )
                }
                {htmlContent !== "" ? (
                        <DialogActions sx={{padding: "16px"}}>

                             <div className="download-btn text-center mt-4">
                                <button className="btn btn-primary" onClick={downloadPDF}>
                                    Download PDF
                                </button>
                            </div>

                            <Button onClick={() => {
                                window.location.reload()
                            }} variant="outlined" sx={{
                                color: "#d32f2f",
                                borderColor: "#d32f2f",
                                "&:hover": {
                                    backgroundColor: "rgba(211, 47, 47, 0.1)",
                                },
                            }}>
                                Cancel
                            </Button>

                        </DialogActions>
                    ) :
                    (
                        <DialogActions sx={{padding: "16px"}}>
                            {/* Cancel Button */}
                            <Button onClick={() => window.location.reload()} variant="outlined" sx={styles.cancelButton}>
                                Cancel
                            </Button>

                            {/* Bill Button */}
                            <LoadingButton
                                size="medium"
                                onClick={billTenant}
                                loading={billLoading}
                                startIcon={<SendIcon/>}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#2C5283",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#1E3A63",
                                    },
                                }}
                                loadingPosition="start"
                            >
                                Bill
                            </LoadingButton>
                        </DialogActions>
                    )
                }
            </Dialog>


            <Box p={1} bgcolor="#F7F9FA">

                {/* Header Section */}
                <Box
                    sx={{
                        position: "sticky", // or "fixed" if you want it to always stay at the top
                        top: 50, // Ensures it sticks to the top of the viewport
                        zIndex: 1000, // Ensures it stays on top of other elements
                        backgroundColor: "#F5F5F5",
                        borderBottom: "2px solid #2C5283",
                        padding: "10px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    {/* Left Section: Back button and Title */}
                    <Box sx={{display: "flex", alignItems: "center"}}>
                        <IconButton
                            sx={{color: "#2C5283", marginRight: 1, backgroundColor: "#F4F6F8"}}
                            onClick={goBack}
                        >
                            <ArrowBackIcon/>
                        </IconButton>
                        <Typography variant="h6" sx={{fontWeight: "bold", color: "#2C5283"}}>
                            View Tenant
                            - {tenantData.first_name.toUpperCase()} {tenantData.last_name.toUpperCase()}
                        </Typography>
                    </Box>

                    {/* Right Section: Action Buttons */}
                    <Box sx={{display: "flex", gap: 1}}>
                        <Button variant="outlined" sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                onClick={() => {
                                    getTenantBills();

                                }}>
                            View Bill Details
                        </Button>
                        <Button variant="outlined" sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                onClick={() => {
                                    setBillTenantsModal(true)
                                }}>
                            Bill Tenant
                        </Button>
                        <Button variant="outlined" sx={{textTransform: "none", backgroundColor: "#FBF4F0"}}
                                onClick={() => {
                                    window.location.reload();
                                }}>
                            Refresh Details
                        </Button>
                    </Box>
                </Box>

                {/* Main Layout */}
                <Grid container spacing={3}>
                    <LeftSide tenantID={props.tenant_id}/>

                    {/* Right Section: Tabs and Content */}
                    <RightSide tenantID={props.tenant_id} tenantData={tenantData}/>
                </Grid>
            </Box>
        </>
    );
};

export default CustomerPage;
